import {
  createRef,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAutocomplete } from '@material-ui/lab';

import {
  getLabelForDropdownOption,
  findSelectedItemFromDropdownData,
} from '../../../helper/DropdownHelper';
import { actorDispatch, DropdownData } from '../../../type/actor-setup';
import { convertToStringValue } from '../../../helper/AutocompleteHelper';
import type {
  AutocompleteFilterInputPropsInterface,
  onChangeFunc,
  DropdownOption,
} from './autocomplete-filter-input.type';
import AutocompleteFilterInputView from './autocomplete-filter-input.view';
import { isEmpty } from '../../../helper/data-helper';
import lodashDebounce from 'lodash/debounce';

const AutocompleteFilterInputController = (
  props: AutocompleteFilterInputPropsInterface,
): ReactElement | null => {
  const { field, disabled, value, maxSelection, onChange } = props;

  const { valueMember, tableName, moduleName } = field.dropdown;

  const createPreselectedOptionsRef = useRef(true);
  const buttonRefDialog = createRef<HTMLSpanElement>();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<DropdownOption[]>([]);
  const [dropdownData, setDropdownData] = useState<{
    items: Record<string, unknown>[];
    totalItemsCount: number;
  }>({
    items: [],
    totalItemsCount: 0,
  });

  useEffect(() => {
    if (!isEmpty(value) && createPreselectedOptionsRef.current) {
      fetchDropdownDataHandler({
        successCallback: makeDefaultSelectedOptions,
        searchValue: value,
      });
    } else if (isEmpty(value)) {
      setSelectedOptions([]);
    }
    onChange?.(value);

    return () => {
      // Yeah, finally i used this feature of `react` => will update
      if (!isEmpty(value)) {
        createPreselectedOptionsRef.current = false;
      }
    };
  }, [value]);

  /**
   * @function makeDefaultSelectedOptions
   * @param { DropdownData } dropData
   * @returns { void } void
   */
  const makeDefaultSelectedOptions = useCallback(
    (dropData: DropdownData): void => {
      const { DATA, TOTAL } = dropData;
      const formattedSelectedOptions: DropdownOption[] = [];

      const valueList = value.split(',');

      for (let index = 0; index < valueList.length; index++) {
        const selectedItemData = findSelectedItemFromDropdownData({
          dropdownMeta: field.dropdown,
          dataArray: DATA,
          value: valueList[index],
          field,
        });

        formattedSelectedOptions.push({
          text: selectedItemData.label,
          value: selectedItemData.value + '',
          key: selectedItemData.value + '' + index,
        });
      }
      setSelectedOptions(formattedSelectedOptions);
      setDropdownData({
        items: DATA!,
        totalItemsCount: Number(TOTAL),
      });
    },
    [value],
  );

  /**
   * Change filter value of the current field on `filter` form
   * @function handleChange
   * @param { DropdownOption[] } newValueList
   * @returns { void } void
   */
  const handleChange: onChangeFunc = useCallback(
    (_, newValueList: DropdownOption[]): void => {
      setSelectedOptions(newValueList);

      const selectedIdsToString = convertToStringValue(
        newValueList as DropdownOption[],
      );
      onChange?.(selectedIdsToString);

      createPreselectedOptionsRef.current = false;
    },
    [],
  );

  /**
   * it will get a value from row click or some values from checkbox and
   * add or replace it to input value state then trigger handleBlur
   * @function handleSelectionWithSearchDialog
   * @param {Object} {newValue}
   * @returns {void}
   */
  const handleSelectionWithSearchDialog = useCallback(
    (newValue: {
      selectedIds: string;
      selectedItemsData: DropdownOption[];
    }): void => {
      const formattedSelectedOptions =
        newValue.selectedItemsData?.map((item, index) => ({
          text: getLabelForDropdownOption(field.dropdown, item),
          value: item[valueMember] + '',
          key: item[valueMember] + '' + index,
        })) ?? [];

      setSelectedOptions(formattedSelectedOptions);
      onChange?.(newValue.selectedIds);
    },
    [],
  );

  const preparedOptions = useMemo(
    () =>
      dropdownData.items?.map((item, index) => ({
        text: getLabelForDropdownOption(field.dropdown, item),
        value: item[valueMember] + '',
        key: item[valueMember] + '' + index,
      })) ?? [],
    [dropdownData.items],
  );

  /**
   * on click for open drop down options (first get data options and handleOpen for open drop down)
   * @function handleClick
   * @returns {void}
   */
  const handleInputBaseClick = useCallback((): void => {
    if (disabled) return;

    fetchDropdownDataHandler({
      successCallback: updateDropdownData,
    });
  }, [disabled, dropdownData.totalItemsCount]);

  /**
   * it should clear previous dropdown data in actor state every time it will
   * open if it has data then call formActionsHandler method to get data
   * @function fetchDropdownDataHandler
   * @param {
   *  searchValue?: string;
   *  successCallback?: (response: DropdownData) => void;
   * } params
   * @returns {void} void
   */
  const fetchDropdownDataHandler = useCallback(
    (params: {
      searchValue?: string;
      successCallback?: (response: DropdownData) => void;
    }): void => {
      setLoading(true);

      actorDispatch(
        'fetchDropdownData',
        {
          dropdownId: field.dropdown.id,
          option: {
            perPage: 500, // َUnlike `DropdownInput`, this input doesn't have `InfiniteScroll`
            isFilter: true,
          },
          dropdownMeta: field.dropdown,
          resource: '',
          resourceType: '',
          searchValue: params.searchValue,
          isFilterInput: true,
          successCallback: (response: DropdownData): void => {
            params.successCallback?.(response);
            setLoading(false);
          },
          failureCallback: (): void => {
            setLoading(false);
          },
        },
        { disableDebounce: true, replaceAll: true },
      );
    },
    [],
  );

  /**
   * @function updateDropdownData
   * @param { DropdownData } data
   * @return { void } void
   */
  const updateDropdownData = useCallback((data: DropdownData): void => {
    const { DATA = [], TOTAL = null } = data;

    setDropdownData({
      items: DATA,
      totalItemsCount: Number(TOTAL),
    });
  }, []);

  /**
   * it should trigger `fetchDropdownData` function in form controller to make request
   * @function handleOnSearch
   * @param { string } searchValue
   * @returns { void } void
   */
  const handleOnSearch = useCallback((searchValue: string): void => {
    fetchDropdownDataHandler({
      searchValue,
      successCallback: updateDropdownData,
    });
  }, []);

  /**
   * @function debouncedSearch
   * @param { string } value
   * @returns { void } void
   */
  const debouncedSearch = useCallback<(value: string) => void>(
    lodashDebounce(value => {
      handleOnSearch(value);
    }, 700),
    [dropdownData.items],
  );

  /**
   * this function handle value change in search input drop down
   * @function handleChangeInput
   * @param {React.ChangeEvent<HTMLInputElement>} {event}
   * @return {void}
   */
  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      debouncedSearch(event.target.value);
    },
    [],
  );

  /**
   * open search dialog
   * @function handleClickSearchDialog
   * @return {void}
   */
  const handleClickSearchDialog = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();

    if (disabled) return;

    if (buttonRefDialog.current) {
      buttonRefDialog.current.click();
      buttonRefDialog.current.style.display = 'none';
    }
  };

  /**
   * @function searchPopupRequestData
   * @param { Record<string, unknown> } params
   * @returns { void } void
   */
  const searchPopupRequestData = useCallback(
    (params: Record<string, unknown>): void => {
      actorDispatch(
        'fetchDropdownData',
        {
          ...params,
        },
        { disableDebounce: true, replaceAll: true },
      );
    },
    [],
  );

  /**
   * @function gridRowClickHandler
   * @param { Record<string, unknown> } newValue
   * @returns { void } void
   */
  const gridRowClickHandler = useCallback(
    (newValue: Record<string, unknown>): void => {
      const newSelectedDropdownOptionFormattedItem = {
        text: getLabelForDropdownOption(field.dropdown, newValue),
        value: newValue[valueMember] + '',
        key: newValue[valueMember] + '',
      };

      setSelectedOptions(prevSelectedOptions => {
        const targetIndex = prevSelectedOptions.findIndex(
          item => item.value === newSelectedDropdownOptionFormattedItem.value,
        );

        if (targetIndex === -1) {
          const preparedSelectedValues = prevSelectedOptions.map(item => item.value);
          preparedSelectedValues.push(newSelectedDropdownOptionFormattedItem.value);
          onChange?.(preparedSelectedValues.join(','));

          return [...prevSelectedOptions, newSelectedDropdownOptionFormattedItem];
        }

        return prevSelectedOptions;
      });
    },
    [],
  );

  const clearSelections = useCallback(() => {
    setSelectedOptions([]);
    onChange?.('');
  }, []);

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    setAnchorEl,
    groupedOptions,
  } = useAutocomplete({
    id: 'autocomplete-filter-input-' + field.name,
    multiple: true,
    disableCloseOnSelect: true,
    options: preparedOptions,
    defaultValue: selectedOptions,
    value: selectedOptions,
    onOpen: (): void => {
      setOpen(true);
    },
    onClose: (): void => {
      setOpen(false);
    },
    onChange: handleChange,
    getOptionLabel: (option: DropdownOption): string => option.text?.toString(),
    getOptionSelected: (option, selectedOption) =>
      option.value == selectedOption.value,
  });

  return (
    <AutocompleteFilterInputView
      preparedOptions={groupedOptions}
      selectedOptions={selectedOptions}
      loading={loading}
      open={open}
      maxSelection={maxSelection ?? preparedOptions.length}
      dropResource={`${moduleName}/${tableName}`}
      buttonRefDialog={buttonRefDialog}
      field={field}
      handleInputBaseClick={handleInputBaseClick}
      handleChangeInput={handleChangeInput}
      handleClickSearchDialog={handleClickSearchDialog}
      searchPopupRequestData={searchPopupRequestData}
      handleSelectionWithSearchDialog={handleSelectionWithSearchDialog}
      gridRowClickHandler={gridRowClickHandler}
      clearSelections={clearSelections}
      getInputProps={getInputProps}
      getListboxProps={getListboxProps}
      getOptionProps={getOptionProps}
      getRootProps={getRootProps}
      setAnchorEl={setAnchorEl}
      getTagProps={getTagProps}
    />
  );
};

export default AutocompleteFilterInputController;
