/* eslint-disable prettier/prettier */
import { FC, memo, useRef } from 'react';
import { useLocale } from 'react-admin';
import lodashDebounce from 'lodash/debounce';
import lodashGet from 'lodash/get';
import { DataGrid } from 'devextreme-react';

import GridView from './grid.view';
import LoadingBox from '../LoadingBox';
import { clone, isEmptyObject } from '../../helper/data-helper';
import { checkGridColumnHasDynamic, getFinalColumnsForGrid } from './grid.helper';
import { setGridFixedColumnsSetting } from './grid.helper';

import useSelection from './grid-hooks/useSelection';
import useSummery from './grid-hooks/useSummery';
import useTopFilters from './grid-hooks/useTopFilters';
import useGrouping from './grid-hooks/useGrouping';
import useContextMenu from './grid-hooks/useContextMenu';
import useSorting from './grid-hooks/useSorting';
import useFixedColumns from './grid-hooks/useFixedColumns';
import useColumnWidth from './grid-hooks/useColumnWidth';
import useEmbedForm from './grid-hooks/useEmbedForm';
import useDataPreparation from './grid-hooks/useDataPreparation';
import useCustomStyles from './grid-hooks/useCustomStyles';
import useVisibleColumns from './grid-hooks/useVisibleColumns';
import useLoading from './grid-hooks/useLoading';
import useColumnChooser from './grid-hooks/useColumnChooser';
import useResource from './grid-hooks/useResource';

import type { FormFieldInterface, GridPropsInterface } from './grid.type';

const GridController: FC<GridPropsInterface> = memo(props => {
  const locale = useLocale();

  const {
    resource,
    onSelectCheckbox,
    parentInfo,
    isReport,
    relation,
    parentRecord,
    setSort,
    fields,
    hasEdit,
    metaData,
    onRowClick,
    relationMode = false,
    addToFilterRequestList,
    hasShow,
    basePath,
    redirect,
    hasCreate,
    ids,
    data,
    relationResource = '',
    quickEditButton = false,
    setFilters,
    enableSelection = true,
    isTopFilterOpen,
    filterValues,
    idDropDown = null,
    selectedRows,
    sort,
    enableClientExportExcel,
    isGroupingOpen = false,
    summaryData,
    hasColumnChooser,
    simpleGridData,
    isMap,
  } = props;

  //------------------------------------------------------------ flags ------------------------------------------------------------

  const hasDynamic = isEmptyObject(data)
    ? null
    : checkGridColumnHasDynamic(Object.values(data), metaData);

  //------------------------------------------------------------ references ------------------------------------------------------------

  const gridRef = useRef<DataGrid>(null);
  const preparedFieldsRef = useRef<FormFieldInterface>({ allFields: [] });

  //------------------------------------------------------------ custom hooks ------------------------------------------------------------

  // reliable resources
  const { updatedResourceRef } = useResource({
    resource,
    relationResource,
    idDropDown,
  });

  // summery
  const {
    summaryDataRef,
    formatTotalSummaryGroupItem,
    formatTotalSummaryCell,
    getTotalSummaryItems,
  } = useSummery({
    fields,
    updatedResourceRef,
    resource,
    summaryData,
    data,
    gridRef,
  });

  // selection
  const { changeCheckboxSelection } = useSelection({ resource, onSelectCheckbox });

  // context menu
  const { onContextMenuPreparing } = useContextMenu({ metaData, setSort });

  // sort
  const { onlyClientSort, changeSorting } = useSorting({ hasDynamic, setSort });

  // filters
  const { forceIsTopFilterOpen, onTopFilterChange } = useTopFilters({
    resource,
    relationResource,
    gridRef,
    setFilters,
  });

  // grouping
  const {
    isGridGroupingEnable,
    groupColumns,
    setGroupGridSetting,
    getGridGroupItems,
    groupCellTemplate,
  } = useGrouping({ isGroupingOpen, resource, gridRef });

  // prepare data
  const { prepareAllFields, getPreparedRows } = useDataPreparation({
    allowAddInGrid,
    metaData,
    resource,
    preparedFieldsRef,
    ids,
    data,
    selectedRows,
    gridRef,
  });

  // embed form in grid
  const formProps = {
    resource,
    gridRef,
    preparedFieldsRef,
    idDropDown,
    isReport,
    hasEdit,
    summaryDataRef,
    metaData,
    parentInfo,
    relationMode,
    parentRecord,
    onlyClientSort,
    relationResource,
    relation,
    updateGridDataSource,
    prepareAllFields,
    onRowClick,
    changeSorting,
  };

  const {
    onFailureSaveForm,
    onEditorPreparing,
    handleOnRowClick,
    focusOnLastInput,
    allowEditInline,
    onEditCanceled,
    onEditingStart,
    onCellPrepared,
    isEnableInput,
    onChangeInput,
    onInitNewRow,
    onCellClick,
    setFormMode,
    onSaving,
    inputsCustomFunctionRef,
    lastFocusCellRef,
    formMode,
  } = useEmbedForm(formProps);

  // fixed columns
  const { fixedColumns } = useFixedColumns({ resource });

  // visible columns
  const { setGridVisibleColumnsSetting, getVisibleColumns } = useVisibleColumns({
    gridRef,
    resource,
  });

  // custom components and styles
  const { getGridClass, setRowColor } = useCustomStyles({
    metaData,
    relationMode,
    getPreparedRows,
    gridRef,
  });

  // columns width
  const { columnWidthSetting, onGridColumnWidthChange } = useColumnWidth({
    resource,
    relationResource,
    idDropDown,
    relationMode,
    gridRef,
    fields,
    metaData,
  });

  // loading
  const { actorIsLoading } = useLoading({
    resource,
    gridRef,
    prepareAllFields,
    setFormMode,
    data,
  });

  // column chooser listener
  useColumnChooser({ resource, gridRef });

  //------------------------------------------------------------ local functions ------------------------------------------------------------

  function allowAddInGrid(): boolean {
    // allow add in relation panel
    const allowAddInGrid = lodashGet(metaData, ['config', 'allowAddInGrid']);
    return (
      Boolean(hasCreate) &&
      !!allowAddInGrid &&
      !isReport &&
      !isEmptyObject(parentInfo)
    );
  }

  const onOptionChanged = lodashDebounce((event): void => {
    if (!event || !event.fullName || hasDynamic) return;

    if (event.fullName.indexOf('width') != -1) {
      onGridColumnWidthChange(event);
    } else if (event.fullName.indexOf('visible') != -1) {
      setGridVisibleColumnsSetting(getVisibleColumns(event.component));
    }
    if (event && event.fullName && event.fullName.indexOf('groupIndex') != -1) {
      setGroupGridSetting(getGridGroupItems(event.component));
    }
    if (event && event.fullName && event.fullName.indexOf('filterValue') !== -1) {
      onTopFilterChange(event);
    } else if (event.fullName.indexOf('fixed') != -1) {
      setGridFixedColumnsSetting(resource, event.component);
    }
  }, 500);

  function updateGridDataSource(): void {
    const dataSource = gridRef.current?.instance.getDataSource();
    dataSource?.reload();
  }

  //------------------------------------------------------------ show loading ------------------------------------------------------------

  if (
    !simpleGridData &&
    (isEmptyObject(metaData) || columnWidthSetting == null || !!actorIsLoading)
  ) {
    return <LoadingBox />;
  }

  //------------------------------------------------------------ prepare data ------------------------------------------------------------

  const gridRows = getPreparedRows();

  const gridColumns = getFinalColumnsForGrid({
    fields: clone(fields),
    metaData,
    locale,
    columnWidthSetting,
    gridRows,
  });

  return (
    <GridView
      // selection
      changeCheckboxSelection={changeCheckboxSelection}
      enableSelection={enableSelection}
      // summery
      formatTotalSummaryGroupItem={formatTotalSummaryGroupItem}
      formatTotalSummaryCell={formatTotalSummaryCell}
      totalSummaryItems={getTotalSummaryItems()}
      // filters
      addToFilterRequestList={addToFilterRequestList}
      isTopFilterOpen={isTopFilterOpen || forceIsTopFilterOpen}
      filterValues={filterValues}
      // form
      onFailureSaveForm={onFailureSaveForm}
      onEditorPreparing={onEditorPreparing}
      handleOnRowClick={handleOnRowClick}
      focusOnLastInput={focusOnLastInput}
      allowEditInline={allowEditInline}
      onEditCanceled={onEditCanceled}
      allowAddInGrid={allowAddInGrid}
      onEditingStart={onEditingStart}
      onCellPrepared={onCellPrepared}
      isEnableInput={isEnableInput}
      onChangeInput={onChangeInput}
      onInitNewRow={onInitNewRow}
      onCellClick={onCellClick}
      onSaving={onSaving}
      quickEditButton={quickEditButton}
      lastFocusCell={lastFocusCellRef}
      formMode={formMode}
      // event handling
      onOptionChanged={onOptionChanged}
      // context menu
      onContextMenuPreparing={onContextMenuPreparing}
      // styles
      getGridClass={getGridClass}
      setRowColor={setRowColor}
      // inputs
      inputsCustomFunctionRef={inputsCustomFunctionRef}
      // exports
      enableClientExportExcel={enableClientExportExcel}
      // resource and data
      relationResource={relationResource}
      parentInfo={parentInfo}
      basePath={basePath}
      redirect={redirect}
      resource={resource}
      metaData={metaData}
      rows={gridRows}
      // column chooser
      hasColumnChooser={hasColumnChooser}
      columns={gridColumns}
      groupCellTemplate={groupCellTemplate}
      // grouping
      isGroupingOpen={isGridGroupingEnable}
      groupColumns={groupColumns}
      // fixed columns
      fixedColumns={fixedColumns}
      // flags
      onlyClientSort={onlyClientSort}
      relationMode={relationMode}
      hasShow={hasShow}
      hasEdit={hasEdit}
      isMap={isMap}
      // references
      gridRef={gridRef}
      // sorting
      sort={sort}
    />
  );
});

export default GridController;
