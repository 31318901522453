import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { useStyles } from './polygon-input.style';
import InputBase from '../../input-base';
import { DynamicMap } from '../../map/dynamic-map';
import { getCurrentMetaData } from '../../../helper/ActorHelper';
import { tehranLatLong } from '../../map/dynamic-map/dynamic-map.controller';

import type { GeneralMetaData } from '../../../helper/Types';
import type { ReactElement, RefObject } from 'react';
import type { MapRef, PolygonInputViewPropsInterface } from './polygon-input.type';

const LocationInputView = (props: PolygonInputViewPropsInterface): ReactElement => {
  const {
    toggleOpen,
    handleCancelClick,
    isOpen,
    handleOkClick,
    field,
    label,
    getRef,
    inputMessage,
    hint,
    disabled,
    handleBlur,
    handleFocus,
    visibleClass,
    mapRef,
    onMapLoad,
    inputValue,
    previewMapRef,
    onPreviewMapLoad,
    isLoading,
    isShowMode,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  const preparedHeight =
    field?.rowSpan && +field.rowSpan > 1 ? field.rowSpan * 38 : 38;

  const MapFieldSet = () => {
    return (
      <fieldset
        className={`${visibleClass} datePickerContainer ${classes.fieldset}`}
        data-error={!!inputMessage?.message}
        data-label={!!label || label !== ''}
        disabled={disabled}
        onClick={toggleOpen}
        onBlur={handleBlur}
        onFocus={handleFocus}
        ref={getRef}
      >
        <div style={{ height: preparedHeight }}>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <DynamicMap
              metaData={getCurrentMetaData() as GeneralMetaData}
              mapRef={previewMapRef as RefObject<MapRef>}
              onMapLoad={onPreviewMapLoad}
              inputValue={inputValue}
              allowDrawPolygon
              mapOptions={{
                current: {
                  center: tehranLatLong,
                  zoom: 16,
                },
              }}
            />
          )}
        </div>
      </fieldset>
    );
  };

  return (
    <>
      {isShowMode ? (
        <MapFieldSet />
      ) : (
        <InputBase
          className={`${visibleClass}`}
          label={label}
          hint={hint}
          required={field.required}
          inputMessage={inputMessage}
          field={field}
        >
          <MapFieldSet />
        </InputBase>
      )}

      {isOpen && (
        <Dialog open={isOpen} onClose={handleCancelClick} fullWidth fullScreen>
          <DialogContent className={classes.dialogContent}>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOkClick}
                disabled={isShowMode}
              >
                {translate('ra.action.confirm')}
              </Button>
              <Button variant="contained" onClick={handleCancelClick}>
                {translate('ra.action.cancel')}
              </Button>
            </DialogActions>

            <DynamicMap
              metaData={getCurrentMetaData() as GeneralMetaData}
              allowDrawPolygon
              mapRef={mapRef as RefObject<MapRef>}
              onMapLoad={onMapLoad}
              inputValue={inputValue}
              mapOptions={{
                current: {
                  center: tehranLatLong,
                  zoom: 16,
                },
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default LocationInputView;
