import { useCallback, useMemo } from 'react';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';

import { actorDispatch } from '../../../type/actor-setup';
import { getServices } from '../../../helper/MetaHelper';

import type { DevExpressContextMenuEvent } from '../grid.type';

const useContextMenu = entries => {
  const { metaData, setSort } = entries;

  const locale = useLocale();

  const serviceList = useMemo(() => {
    return getServices(metaData)?.filter(
      service => service.related === 'SingleRecord',
    );
  }, []);

  /**
   * https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onContextMenuPreparing
   */
  const onContextMenuPreparing = useCallback((event: DevExpressContextMenuEvent) => {
    if (event.target == 'header') {
      event.items?.forEach(item => {
        if (item.text.includes('Sort')) {
          const columnName =
            item.value == 'none' || !event.column?.name ? '' : event.column.name;
          const direction =
            item.value == 'asc' || item.value == 'desc' ? item.value : null;
          item.onItemClick = () => setSort?.(columnName, direction);
        }
      });
      return;
    }

    if (event.row?.rowType === 'data') {
      const serviceListRef = getServices(metaData);
      event.items = serviceListRef.map(serviceItem => {
        return {
          text: lodashGet(
            serviceItem,
            ['translatedTitle', locale],
            lodashGet(serviceItem, ['title']),
          ),
          onItemClick: () => {
            actorDispatch('runServiceDirectly', {
              service: serviceItem,
              targetSelectedId: event.row!.key,
            });
          },
        };
      });
    }
  }, []);

  return { onContextMenuPreparing };
};

export default useContextMenu;
