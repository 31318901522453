import { useEffect, useState, useRef } from 'react';

import { getMetaDataFromActorOrNetwork } from '../../helper/meta-helper';
import { clone, isEmpty } from '../../helper/data-helper';
import { apiRequestResultHandler } from '../../helper/crud-api.helper';
import { requestListData } from '../list/list.helper';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  GridDataInterface,
} from '../../type/actor-setup';

import type {
  RelationAPIResultInterface,
  UseRelationDataType,
} from './relation-panel.type';
import type { GeneralMetaData } from '../../helper/Types';
import { customRecordNotePin } from './note-relation/create-edit-note/constants';

const useRelationData: UseRelationDataType = props => {
  const {
    relationResource,
    childFieldName,
    parentFieldName,
    parentIdentifier,
    relationIndex,
    relationType,
  } = props;

  const relationCachedMetaData = actorGetActionValue(
    'metaData',
    relationResource,
  ) as GeneralMetaData | null;

  const [isRelationDataReady, setRelationDataReady] = useState<boolean>(false);
  const [relationData, setRelationData] = useState<GridDataInterface | null>(null);
  const [metaDataError, setMetaDataError] = useState<string | null>(null);

  const relationDataAccessRef = useRef(relationData);
  const relationResourceAccessRef = useRef(relationResource);
  //in all condition we after set relationMetaData,we have one state to render component.
  let relationMetaData: GeneralMetaData | null = relationCachedMetaData;

  /**
   * remove request id from error message if exist , then set new message into meta error state
   * @function handleGetMetaError
   * @param {string} error
   * @returns {void} void
   */
  const handleGetMetaError = (error: string): void => {
    let splittedError: string | null = null;
    try {
      if (error.includes('^')) {
        splittedError = error.split('^')[0];
      }
    } catch (splitError) {
      console.log('splitError: ', splitError);
    }
    setMetaDataError(splittedError ?? error);

    setRelationDataReady(true);
  };

  const handleGetRelationDataRequestFailure = (
    apiResult: RelationAPIResultInterface,
  ): void => {
    let errorMessage = '';

    if (apiResult) {
      const getManyReferenceRelationErrorMessage = (
        apiResult.GET_MANY_REFERENCE?.relation?.data as string | null
      )
        ?.toString()
        .split('^')?.[0];

      const getListRelationErrorMessage = (
        apiResult.GET_LIST?.relation?.data as string | null
      )
        ?.toString()
        .split('^')?.[0];

      errorMessage =
        getManyReferenceRelationErrorMessage ?? getListRelationErrorMessage ?? '';
    }

    setRelationData({
      error: errorMessage,
    });
    setRelationDataReady(true);
  };

  /**
   * @function prepareRelationData
   * @returns {void} void
   */
  const prepareRelationData = async () => {
    // --------- handle meta data ---------

    const metaDataInActor =
      actorGetActionValue('metaData')?.[relationResourceAccessRef.current];

    if (!relationMetaData || !metaDataInActor) {
      if (relationIndex !== 0) {
        setRelationDataReady(true);
      } else {
        try {
          const metaData = await getMetaDataFromActorOrNetwork(
            relationResourceAccessRef.current,
            null,
            true,
          );

          relationMetaData = metaData?.[relationResourceAccessRef.current];
          actorSetActionValue(
            'metaData',
            metaData?.[relationResourceAccessRef.current],
            {
              path: relationResourceAccessRef.current,
            },
          );

          requestListData(
            relationResourceAccessRef.current,
            relationType,
            parentIdentifier,
            childFieldName,
            parentFieldName,
            metaData?.[relationResourceAccessRef.current],
            undefined,
            apiRequestResultHandler,
            handleGetRelationDataRequestFailure,
          );

          return;
        } catch (error) {
          handleGetMetaError(error as string);
        }
      }
    } else {
      Promise.resolve().then(() => {
        if (
          (!relationResourceAccessRef.current.includes('report') &&
            relationCachedMetaData?.config?.moduleName !==
              (metaDataInActor as GeneralMetaData)?.config?.moduleName) ||
          relationCachedMetaData?.config?.moduleTableName !==
            (metaDataInActor as GeneralMetaData)?.config?.moduleTableName
        ) {
          relationMetaData = metaDataInActor as GeneralMetaData;
        } else {
          relationMetaData = relationCachedMetaData;
        }

        setRelationDataReady(true);
      });
    }

    // --------- handle data ---------
    if (relationIndex === 0) {
      requestListData(
        relationResource,
        relationType,
        parentIdentifier,
        childFieldName,
        parentFieldName,
        relationMetaData,
        undefined,
        apiRequestResultHandler,
        handleGetRelationDataRequestFailure,
      );
    }
  };

  /**
   * get new metaData and then request new data
   * @function getNewRelationMetaAndRefreshData
   * @param {string} parentRecordIdentifier
   * @returns {Promise<void>} void
   */
  const getNewRelationMetaAndRefreshData = async (
    parentRecordIdentifier: string,
  ): Promise<void> => {
    try {
      const metaData = await getMetaDataFromActorOrNetwork(relationResource);

      actorSetActionValue('metaData', metaData?.[relationResource], {
        path: relationResource,
      });
      relationMetaData = metaData?.[relationResource];

      let preparedResource = relationResource;

      const isMultiResult =
        metaData?.[relationResource]['reportType'] === 'MultiResult';

      if (isMultiResult && relationData?.lastRequestId === undefined) {
        preparedResource = preparedResource + '/0';
      }

      requestListData(
        preparedResource,
        relationType,
        parentRecordIdentifier,
        childFieldName,
        parentFieldName,
        metaData?.[relationResource],
        undefined,
        apiRequestResultHandler,
        handleGetRelationDataRequestFailure,
      );
    } catch (error) {
      handleGetMetaError(error as string);
    }
  };

  useEffect(() => {
    relationDataAccessRef.current = relationData;
  }, [relationData?.lastRequestId]);

  useEffect(() => {
    if (!isEmpty(relationResource)) {
      Promise.resolve().then(() => {
        prepareRelationData();
      });
    }

    relationResourceAccessRef.current = relationResource;
  }, [relationResource]);

  useEffect(() => {
    const id = actorOnDispatch(
      'gridData',
      gridData => {
        const _relationMetaData = actorGetActionValue(
          'metaData',
          relationResourceAccessRef?.current,
        ) as unknown as GeneralMetaData;
        const isMultiResult = _relationMetaData?.['reportType'] === 'MultiResult';

        const _relationData: GridDataInterface = clone(
          isMultiResult
            ? gridData[relationResourceAccessRef?.current + '/0']
            : gridData[
                relationType === 'note'
                  ? `report/${customRecordNotePin}`
                  : relationResourceAccessRef?.current
              ],
        );

        const prevRequestId = relationDataAccessRef.current?.lastRequestId;
        const nextRequestId = _relationData?.lastRequestId;

        if (
          typeof nextRequestId !== 'undefined' && // unrelated event
          prevRequestId !== nextRequestId
        ) {
          // Promise.resolve has been used because of batch state updating
          // it will reduce number of renders that they has been triggered by setState from three to one
          Promise.resolve().then(() => {
            setRelationData(_relationData);
            setRelationDataReady(true);
          });
        }
      },
      { callerScopeName: 'useRelationData' },
    );
    return () => {
      actorRemoveAction({
        actionName: 'gridData',
        listenerId: id,
      });
    };
  }, []);

  return [
    relationMetaData,
    relationData,
    isRelationDataReady,
    getNewRelationMetaAndRefreshData,
    metaDataError,
    handleGetRelationDataRequestFailure,
  ];
};

export default useRelationData;
