import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  dialogContent: {
    padding: '0 !important',
  },

  dialogActions: {
    position: 'absolute',
    zIndex: 500,
    bottom: 0,
    right: 0,
  },
  outlineRoot: {
    height: '100%',
  },
  notchedOutline: {
    top: 0,
  },
  root: {
    flexGrow: 1,
    margin: 0,
    height: '100%',

    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    },
  },
  disabled: {
    color: theme.palette.primary.disabledFieldColor,
    '& fieldset': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  input: {
    zIndex: 1,
    '&:-webkit-autofill': {
      border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
    },
  },

  iconButton: {
    zIndex: 1,
  },
  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  InputAdornment: {
    width: 'auto !important',
    padding: 0,
    margin: 0,
  },

  datePickerIcon: {
    margin: '0 2px 0 5px',
    cursor: 'pointer',
  },
  fieldset: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px !important',
    '& div:nth-child(2)': {
      height: 'auto !important',
    },
    '& button': {
      marginBottom: 2,
    },

    '& .datepicker-input': {
      visibility: 'hidden !important',
    },
  },
}));
