import { useEffect, useState } from 'react';
import lodashMap from 'lodash/map';

import { actorOnDispatch, actorRemoveAction } from '../../../type/actor-setup';
import { replaceFarsiCharactersWithArabic } from '../../../helper/TextHelper';
import { FilterValueStructureEnum } from '../../filter-form/filter-form.helper';
import { isEmpty } from '../../../helper/data-helper';

const useTopFilters = entries => {
  const { resource, relationResource, gridRef, setFilters } = entries;

  const [forceIsTopFilterOpen, setForceIsTopFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    const onDispatchId = actorOnDispatch('isTopFilterOpen', response => {
      const [module, moduleTable] = resource?.split('/');

      if (module && moduleTable) {
        setForceIsTopFilterOpen(
          response[`${module}/${moduleTable}`] ??
            response?.[relationResource as string],
        );
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'isTopFilterOpen',
        listenerId: onDispatchId,
      });
    };
  }, []);

  const onTopFilterChange = event => {
    if (!isEmpty(event.value)) {
      //e.fullName is like "columns[2].filterValue"
      const colIndex = parseInt(
        event.fullName
          .match(/\[\d+\]/)[0]
          .replace('[', '')
          .replace(']', ''),
      );
      //replace Farsi character with arabic in filter value
      gridRef.current?.instance.columnOption(
        colIndex,
        'filterValue',
        replaceFarsiCharactersWithArabic(event.value),
      );
    }

    if (typeof setFilters == 'function') {
      let combinedFilter = event.component?.getCombinedFilter(true);
      const filters = {};

      if (combinedFilter?.length > 0) {
        combinedFilter = Array.isArray(combinedFilter[0])
          ? combinedFilter
          : [combinedFilter];

        lodashMap(combinedFilter, item => {
          if (Array.isArray(item)) {
            filters[item[FilterValueStructureEnum.KEY]] = [
              item[FilterValueStructureEnum.KEY],
              item[FilterValueStructureEnum.OPERATOR],
              replaceFarsiCharactersWithArabic(item[FilterValueStructureEnum.VALUE]),
            ];
          }
        });
      }

      setFilters(filters);
    }
  };

  return {
    forceIsTopFilterOpen,
    onTopFilterChange,
  };
};

export default useTopFilters;
