import type { ReactElement } from 'react';
import {
  Chip,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslate } from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import styled from 'styled-components';

import type {
  AutocompleteFilterInputViewPropsInterface,
  DropdownOption,
} from './autocomplete-filter-input.type';
import { useStyle, useTagStyle } from './autocomplete-filter-input.style';
import { SearchPopupButton } from '../../search-popup-button';
import { FieldType } from '../../../helper/Types';
const Tag = (props: {
  label: string;
  onDelete: (index: number) => void;
}): ReactElement => {
  const { label, onDelete } = props;
  const classes = useTagStyle();

  return (
    <div
      style={{
        maxWidth: 180,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      <Tooltip
        classes={{
          tooltip: classes.rootTooltip,
          arrow: classes.arrowTooltip,
        }}
        arrow
        placement="bottom-start"
        title={label}
      >
        <Chip
          className={classes.button}
          onDelete={onDelete}
          label={
            <Typography component="span" noWrap className={classes.spanItemButton}>
              {label}
            </Typography>
          }
        />
      </Tooltip>
    </div>
  );
};

const InputWrapper = styled('div')`
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: 'noWrap';
  overflow: 'hidden';

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.light,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);

const Listbox = styled('ul')`
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  overflow: auto;
  background-color: #fff;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

const AutocompleteFilterInputView = (
  props: AutocompleteFilterInputViewPropsInterface,
): ReactElement => {
  const {
    preparedOptions,
    selectedOptions,
    loading,
    open,
    maxSelection,
    buttonRefDialog,
    field,
    handleInputBaseClick,
    handleChangeInput,
    handleClickSearchDialog,
    searchPopupRequestData,
    handleSelectionWithSearchDialog,
    gridRowClickHandler,
    clearSelections,
    getRootProps,
    setAnchorEl,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getTagProps,
  } = props;

  const theme = useTheme();
  const classes = useStyle();
  const translate = useTranslate();

  return (
    <LightTooltip
      title={
        Array.isArray(selectedOptions) && selectedOptions.length > 0
          ? selectedOptions.map(item => {
              return <Typography color="inherit">{item.text}</Typography>;
            })
          : translate('dropdown.placeholder')
      }
      placement={theme.direction === 'rtl' ? 'left' : 'right'}
    >
      <div className={classes.content}>
        <div {...getRootProps()}>
          <InputWrapper ref={setAnchorEl} className={classes.inputWrapper}>
            <div className={classes.containerFilter}>
              <div className={classes.selectFilter}>
                {selectedOptions.map((option: DropdownOption, index: number) => (
                  /* The following `ignore` is because of `getTagProps` wrong and unknown type */
                  /* @ts-ignore */
                  <Tag label={option.text} {...getTagProps({ index })} />
                ))}
              </div>
              {selectedOptions.length > 2 ? <span>...</span> : <></>}
            </div>

            <input
              {...getInputProps()}
              onFocus={handleInputBaseClick}
              onInput={handleChangeInput}
            />
            <div className={classes.loadingWrapper}>
              {loading && (
                <CircularProgress size={18} className={classes.progress} />
              )}
            </div>
            {selectedOptions.length > 0 && (
              <IconButton
                aria-label="close"
                onClick={clearSelections}
                onTouchEnd={clearSelections}
                className={classes.iconButton}
              >
                <Icon fontSize="small">close</Icon>
              </IconButton>
            )}

            <SearchPopupButton
              disabled={false}
              relationInfo={{}}
              dropdownInPuzzleForm={false}
              dropdownMultipleSelection={true}
              isFilterInput={true}
              isProfile={false}
              isService={false} // FIXME: Check this prop from its parent
              resource={`${field.dropdown.moduleName}/${field.dropdown.tableName}`}
              source={field.name}
              dropdownMeta={field.dropdown}
              parentResource={''}
              relationResource={''}
              relationSource={''}
              changeFormValue={handleSelectionWithSearchDialog}
              changeFormValueByClickingOnRow={gridRowClickHandler}
              formData={{}}
              fieldName={field.name}
              isTodo={false}
              fieldId={''}
              parentResourceType={''}
              field={field as unknown as FieldType}
              buttonRef={buttonRefDialog}
              dropBasePreparedOptions={preparedOptions}
              dropBaseValue={Array.from(selectedOptions, item => Number(item.value))}
              buttonCustomStyle={{ padding: 5 }}
              customMakeRequest={searchPopupRequestData}
              clickHandler={handleClickSearchDialog}
              maxSelection={maxSelection}
            />
          </InputWrapper>
        </div>

        {selectedOptions.length < maxSelection && (
          <Listbox {...getListboxProps()}>
            {preparedOptions.length > 0 &&
              preparedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <span>{option.text}</span>
                  <CheckIcon fontSize="small" />
                </li>
              ))}
            {open && preparedOptions.length === 0 && (
              <li>
                <span>{translate('general.noRecordFound')}</span>
              </li>
            )}
          </Listbox>
        )}
      </div>
    </LightTooltip>
  );
};

export default AutocompleteFilterInputView;
