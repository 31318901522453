import { useEffect, useRef } from 'react';

const useResource = entries => {
  const { resource, relationResource, idDropDown } = entries;

  const updatedResourceRef = useRef<string>(resource);

  useEffect(() => {
    updatedResourceRef.current = resource;
  }, [resource, relationResource, idDropDown]);

  return { updatedResourceRef };
};

export default useResource;
