import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  dumbButton: {
    margin: '0 5px',
  },

  saveIconButton: {
    margin: '0 5px',
  },

  toolbar: {
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    padding: '1.5rem',
  },
}));
