import { type FC } from 'react';
import { useTranslate } from 'react-admin';
import { DataGrid } from 'devextreme-react';
import { useLocale } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  Column,
  FilterRow,
  GroupPanel,
  Grouping,
  Scrolling,
  LoadPanel,
  Paging,
} from 'devextreme-react/data-grid';

import {
  prepareCustomCell,
  prepareCustomCellForAdvancedPermissions,
} from './simple-grid.helper';
import { useStyles } from '../show-permissions.style';

import type { SimpleGridViewInterface } from './simple-grid.type';

const SimpleGridView: FC<SimpleGridViewInterface> = props => {
  const {
    rows,
    columns,
    groping = true,
    hasDelete,
    onDeleteRow,
    filterLocally,
    gridRef,
    onOptionChanged,
    useDynamicHeight,
    heigh,
    refreshTabData,
    isAdvanced,
    selectItems,
  } = props;

  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();
  const dynamicHeight = isAdvanced
    ? '71vh'
    : useDynamicHeight
    ? Math.min(rows.length * 6 + 80, heigh ? heigh : 80) + 'vh'
    : '';

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <DataGrid
        allowColumnResizing={true}
        columnResizingMode="widget"
        ref={gridRef}
        onOptionChanged={onOptionChanged}
        noDataText={translate('imageDialog.dataNotFound')}
        dataSource={rows}
        height={dynamicHeight}
        rowAlternationEnabled={true}
        rtlEnabled={locale !== 'en'}
        width="100%"
      >
        <Scrolling
          useNative={true}
          showScrollbar={'always'}
          preloadEnabled={true}
          mode={'virtual'}
          scrollByThumb={true}
        />

        <Paging enabled={false} />
        <LoadPanel enabled={true} />

        <Grouping contextMenuEnabled={groping} expandMode="rowClick" />
        <GroupPanel visible={groping} />

        <FilterRow visible={filterLocally} />
        {hasDelete && (
          <Column
            type="buttons"
            cellRender={({ data }) => (
              <DeleteIcon
                className={classes.iconInGrid}
                fontSize="small"
                onClick={onDeleteRow?.(data)}
              />
            )}
          />
        )}

        {columns.map(columnInfo => {
          return (
            <Column
              groupIndex={columnInfo?.groping}
              key={`column_${columnInfo.name}`}
              dataField={columnInfo.name}
              cellRender={
                isAdvanced
                  ? prepareCustomCellForAdvancedPermissions(
                      columnInfo,
                      refreshTabData,
                      selectItems,
                    )
                  : prepareCustomCell(columnInfo, refreshTabData)
              }
              alignment="center"
              caption={columnInfo.title}
              width={130}
            />
          );
        })}
      </DataGrid>
    </>
  );
};
export default SimpleGridView;
