import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  mapContainer: {
    mapContainer: {
      position: 'absolute',
      width: '100%',
      top: 0,
      bottom: 0,
      height: '100%',
      overflow: 'hidden', // Add overflow: hidden to prevent text scrolling
    },
  },

  map: {
    position: 'absolute',
    top: 0,
    bottom: 0,
  },

  sidebar: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: theme.spacing(1), // Adjust the padding according to your design
    borderRadius: theme.spacing(0.5), // Adjust the border radius according to your design
    margin: theme.spacing(1), // Adjust the margin according to your design
  },

  sidebarContent: {
    fontSize: '14px',
  },

  mapHeight: { width: '100%', height: '100%' },
  marker: { width: 100 },
}));
