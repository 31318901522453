import { memo, ReactElement, useEffect } from 'react';

import PermissionsView from './permissions.view';
import { useTranslate } from 'react-admin';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../type/actor-setup';
import { REMOVE_PERMISSION_URL, APPLY_PERMISSION_URL } from './permissions.helper';
import {
  showNotification,
  showNotificationForUnknownError,
} from '../../helper/general-function-helper';
import { PermissionAssignType } from './permission-assigned/permission-assigned.type';
import { CUSTOM_POST } from '../../core/data-Provider.helper';
import { getValue, IS_ADMIN_USER } from '../../core/configProvider';
import NotFound from '../NotFound';

const PermissionsController = (): ReactElement => {
  const isAdmin = getValue(IS_ADMIN_USER);

  /**
   * handle permission click
   * @function onChangePermissionClick
   * @param {'apply' | 'remove'} mode
   * @returns {void}
   */
  const translate = useTranslate();

  useEffect(() => {
    if (!isAdmin) {
      showNotificationForUnknownError(
        translate('ra.auth.authError'),
        translate,
        true,
      );
    }
  }, []);

  useEffect(() => {
    return () => {
      actorSetActionValue('userPermissionValue', {}, { replaceAll: true });
    };
  }, []);

  const onChangePermissionClick = (mode: 'apply' | 'remove') => (): void => {
    try {
      const permissionsInActor = actorGetActionValue('userPermissionValue');

      const {
        selectedAssignType = PermissionAssignType.User,
        selectedAssignees = [],
        selectedBranches = [],
        selectedMenus = [],
        selectedPermissionTypes = [],
      } = permissionsInActor ?? {};

      const selectedPermissions = {
        Subjects: {
          users:
            selectedAssignType === PermissionAssignType.User
              ? selectedAssignees
              : [],
          roles:
            selectedAssignType === PermissionAssignType.Role
              ? selectedAssignees
              : [],
          groups:
            selectedAssignType === PermissionAssignType.Chart
              ? selectedAssignees
              : [],
        },
        Menus: selectedMenus ?? [],
        Permissions: selectedPermissionTypes,
        departments: selectedBranches,
      };

      actorDispatch(
        'crudAction',
        {
          type: CUSTOM_POST,
          resource: mode === 'apply' ? APPLY_PERMISSION_URL : REMOVE_PERMISSION_URL,
          data: selectedPermissions,
          onSuccess: response => {
            if (response.data.code == 200) {
              showNotification(
                translate('ra.notification.successfully_executed'),
                'success',
                {
                  fromQuickCreateDialog: true,
                  forceSnackbar: true,
                },
              );
            } else {
              showNotification(response.data?.userMessage, 'error', {
                forceShowInDialog: true,
              });
            }
          },
          onFailure: response => {
            showNotification(response.data?.userMessage, 'error', {
              forceShowInDialog: true,
            });
          },
        },
        {
          disableDebounce: true,
          replaceAll: true,
          callerScopeName: 'PermissionsController => onChangePermissionClick',
        },
      );
    } catch {
      console.error('onChangePermissionClick failed');
    }
  };
  if (!isAdmin) {
    return <NotFound />;
  }

  return <PermissionsView onChangePermissionClick={onChangePermissionClick} />;
};

export default memo(PermissionsController, () => true);
