import { ReactElement } from 'react';
import { apiUrl } from '../../core/data-Provider.helper';
import { getValue, SESSION_ID, SUB_DOMAIN } from '../../core/configProvider';
import { getSessionIdInUrl } from '../../helper/UrlHelper';
import { useStyles } from './print-report.style';
import { PrintReportViewInterface } from './print-report.type';
import { ListToolbar } from '../list-toolbar';

const PrintReportView = (props: PrintReportViewInterface): ReactElement => {
  const { isRelation, iframeKey, actionBarProps, printResource, filterValues } =
    props;
  const classes = useStyles();
  const company = getValue(SUB_DOMAIN);
  const userSessionId = getValue(SESSION_ID) ?? getSessionIdInUrl();
  return (
    <div className={classes.listContainer}>
      {isRelation ? null : <ListToolbar actionBarProps={actionBarProps} />}
      <iframe
        src={`${apiUrl}/oauth2/${userSessionId}/v2/${printResource}/report?filters=${filterValues}&company=${company}`}
        key={iframeKey}
        name="report-print"
        id="report-print"
        className={classes.root}
      />
    </div>
  );
};
export default PrintReportView;
