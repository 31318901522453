import axios, { AxiosInstance, AxiosResponse } from 'axios';
import lodashGet from 'lodash/get';

import {
  API_NAME,
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from './configProvider';

import { isEmpty, isEmptyObject } from '../helper/data-helper';

import error from '../sound/error-sound.mp3';
import warning from '../sound/warning-sound.mp3';
import defaultSound from '../sound/default-sound.mp3';

import beep1 from '../sound/beep-01.wav';
import beep2 from '../sound/beep-02.wav';
import beep3 from '../sound/beep-03.wav';
import beep4 from '../sound/beep-04.wav';
import beep5 from '../sound/beep-05.wav';
import beep6 from '../sound/beep-06.wav';
import beep7 from '../sound/beep-07.wav';
import beep8 from '../sound/beep-08.wav';
import beep9 from '../sound/beep-09.wav';
import beep10 from '../sound/beep-10.wav';
import PhotoSoundOk from '../sound/PhotoSoundOk.mp3';
import ChartSoundOk from '../sound/ChartSoundOk.mp3';

import { openNewTab } from '../helper/QuickAccessHelper';
import { getOperatorByValueForFilter } from '../helper/TextHelper';
import { openPrintProcess } from '../helper/print-helper';
import { showNotification } from '../helper/general-function-helper';
import { actorGetActionValue } from '../type/actor-setup';
import { getFieldByName, getReportParameterFieldByKey } from '../helper/MetaHelper';
import { prepareDateTimeFilterValue } from '../helper/FilterHelper';
import { FinalFiltersType } from '../component/filter-form';

// -------------------------
export const GET_DROPDOWN = 'GET_DROPDOWN';
export const GET_MENU = 'GET_MENU';
export const GET_CODING = 'GET_CODING';
export const GET_META = 'GET_META';
export const RUN_SERVICE = 'RUN_SERVICE';
export const GET_REPORT = 'GET_REPORT';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const CUSTOM_UPDATE = 'CUSTOM_UPDATE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_FILE = 'GET_FILE';
export const GET_QUICK_ACCESS = 'GET_QUICK_ACCESS';
export const INLINE_CELL_UPDATE = 'INLINE_CELL_UPDATE';
export const GET_DROPDOWN_DEFAULT_VALUES = 'GET_DROPDOWN_DEFAULT_VALUES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_AUTHORITY_DELEGATION = 'GET_AUTHORITY_DELEGATION';
export const PUT_AUTHORITY_DELEGATION = 'PUT_AUTHORITY_DELEGATION';
export const POST_STREAM_FILE = 'POST_STREAM_FILE';
export const POST_STREAM_MULTIPLE_FILE = 'POST_STREAM_MULTIPLE_FILE';
export const GET_DEFAULT_FORM_VALUES = 'GET_DEFAULT_FORM_VALUES';
export const RUN_VALIDATION = 'RUN_VALIDATION';
export const LOG_ERROR = 'LOG_ERROR';
export const CUSTOM_GET = 'CUSTOM_GET';
export const CUSTOM_POST = 'CUSTOM_POST';
export const PROCESS_HISTORY_RESOURCE = 'workflow/processtaskexecutionhistory';
// -------------------------
export const apiUrl = getValue<string>(API_URL);
export const apiVersion = getValue<string>(API_VERSION);
export const apiName = getValue<string>(API_NAME);
// -------------------------
export const httpClient: AxiosInstance = axios.create();
httpClient.defaults.timeout = 240000; // wait 240 seconds
// -------------------------

/**
 * @function isResponseOk
 * @param {AxiosResponse} response
 * @returns {boolean} boolean
 */
export const isResponseOk = (response: AxiosResponse): boolean => {
  return (
    response.data &&
    response.data.code >= 200 &&
    response.data.code < 400 &&
    (response.data.messageType === 'ok' || response.data.messageType === 'ignore')
  );
};

/**
 * @function getBaseUrl
 * @returns {string}
 */
export const getBaseUrl = (): string => {
  return `${apiUrl}/${apiVersion}`;
};

/**
 * @function getResponseMessage
 * @param {AxiosResponse} response
 * @returns {string}
 */

export const getResponseMessage = dataKeyInResponse => {
  let errorType = dataKeyInResponse.messageType;
  let userMessage = lodashGet(dataKeyInResponse, 'userMessage');
  const code = lodashGet(dataKeyInResponse, 'code');
  const reqId = lodashGet(dataKeyInResponse, 'requestId');

  if (errorType == null) {
    errorType = 'Error';
  }

  userMessage = typeof userMessage === 'string' ? userMessage : code.toString();
  const output = (userMessage || code || errorType) + `^${reqId}` + `CODE${code}`;
  return output;
};

/**
 * this function checks if the response error should parse or show `userMessage`
 * @function shouldParseResponseError
 * @param {AxiosResponse} response
 * @returns {boolean} boolean
 */
export const shouldParseResponseError = response => {
  return (
    !isEmptyObject(response.data.data) &&
    // prettier-ignore
    [6033, 6015, 6057, 7008, 6076, 6080, 6081, 6001,7042,7046,7044].indexOf(response.data.code) === -1
  );
};

/**
 * @function shouldParseResponseAdditionalDataError
 * @param {AxiosResponse} response
 * @returns {boolean} boolean
 */
export const shouldParseResponseAdditionalDataError = (
  response: AxiosResponse,
): boolean => {
  return (
    !response.data.data &&
    !isEmptyObject(response.data.additionalData) &&
    response?.data?.additionalData[0]?.exception?.data
  );
};

/**
 * @function getFilterByValue
 * @param {object} filters
 * @returns { string[] } string[]
 */
export const getFilterByValue = (name: string, value: string): string[] => {
  const operator = getOperatorByValueForFilter(value);
  return [name, operator, value];
};

/**
 * @function getFilterByValue
 * @param {Record<string, string | string[]>} filters
 * @returns { FinalFiltersType } FinalFiltersType
 */
export const prepareFilterFromObject = (
  filters: Record<string, any>,
): FinalFiltersType => {
  const preparedListFilter: FinalFiltersType = [];
  const currentResource = actorGetActionValue('resources')?.current?.value;
  const metaData = actorGetActionValue('metaData', currentResource);
  for (const key in filters) {
    if (key === 'undefined' || filters[key] === 'and') continue;
    const filterField = isEmpty(metaData?.reportId)
      ? getFieldByName(metaData, key)
      : getReportParameterFieldByKey(metaData, key);

    let isDateTimeField = false;
    if (filterField != null) {
      isDateTimeField =
        filterField.dataType.simple === 'date' ||
        filterField?.dataType.simple === 'datetime';
    }

    if (preparedListFilter.length) {
      preparedListFilter.push('and');
    }

    // if filter value is prepared
    if (Array.isArray(filters[key])) {
      preparedListFilter.push(
        isDateTimeField ? prepareDateTimeFilterValue(filters[key]) : filters[key],
      );
    }
    // if it's key=value , then prepare as array
    else {
      preparedListFilter.push(
        isDateTimeField
          ? prepareDateTimeFilterValue(getFilterByValue(key, filters[key]))
          : getFilterByValue(key, filters[key]),
      );
    }
  }
  return preparedListFilter;
};

/**
 * @function checkResponseAndPlayAudio
 * @param { response: { messageSound :string } } response
 * @returns {void} void
 */
export const checkResponseAndPlayAudio = (response: {
  messageSound: string;
}): void => {
  const messageSound: string = response?.messageSound;
  if (isEmpty(messageSound)) {
    return;
  }

  const objectSound = {
    error: error,
    warning: warning,
    default: defaultSound,
    beep1: beep1,
    beep2: beep2,
    beep3: beep3,
    beep4: beep4,
    beep5: beep5,
    beep6: beep6,
    beep7: beep7,
    beep8: beep8,
    beep9: beep9,
    beep10: beep10,
    chartsoundok: ChartSoundOk,
    photosoundok: PhotoSoundOk,
  };

  let soundFile = objectSound.default;
  try {
    soundFile = objectSound[messageSound.toString().toLowerCase()];
  } catch (error) {
    soundFile = objectSound.default;
  }

  const responseAudio = new Audio(soundFile);
  responseAudio.loop = false;
  responseAudio.play();
};

/**
 * if in the data `recordOpenForm` or `recordKey` is existes this function opens it in new tab.
 * @function checkResponseForOpenNewTab
 * @param { Record<string, string> } response
 * @returns { void | undefined } void | undefined
 */
export const checkResponseForOpenNewTab = (
  response: Record<string, string>,
  filters,
): void | undefined => {
  if (
    isEmptyObject(response) ||
    !response.actionOutput ||
    isEmptyObject(response.actionOutput)
  ) {
    return;
  }

  const {
    recordKey = [],
    recordOpenForm = [],
    recordEditForm = [],
    printReport = [],
    additionalData = {},
  } = lodashGet(response, ['actionOutput'], {});

  const newTabs: number[] = [];
  let index = 0;

  // check recordKey
  if (recordKey && recordKey.length) {
    recordKey.forEach((item: any) => {
      newTabs[index++] = item;
    });
  }

  // check recordOpenForm
  if (recordOpenForm && recordOpenForm.length) {
    recordOpenForm.forEach((item: any) => {
      newTabs[index++] = item;
    });
  }

  // check recordEditForm
  if (recordEditForm && recordEditForm.length) {
    recordEditForm.forEach((item: any) => {
      newTabs[index++] = item;
    });
  }

  if (!isEmptyObject(additionalData)) {
    const linkType = additionalData.linktype ?? '_self';

    //RCT-4423
    if (!isEmpty(additionalData.link)) {
      openNewTab(additionalData.link, linkType);
    }

    //RCT-4423
    if (!isEmpty(additionalData.apilink)) {
      const userSessionId = getValue(SESSION_ID);
      const printLink = `${apiUrl}/oauth2/${userSessionId}/${apiVersion}${additionalData.apilink}`;

      openNewTab(printLink, linkType);
    }
  }

  // Open new tabs
  if (newTabs.length) {
    newTabs.forEach((item: any) => {
      setTimeout(() => {
        openNewTab(item as string);
      }, 100);
    });
  }

  if (printReport.length) {
    for (const print of printReport) {
      if (typeof print !== 'object') continue;
      openPrintProcess(print, print.withPreview, filters);
      // TODO:  directly send to printer
    }
  }
};

/**
 * @function showWarningsSnackbar
 * @param {object} response
 * @returns {void} void
 */
export const showWarningsSnackbar = (response: {
  userMessage: string;
  messageType: string;
}): void => {
  const { messageType, userMessage } = response;

  if (messageType === 'warning' && userMessage) {
    showNotification(userMessage, 'warning', { forceSnackbar: true });
  }
};
