import { useContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate, useLocale, GET_LIST } from 'react-admin';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import { Button, Icon, IconButton } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import lodashGet from 'lodash/get';

import { getPrintResource, getPrintList, PrintPopupOpener } from '../helper/PrintMetaHelper';
import { getDefaultReportSort, getReportInfo, prepareReportFilter } from '../helper/MetaHelper';
import { NewMetaContext } from './NewMetaContext';
import { actorDispatch, FormKeyMode, actorGetActionValue, actorOnDispatch, RecordKeyMode } from '../type/actor-setup';
import { removeOnDispatches, showNotification, } from '../helper/general-function-helper';
import { getCurrentRecord } from '../helper/ActorHelper';
import { openPrintInPopup } from '../helper/print-helper';
import useKeyPress from '../hooks/useKeyPress';
import { getSelectedItemInfo } from '../component/form/hooks/grid-data.helper';
import { isEmptyObject } from '../helper/data-helper';
import { CUSTOM_POST } from '../core/data-Provider.helper';
import dataProvider from '../core/dataProvider';


const styles = theme => ({
  iconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },

  icon: {
    fontSize: 20,
  },
});

const PrintButtonContainer = props => {
  const {
    classes,
    selectedIds,
    isForceEnabled = false,
    button,
    translate,
    resource,
    filterValues,
    currentSort,
    metaData,
    relationMode,
  } = props;
  const locale = useLocale();

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const { getMeta } = useContext(NewMetaContext);

  const _metaData = metaData ?? getMeta(resource);

  useEffect(() => {
    const onDispatches = [];

    const listenerId = actorOnDispatch('loading', loadingRecord => {
      setLoading(
        loadingRecord['service'] ||
        loadingRecord['processChangeLineButtons'] ||
        loadingRecord[resource] ||
        false,
      );
    });

    onDispatches.push({
      actionName: 'loading',
      listenerId,
    });

    return () => {
      removeOnDispatches(onDispatches);
    };
  }, []);

  /**
   * it will compute a list of reports from metadata and set their disable property base of their report id
   * @constant printList
   * @returns {Array<Object>} print list
   */
  const printList = useMemo(() => {
    const list = getPrintList(_metaData);
    if (!list) {
      return [];
    }
    for (const print of list) {
      print.disable = print.reportId ? false : true;
    }
    return list;
  }, [_metaData]);

  const isPrintEnabled = !loading && printList && printList.length;

  const disableAll =
    !selectedIds || !selectedIds.length
      ? true
      : false;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * it will create a new promise and pass resolve and reject functions to get meta in meta context
   * then execute one of them when `getMeta` function completed (success or failure)
   * @function getPrintMeta
   * @param {Object} print
   * @returns {Promise<Object>}
   */
  const getPrintMeta = print => {
    return new Promise((res, rej) => {
      if (
        !printList ||
        !printList.filter(printInPrintList => printInPrintList.id === print.id)
          .length
      ) {
        rej(translate('print.printIdNotFoundInMeta'));
      }
      const printResource = getPrintResource(print.id);
      getMeta(printResource, res, rej);
    });
  };

  const computeReportParameter = reportId => {
    //TODO selectedIds must be get from gridIDs.selectedIDs. In show_record_with_relation selectedIDs receive from props
    const reportMeta = getReportInfo(_metaData, reportId);

    const selectedRowData = getSelectedItemInfo(resource, selectedIds[0]);
    const recordInActor = getCurrentRecord();

    return prepareReportFilter({
      meta: reportMeta,
      record: selectedRowData ?? recordInActor,
      formatByAnd: true,
    });
  };

  /**
   * @function getPrintDataServerSide 
   * @param {Object} print
   * @return {void}
   */
  const getPrintDataServerSide = print => {
    let printFilter = [];

    //don't change priority of if/else.because in one state "isForceEnabled and print && print.id && print.reportId" at the same time happened
    if (isForceEnabled) {
      printFilter = filterValues;
    } else if (print && print.id && print.reportId) {
      printFilter = computeReportParameter(print.reportId);
    } else {
      console.log('no report in print info: ', print);
      handleClose();
      return;
    }

    const gridData = actorGetActionValue('gridData', resource);
    const currentRecord = actorGetActionValue('record', resource)?.[FormKeyMode.ROOT]?.[RecordKeyMode.FULL] ?? {};

    let selectedRowData = [currentRecord];
    if (!isEmptyObject(gridData?.selectedIds)) {
      selectedRowData = Array.isArray(gridData?.data)
        ? gridData?.data.filter(item => gridData?.selectedIds.indexOf(item?.id.toString()) !== -1)
        : null;
    }

    if (!isEmptyObject(selectedRowData)) {
      actorDispatch(
        'crudAction',
        {
          type: CUSTOM_POST,
          resource: 'cache',
          data: selectedRowData,
          onSuccess: (response) => {
            openPrintInPopup(print.id, printFilter, `cacheKey=${response?.data?.data?.key ?? ''}`);
            handleClose();
          },
          onFailure: () => {
            //open print dialog anyway
            openPrintInPopup(print.id, printFilter);
          },
        },
      );
    } else {
      openPrintInPopup(print.id, printFilter);
      handleClose();
    }
  };

  /**
   * @function getPrintDataClientSide
   * @param { Object } print
   * @returns {void}  `
   */
  const getPrintDataClientSide = async print => {
    let reportData;

    if (isForceEnabled) {
      const filter = filterValues;
      const sort = getDefaultReportSort(metaData, print.reportId);
      const response = await dataProvider(GET_LIST, `report/${print.reportId}`, {
        filter: filter,
        sort: sort,
        pagination: { page: 1, perPage: 10000 }, // only change pagination
        queryParams: {
          putCaptionInHeader: true,
        },
        // server might give back an object and break everything!
        rawResponse: true,
      });

      reportData = response.data;
    } else if (print && print.id && print.reportId) {
      const filter = computeReportParameter(print.reportId);
      const sort = getDefaultReportSort(metaData, print.reportId);
      const response = await dataProvider(GET_LIST, `report/${print.reportId}`, {
        filter: filter,
        pagination: { page: 1, perPage: 10000 },
        sort,
        queryParams: {
          putCaptionInHeader: true,
        },
        // server might give back an object and break everything!
        rawResponse: true,
      });

      reportData = response.data;
    } else {
      console.log('no report in print info: ', print);
      handleClose();
      return;
    }

    const printResource = getPrintResource(print.id);
    const opener = new PrintPopupOpener();
    opener.setTranslate(translate);
    opener.openPrintInNewWindow(
      lodashGet(print, ['translatedTitle', locale], print.id),
      getMeta(printResource),
      reportData,
    );

    handleClose();
  };

  /**
   * it will compute the parameters that the data provider needs to get print data, then trigger it to get
   * with received metadata and computed parameters. then open a print PrintPopupOpener if data got
   * successfully of call `handleClose` on failure
   * @function getPrintData
   * @param {Object} print
   * @returns {void}
   */
  const getPrintData = async print => {
    const clientSide = print.withPreview;
    if (clientSide) {
      await getPrintDataClientSide(print);
      return;
    }

    getPrintDataServerSide(print);

  };

  /**
   * it will call `getPrintMeta` function to get print metadata and wait for it succeeds or fails
   * then call `getPrintData` if meta received successfully or log an error message to the console
   * @function handlePrintReport
   * @param {Object} print
   * @returns {void} void
   */
  const handlePrintReport = print => () => {
    getPrintMeta(print)
      .then(() => {
        getPrintData(print);
      })
      .catch(printMetaError => {
        showNotification(printMetaError, 'error', {
          forceSnackbar: true,
        });
      });
  };

  /**
   * @function checkIsPrintEnableDisabled
   * @param {Object} print
   * @returns {boolean} printDisabled
   */
  const checkIsPrintDisabled = print => () =>
    (!isForceEnabled && disableAll) ||
    print.disable ||
    (_metaData && !_metaData.reportId && !getReportInfo(_metaData, print.reportId));

  const handlePrintShortcutKeyPress = () => {
    if (
      isPrintEnabled &&
      !checkIsPrintDisabled(printList[0])() &&
      printList.length > 0
    ) {
      handlePrintReport(printList[0])();
    }
  };
  useKeyPress(['p'], handlePrintShortcutKeyPress, true);

  return (
    <>
      {button ? (
        <Button onClick={handleClick} color="primary" disabled={!isPrintEnabled}>
          <Icon>print</Icon>
          {translate('grid.print')}
        </Button>
      ) : (
        <IconButton
          className={classes.iconButton}
          onClick={handleClick}
          color="primary"
          disabled={!isPrintEnabled}
          id="printButton"
        >
          <Icon className={classNames(classes.icon)}>print</Icon>
        </IconButton>
      )}

      <Menu
        id="service-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {printList &&
          printList.map(print => (
            <MenuItem
              key={print.id}
              data-test-print-id={print.id}
              className={classes.menuItem}
              disabled={
                (!isForceEnabled && disableAll) ||
                print.disable ||
                (_metaData &&
                  !_metaData.reportId &&
                  !getReportInfo(_metaData, print.reportId))
              }
              onClick={handlePrintReport(print)}
            >
              {lodashGet(
                print,
                ['translatedTitle', locale],
                lodashGet(print, 'title'),
              )}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

PrintButtonContainer.propTypes = {
  resource: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  selectedIds: PropTypes.array,
  isForceEnabled: PropTypes.bool,
  button: PropTypes.bool,
  relationMode: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(
  translate(PrintButtonContainer),
);
