import { useEffect, useRef, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import lodashFilter from 'lodash/filter';
import lodashMerge from 'lodash/merge';

import { clone, isEmpty, isEmptyObject } from '../../../helper/data-helper';
import {
  DROPDOWN_FIELD,
  getTypeByField,
  inputTypes,
} from '../../../helper/InputHelper';
import {
  removeOnDispatches,
  showNotification,
} from '../../../helper/general-function-helper';
import { isRelationCellEditableWithoutTypeChecking } from '../../relation-panel/relation-panel.helper';
import {
  getInputsInitialAppearanceCharacteristics,
  getProcessTaskByMetaDataAndRecord,
} from '../../../helper/meta-helper';
import {
  FormKeyMode,
  URLInfo,
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
} from '../../../type/actor-setup';
import {
  getGridAdditionalData,
  getReportEditableColumnServices,
  prepareGridInputsCharacteristics,
  runReportEditService,
} from '../grid.helper';

import type {
  ApiDataInterface,
  InputsCustomFunctions,
  LastFocusCell,
  OnChangeInputParameter,
  OnEditingStartInterface,
} from '../grid.type';
import type {
  FieldType,
  GeneralMetaData,
  GlobalParametersInterface,
  MetaData,
  MetaDataBase,
} from '../../../helper/Types';
import { InputAppearanceCharacteristics } from '../../../helper/meta-helper.type';
import { handleInlineEdit } from '../../../api/grid-api';
import { runServiceValidationClient } from '../../../helper/validation-helper';
import { useFormSave } from '../../form/hooks/use-form-save';
import { getFormDefaultValues } from '../../form/form.helper';

const useEmbedForm = entries => {
  const {
    resource,
    gridRef,
    onRowClick,
    idDropDown,
    isReport,
    hasEdit,
    metaData,
    parentInfo,
    relationMode,
    parentRecord,
    onlyClientSort,
    changeSorting,
    updateGridDataSource,
    prepareAllFields,
    preparedFieldsRef,
    relationResource,
    relation,
    summaryDataRef,
  } = entries;

  let formAsyncActionList: Promise<unknown>[] = [];
  let inputsInitialAppearanceCharacteristics: Record<
    string,
    InputAppearanceCharacteristics
  > = {};

  const locale = useLocale();
  const translate = useTranslate();

  const [formMode, setFormMode] = useState<'edit' | 'add'>('edit');

  const lastFocusCellRef = useRef<LastFocusCell>({});
  const defaultFormValuesRef = useRef<Record<string, unknown>>({});
  const inputsCustomFunctionRef = useRef<Record<string, InputsCustomFunctions>>({});
  const lastSearchedDropDownData = useRef<Record<string, unknown>[]>([{}]);

  const reportEditableColumnServices = getReportEditableColumnServices(metaData);

  useEffect(() => {
    const onDispatchId = actorOnDispatch('quickDialog', () => {
      setFormMode('edit');
    });

    return () => {
      actorRemoveAction({
        actionName: 'quickDialog',
        listenerId: onDispatchId,
      });
    };
  }, []);

  useEffect(() => {
    const listenerId = actorOnDispatch('gridFormData', gridFormData => {
      if (
        gridFormData == null ||
        isEmptyObject(metaData) ||
        isEmpty(resource) ||
        isEmpty(parentInfo?.parentResource)
      ) {
        return;
      }

      inputsInitialAppearanceCharacteristics = prepareGridInputsCharacteristics({
        resource,
        metaData,
        parentResource: parentInfo?.parentResource,
      });
    });

    return () => {
      removeOnDispatches([
        {
          actionName: 'gridFormData',
          listenerId,
        },
      ]);
    };
  }, [resource, metaData, parentInfo]);

  const formSave = useFormSave('gridForm');

  // main functions
  function onFailureSaveForm(error: string): void {
    actorSetActionValue('loading', false, { path: resource });

    //Don't set "error" type in showNotification, because focus on cell not working
    if (typeof error == 'string') {
      if (typeof error == 'string') {
        if (typeof error.split == 'function') showNotification(error.split('^')[0]);
        else showNotification(error);
      }
    }

    gridRef.current?.instance.endCustomLoading();
  }
  function onEditorPreparing(e: Record<string, any>): void {
    //set default text field for filter row
    if (e.parentType === 'filterRow') {
      e.editorName = 'dxTextBox';
      return;
    }
  }
  function handleOnRowClick(data: Record<string, unknown>, rowType?: string): void {
    if (rowType === 'group') return;
    if (typeof onRowClick == 'function') {
      return onRowClick(data);
    }
  }
  function focusOnLastInput(): void {
    const gridInstance = gridRef.current?.instance;

    if (!gridInstance) return;

    const cellElement = gridInstance.getCellElement(
      lastFocusCellRef.current?.rowIndex as number,
      lastFocusCellRef.current?.columnIndex as number,
    );

    if (cellElement) {
      gridInstance.focus(cellElement);
    }
  }
  function allowEditInline(field: FieldType): boolean {
    if (isEmptyObject(field)) return false;
    const fieldType = getTypeByField(field);

    //boolean field handled in handleInlineEdit
    if (
      reportEditableColumnServices[field.name] &&
      fieldType !== inputTypes.BOOLEAN_FIELD
    )
      return true;

    //show disable field value
    if (formMode == 'add') return true;

    //boolean field handled in handleInlineEdit
    //disable inline edit in dropdown popup
    if (
      !isEmpty(idDropDown) ||
      fieldType == inputTypes.BOOLEAN_FIELD ||
      isReport ||
      !hasEdit
    )
      return false;

    return (
      isRelationCellEditableWithoutTypeChecking(field, metaData, hasEdit) &&
      isEnableInput(field.name)
    );
  }
  function onEditCanceled(): void {
    actorDispatch(
      'gridFormData',
      {},
      {
        replaceAll: true,
      },
    );
    if (formMode != 'edit') setFormMode('edit');
  }

  function onEditingStart(event: OnEditingStartInterface): void {
    if (formMode == 'add') return;

    if (!isEmptyObject(event.data)) {
      defaultFormValuesRef.current = {}; //dont use default value in edit mode

      actorDispatch('gridFormData', event.data, {
        replaceAll: true,
        callerScopeName: 'GridController => onEditingStart',
      });
    } else {
      actorDispatch(
        'gridFormData',
        {},
        {
          replaceAll: true,
          callerScopeName: 'GridController => onEditingStart 2',
        },
      );
      gridRef.current?.instance.cancelEditData();
      if (formMode != 'edit') {
        setFormMode('edit');
      }
    }
  }
  function onCellPrepared(event: Record<string, any>): void {
    if (event.data && event.rowType === 'data' && event.column.name) {
      const colorColumnName = `__${event.column.name}_backgroundcolor`;
      if (event.data[colorColumnName] && event.data[colorColumnName] != '')
        event.cellElement.style.backgroundColor = event.data[colorColumnName];
    }
  }
  function isEnableInput(fieldName: string): boolean {
    if (reportEditableColumnServices[fieldName]) return true;

    const activeRecord = actorGetActionValue('gridFormData');
    const field = getFieldObjectByInputName(fieldName);

    inputsInitialAppearanceCharacteristics = prepareGridInputsCharacteristics({
      resource,
      metaData,
      parentResource: parentInfo?.parentResource,
    });

    if (relationMode && field && resource && metaData && activeRecord) {
      if (
        !inputsInitialAppearanceCharacteristics[field.name]?.enable ||
        !inputsInitialAppearanceCharacteristics[field.name]?.visible
      ) {
        return false;
      }
    }

    //disable grid form in dropdown popup
    if (!field || !hasEdit || !isEmpty(idDropDown)) {
      return false;
    }

    return true;
  }
  const onChangeInput = async (
    parameters: OnChangeInputParameter,
  ): Promise<void> => {
    const { fieldName, value, submitValue = true } = parameters;

    let gridFormData = actorGetActionValue('gridFormData')!;

    if (value == gridFormData[fieldName]) return; //if value not changed, return

    try {
      const field = getFieldObjectByInputName(fieldName);
      if (field) {
        gridFormData = { ...gridFormData, [fieldName]: value };

        let parentMetaData: MetaData | undefined;

        if (parentInfo?.parentResource) {
          parentMetaData =
            actorGetActionValue('metaData')?.[parentInfo.parentResource];
        }

        const formDataCharacteristics = getInputsInitialAppearanceCharacteristics({
          parentMetaData: parentMetaData as GeneralMetaData | undefined,
          currentMetaData: metaData,
          parentRecord: parentRecord,
          currentRecord: gridFormData,
          currentProcessInfo: getProcessTaskByMetaDataAndRecord(
            metaData as GeneralMetaData,
            gridFormData,
          ),
        });

        for (const name in inputsCustomFunctionRef.current) {
          if (
            inputsCustomFunctionRef.current[name] &&
            formDataCharacteristics?.[name]
          ) {
            inputsCustomFunctionRef.current[name].updateFormDataCharacteristics?.(
              formDataCharacteristics[name],
            );
          }
        }

        actorSetActionValue('gridFormData', gridFormData, {
          callerScopeName: 'GridController => onChangeInput',
        });

        if (submitValue) {
          formMode == 'add' &&
            formAsyncActionList.push(runServiceValidation(fieldName));
          formMode == 'edit' && editCellValue(fieldName); //don't need to run service in inline edit
        }
      } else if (reportEditableColumnServices[fieldName]) {
        if (submitValue) {
          runReportEditService({
            fieldName,
            metaData,
            resource,
            gridFormData: { ...gridFormData, [fieldName]: value },
            onSuccess: onSuccessSaveCreateForm,
          });
        }
      }
    } catch (error) {
      console.log('changeFormValueWithValidate failed: ', error);
    }
  };
  function onInitNewRow(): void {
    actorDispatch(
      'gridFormData',
      {},
      {
        replaceAll: true,
        callerScopeName: 'GridController => onInitNewRow',
      },
    );

    prepareAllFields().then(() => {
      prepareDefaultValues().then(() => {
        if (formMode != 'add') {
          setFormMode('add');
          setTimeout(() => {
            if (!gridRef.current?.instance.hasEditData()) {
              gridRef.current?.instance.addRow();
            }
          }, 500);
        }
      });
    });
  }
  function onCellClick(e: any): void {
    if (!onlyClientSort && e.rowType == 'header' && e.column?.command !== 'select') {
      changeSorting(e);
    }
  }
  function onSaving(event: Record<string, any>): void {
    if (formMode == 'add') {
      event.cancel = true;
      saveRow(event);
      lastFocusCellRef.current = {};
    }
  }

  // helper functions
  function getFieldObjectByInputName(inputName: string): FieldType | null {
    const { allFields } = preparedFieldsRef.current; // should remove relationHiddenFields
    const field = lodashFilter(
      allFields,
      row => row.name == inputName || row.relatedName == inputName,
    )[0];

    return field ?? null;
  }
  function editCellValue(fieldName: string): void {
    const gridFormData = actorGetActionValue('gridFormData')!;
    if (!isEmptyObject(gridFormData)) {
      const changedData = gridFormData[fieldName];
      const filedObject = getFieldObjectByInputName(fieldName);
      if (!filedObject) return;

      const rowInfo = { id: gridFormData.id };
      const formData = { [filedObject.name]: changedData };

      if (filedObject) {
        if (getTypeByField(filedObject) === DROPDOWN_FIELD) {
          const selectedDropDownInfo = lodashFilter(
            lastSearchedDropDownData.current[filedObject.name]?.result,
            formData,
          );
          rowInfo[`__dropdownvalue_${filedObject.name}`] = selectedDropDownInfo[0];
        }
        handleInlineEdit(
          formData,
          rowInfo,
          resource,
          onFailureSaveForm,
          onSuccessInlineEditForm,
          false,
        );
      }
    }
  }
  const runServiceValidation = async (fieldName: string): Promise<void> => {
    const gridFormData = actorGetActionValue('gridFormData')!;
    await runServiceValidationClient(
      preparedFieldsRef.current.allFields,
      fieldName,
      gridFormData,
      locale,
      metaData as MetaDataBase,
      resource,
      relationResource,
      ({ fieldName, value }) => (gridFormData[fieldName] = value), //update form data
      {},
      translate,
      true,
      showNotification,
      false,
    ).then(() => {
      actorDispatch('gridFormData', gridFormData, {
        replaceAll: true,
        callerScopeName: 'GridController => runServiceValidation',
      });
    });
  };
  async function saveRow(e: Record<string, any>): Promise<void> {
    if (formMode != 'add' || actorGetActionValue('loading', resource)) {
      return;
    }
    actorSetActionValue('loading', true, { path: resource });
    e.cancel = true; //don't close form

    //run service validation
    const gridInstance = gridRef.current?.instance;
    if (gridInstance) {
      gridInstance.focus(gridInstance.getCellElement(0, 1) as Element);
    }

    const { parentFieldName, childFieldName } = relation ?? {};

    let quickCreateData = {};
    if (!isEmpty(childFieldName)) {
      quickCreateData = {
        [childFieldName!]: lodashGet(parentRecord, parentFieldName),
      };
    }

    try {
      await Promise.all(formAsyncActionList);
      formAsyncActionList = [];
    } catch (error) {
      console.error('saving error => ', error);
    }

    const gridFormData = actorGetActionValue('gridFormData')!;
    formSave(gridFormData, {
      id: null,
      isCreateMode: true,
      additionalFormData: quickCreateData,
      relationMode,
      formName: 'gridForm',
      resource: resource,
      onFailure: onFailureSaveForm,
      onSuccess: onSuccessSaveCreateForm,
    });
  }
  const onSuccessSaveCreateForm = (): void => {
    actorDispatch('refreshView', 'record', {
      callerScopeName: 'GridController => onSuccessSaveCreateForm',
      disableDebounce: true,
    });

    if (parentInfo && relation?.childFieldName) {
      getGridAdditionalData({
        parentInfo: parentInfo,
        childFieldName: relation?.childFieldName,
        parentFieldName: relation?.parentFieldName ?? '',
        relationMetaData: metaData,
        relationResource: resource,
        onSuccess: handleSuccessGetAdditionalData,
      });
    }

    actorDispatch('gridFormData', clone(defaultFormValuesRef.current), {
      replaceAll: true,
      callerScopeName: 'GridController => onSuccessSaveCreateForm',
    });

    actorSetActionValue('loading', false, {
      path: resource,
      callerScopeName: 'GridController => onSuccessSaveCreateForm',
    });
  };
  async function prepareDefaultValues(): Promise<void> {
    if (!isEmptyObject(defaultFormValuesRef.current)) {
      const gridFormData = actorGetActionValue('gridFormData')!;
      actorDispatch(
        'gridFormData',
        lodashMerge(gridFormData, clone(defaultFormValuesRef.current)),
        {
          replaceAll: true,
          callerScopeName: 'GridController => prepareDefaultValues',
        },
      );
      return;
    }

    const globalParameters = actorGetActionValue(
      'profile',
      'profileData.globalparameters',
    );
    const urlInfo = actorGetActionValue('urlInfo');
    const currentResource = { value: resource, type: FormKeyMode.RELATION };

    if (!parentInfo) return;

    const formDefaultValues = await getFormDefaultValues(
      urlInfo as URLInfo,
      parentInfo,
      preparedFieldsRef.current.allFields,
      globalParameters as GlobalParametersInterface,
      currentResource,
      true,
    );

    if (formDefaultValues) {
      defaultFormValuesRef.current = clone(formDefaultValues);

      const gridFormData = actorGetActionValue('gridFormData')!;
      actorDispatch(
        'gridFormData',
        lodashMerge(gridFormData, clone(formDefaultValues)),
        {
          replaceAll: true,
          callerScopeName: 'GridController => prepareDefaultValues 2',
        },
      );
    }
  }
  const onSuccessInlineEditForm = (editedData: Record<string, unknown>): void => {
    const dataSource = gridRef.current?.instance.getDataSource();
    const store = dataSource?.store();
    store.update(editedData.id, editedData);
    if (parentInfo && relation?.childFieldName) {
      getGridAdditionalData({
        parentInfo: parentInfo,
        childFieldName: relation?.childFieldName,
        parentFieldName: relation?.parentFieldName ?? '',
        relationMetaData: metaData,
        relationResource: resource,
        onSuccess: handleSuccessGetAdditionalData,
      });
    } else {
      void updateGridDataSource();
    }
  };
  const handleSuccessGetAdditionalData = (data: ApiDataInterface): void => {
    summaryDataRef.current = data.additionalData.summary;
    actorSetActionValue('additionalData', data.additionalData, {
      path: resource,
    });
    void updateGridDataSource();
  };

  return {
    onFailureSaveForm,
    onEditorPreparing,
    handleOnRowClick,
    focusOnLastInput,
    allowEditInline,
    onEditCanceled,
    onEditingStart,
    onCellPrepared,
    isEnableInput,
    onChangeInput,
    onInitNewRow,
    onCellClick,
    setFormMode,
    onSaving,
    inputsCustomFunctionRef,
    lastFocusCellRef,
    formMode,
  };
};

export default useEmbedForm;
