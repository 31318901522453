/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { type FC, useEffect, useRef } from 'react';
import { useLocale } from 'react-admin';

import { isJsonEncodedString } from '../../../helper/data-helper';
import { useStyles } from './map-box.style';
import { parseJSON } from '../../../core/configProvider';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import './style.css';

import type { MapBoxInterface, MapLegendList } from './map-box.type';
import type { MapBoxDrawRefFunctions } from '../../dynamic-input/polygon-input/polygon-input.type';

const MapBox: FC<MapBoxInterface> = props => {
  const {
    markerList,
    polylinePositions,
    token,
    headerMetaData,
    allowDrawPolygon,
    componentRef,
    onMapLoad,
    inputValue,
    noControls,
    noLegends,
  } = props;

  // map container
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  // map tag
  const mapRef = useRef<mapboxgl.Map | null>(null);
  // map draw polygon feature library ref
  const mapDrawRef = useRef<MapBoxDrawRefFunctions>(null);

  const classes = useStyles();
  const locale = useLocale();

  // set componentRef value with the most updated `mapRef` and `mapDrawRef` internal refs
  if (componentRef && typeof componentRef === 'object') {
    /**
     * we only can set refs here with directly assign value to it
     * the better way is using `forwardRef` and `useImperativeHandle` but
     * because of latency in filling real refs by mapbox , we cannot use them
     */

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    componentRef.current = {
      mapRef: mapRef,
      mapDrawRef: mapDrawRef,
    };
  }

  // set map box token
  mapboxgl.accessToken = token;

  useEffect(() => {
    initializeMap();
    addFullScreenControlPlugin();
    setMapLanguage();
    addRTLSupportPlugin();
    showUserPosition();
    addMapControls();
    handleMarkers();
    handleMapScale();
  }, [markerList, noControls]);

  useEffect(() => {
    drawPolyLines();
  }, [polylinePositions, noControls]);

  useEffect(() => {
    if (!noLegends) {
      drawMapLegends();
    }

    mapRef.current?.on('load', function () {
      handleMapPolygons();

      onMapLoad?.();
    });
  }, [noControls, noLegends]);

  const initializeMap = () => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: `mapbox://styles/mapbox/streets-v11?language=${locale}&optimize=true`,
      center: [59.6067, 36.2972],
      zoom: 5,
      maxZoom: 20,
      cooperativeGestures: true,
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });
  };
  const addFullScreenControlPlugin = () => {
    if (!noControls) {
      mapRef.current.addControl(new mapboxgl.FullscreenControl());
    }
  };
  const setMapLanguage = () => {
    mapRef.current._language = locale;
  };
  const addRTLSupportPlugin = () => {
    if (mapboxgl.getRTLTextPluginStatus() === 'unavailable') {
      mapboxgl.setRTLTextPlugin(
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
        (): void => {},
        true, // Lazy load the plugin only when text is in arabic
      );
    }
  };
  const showUserPosition = () => {
    if (!noControls) {
      mapRef.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        }),
      );
    }
  };
  const addMapControls = () => {
    if (!noControls) {
      mapRef?.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
    }
  };
  const handleMarkers = () => {
    if (markerList?.[0]?.lat) {
      markerList?.forEach(function (marker) {
        const el = document.createElement('img');
        const popupContentWithBreaks = marker?.popupContent?.replace(
          /\n/g,
          '<br />',
        );

        el.addEventListener('click', function (e) {
          el.src = require(`../../../images/icon/map/${
            marker.icon ?? 'defaultMarkerMap'
          }.svg`).default;
        });

        const popup = new mapboxgl.Popup().setHTML(popupContentWithBreaks);

        el.className = 'marker';
        el.src = require(`../../../images/icon/map/${
          marker.icon ?? 'defaultMarkerMap'
        }.svg`).default;

        el.style.cursor = 'pointer';
        el.style.transition = 'width 0.2s ease-in-out';
        el.style.width = '30px';
        el.style.height = 'auto';
        el.addEventListener('mouseenter', function () {
          el.style.width = '40px';
        });

        el.addEventListener('mouseleave', function () {
          el.style.width = '30px';
        });

        new mapboxgl.Marker(el)
          .setLngLat([marker.long ?? 0, marker.lat ?? 0])
          .setPopup(popup)
          .addTo(mapRef.current);
      });
    }
  };
  const handleMapScale = () => {
    if (!noControls) {
      const scale = new mapboxgl.ScaleControl({
        maxWidth: 80,
        unit: 'imperial',
      });

      mapRef.current.addControl(scale);

      scale.setUnit('metric');
    }
  };
  const drawPolyLines = () => {
    if (polylinePositions && polylinePositions.length > 1) {
      // extract coordinates from polylinePositions
      const lineCoordinates = polylinePositions.map(position => [
        position.lng,
        position.lat,
      ]);

      // add line layers to map with map ref
      mapRef.current.on('load', function () {
        polylinePositions.forEach((position, index) => {
          const lineColor = position.lineColor || '#888'; // Default color if not specified

          mapRef.current.addLayer({
            id: `route-${index}`,
            type: 'line',
            source: {
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates:
                    index === 0
                      ? lineCoordinates
                      : [lineCoordinates[index - 1], lineCoordinates[index]],
                },
              },
            },

            paint: {
              'line-color': lineColor ?? 'blue',
              'line-width': 2,
              'line-opacity': 0.8,
            },
          });

          // Example: Add directional symbols along the line
          mapRef.current.addLayer({
            id: `directions-${index}`,
            type: 'symbol',
            source: `route-${index}`,
            layout: {
              'symbol-placement': 'line',
              'icon-image': 'mountain-15',
              'icon-rotate': 90,
              'icon-rotation-alignment': 'map',
              'icon-allow-overlap': true,
              'symbol-spacing': 1000, // Adjust spacing between symbols
              'icon-ignore-placement': true,
            },
            paint: {
              'line-color': lineColor ?? 'blue',
              'line-width': 2,
              'line-opacity': 0.8,
              'line-arrow': {
                // Add arrowheads to the line
                width: 10,
                color: lineColor ?? 'blue',
              },
            },
          });
        });
      });
    }
  };
  const drawMapLegends = () => {
    // extract map legends from meta data
    const mapLegendList = isJsonEncodedString(headerMetaData)
      ? parseJSON<MapLegendList>((headerMetaData as string) ?? '')
      : (headerMetaData as MapLegendList);

    if (!mapLegendList || !mapLegendList.MapLegend) {
      return;
    }

    // create legend container
    const legendContainer = document.createElement('div');
    legendContainer.className = 'legend-container';

    // add legends to legend container
    const length = mapLegendList?.MapLegend.length;
    for (let i = 0; i < length; i++) {
      if (
        !mapLegendList.MapLegend[i].Icon &&
        !mapLegendList.MapLegend[i].Description
      ) {
        return;
      }

      const legendItem = document.createElement('div');
      legendItem.className = 'legend-item';

      const icon = document.createElement('img');
      icon.className = 'legend-icon-mapbox';
      icon.src =
        require(`../../../images/icon/map/${mapLegendList.MapLegend[i].Icon}.svg`).default;

      const description = document.createElement('span');
      description.className = 'legend-description';
      description.innerText = mapLegendList.MapLegend[i].Description;
      legendItem.appendChild(icon);
      legendItem.appendChild(description);
      legendContainer.appendChild(legendItem);
    }

    // add legend container to map
    if (mapContainerRef.current) {
      mapContainerRef.current.appendChild(legendContainer);
    }
  };
  const handleMapPolygons = () => {
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: true,
      },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      defaultMode: inputValue ? 'simple_select' : 'draw_polygon',
    });

    /**
     * we only can set refs here with directly assign value to it
     * the better way is using `forwardRef` and `useImperativeHandle` but
     * because of latency in filling real refs by mapbox , we cannot use them
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mapDrawRef.current = draw;

    // controls
    if (!noControls && allowDrawPolygon) {
      mapRef.current.addControl(draw);
    }
  };

  return <div ref={mapContainerRef} className={classes.mapHeight}></div>;
};

export default MapBox;
