const useSorting = entries => {
  const { hasDynamic, setSort } = entries;

  //Disable remote sort when we get all data from server
  const onlyClientSort = hasDynamic;

  function changeSorting(element: Record<string, any>): void {
    let direction = element.column.sortOrder;
    if (direction == undefined || direction == 'undefined') {
      direction = 'desc';
    }
    const columnName = element.column.name;
    setSort?.(columnName, direction);
  }

  return {
    onlyClientSort,
    changeSorting,
  };
};

export default useSorting;
