import {
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import PolygonInputView from './polygon-input.view';
import { actorGetActionValue } from '../../../type/actor-setup';
import {
  exportLatLngFromGeography,
  latLngToString,
} from '../location-input/location-input.helper';
import { isJsonEncodedString } from '../../../helper/data-helper';
import { parseJSON } from '../../../core/configProvider';
import {
  ChangeFormValueParams,
  FormActions,
  OnBlurParams,
  OnFocusParams,
} from '../../form';

import type {
  MapRef,
  PolygonInputControllerPropsInterface,
  PolygonInterface,
} from './polygon-input.type';
import { emptyFunction } from '../../form/form.helper';

const PolygonInputController = (
  props: PolygonInputControllerPropsInterface,
): ReactElement => {
  const {
    value,
    getRef,
    disabled,
    label,
    hint,
    field,
    inputMessage,
    visibleClass,
    isShowMode,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [location, setLocation] = useState<PolygonInterface | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mapRef = useRef<MapRef>();
  const previewMapRef = useRef<MapRef>();

  const { formActionsHandler } = isShowMode
    ? { formActionsHandler: emptyFunction }
    : actorGetActionValue('formGlobalProps')!;

  useEffect(() => {
    setDefaultLocation();

    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [value, isOpen]);

  const toggleOpen = () => setIsOpen(!isOpen);

  /**
   * @function setDefaultLocation
   * @returns {void} void
   */
  const setDefaultLocation = useCallback(() => {
    setLocation(exportLatLngFromGeography(value));
  }, [value]);

  /**
   * @function handleCancelClick
   * @returns {void} void
   */
  const handleCancelClick = (): void => {
    setIsOpen(false);
    setDefaultLocation();
  };

  /**
   * @function confirmSelectedLocation
   * @returns {void} void
   */
  const confirmSelectedLocation = () => {
    formActionsHandler(FormActions.InputChange, {
      fieldName: field.name,
      value: JSON.stringify(mapRef?.current?.mapDrawRef?.current?.getAll?.()),
      isHidden: visibleClass.includes('displayNone'),
    } as ChangeFormValueParams);

    toggleOpen();
  };

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = (): void => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName: field.name,
      value: latLngToString(location),
    } as OnBlurParams);
  };

  /**
   * Handle focus event
   * @function handleFocus
   * @returns {void} void
   */
  const handleFocus = (): void => {
    formActionsHandler(FormActions.InputFocus, {
      fieldName: field.name,
      value: latLngToString(location),
    } as OnFocusParams);
  };

  /**
   * @function onMapLoad
   * pass parsed polygons to map to redraw it for edit mode
   */
  const onMapLoad = (): void => {
    if (isOpen && isJsonEncodedString(value)) {
      mapRef?.current?.mapDrawRef?.current?.add?.(parseJSON(value));
    }
  };

  /**
   * @function onPreviewMapLoad
   * pass parsed polygons to map to redraw it for edit mode
   */
  const onPreviewMapLoad = (): void => {
    if (!isOpen && isJsonEncodedString(value)) {
      previewMapRef?.current?.mapDrawRef?.current?.add?.(parseJSON(value));
    }
  };

  return (
    <PolygonInputView
      toggleOpen={toggleOpen}
      handleCancelClick={handleCancelClick}
      isOpen={isOpen}
      handleOkClick={confirmSelectedLocation}
      field={field}
      label={label}
      visibleClass={visibleClass}
      getRef={getRef}
      inputMessage={inputMessage}
      hint={hint}
      disabled={disabled}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      mapRef={mapRef as RefObject<MapRef>}
      onMapLoad={onMapLoad}
      inputValue={value}
      previewMapRef={previewMapRef as RefObject<MapRef>}
      onPreviewMapLoad={onPreviewMapLoad}
      isLoading={isLoading}
      isShowMode={isShowMode}
    />
  );
};

export default PolygonInputController;
