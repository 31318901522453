import {
  ReactElement,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useTranslate, useRefresh, crudDeleteMany } from 'react-admin';
import { useDispatch } from 'react-redux';

import { getMetaData } from '../../../api/wms-api';
import { getPrintResource } from '../../../helper/PrintMetaHelper';
import { actorDispatch, actorGetActionValue } from '../../../type/actor-setup';
import { FormActions, FormActionsHandler } from '../../form';
import WMSButtonToolbarView from './wms-button-toolbar.view';

import useKeyPress from '../../../hooks/useKeyPress';
import { openPrintProcess } from '../../../helper/print-helper';
import { isEmptyObject } from '../../../helper/data-helper';

import type { WMSButtonToolbarControllerProps } from './wms-button-toolbar.type';
import type { ActionPrintInterface } from '../../../helper/Types';
import { showNotification } from '../../../helper/general-function-helper';

let formActionsHandler: FormActionsHandler;
const WMSButtonToolbarController = (
  props: WMSButtonToolbarControllerProps,
): ReactElement => {
  const { wmsMetaData, selectedTabIndex } = props;
  const { saveActionId, validationActionId, tabs } = wmsMetaData;

  const [isLoading, setIsLoading] = useState(false);

  const reduxDispatch = useDispatch();
  const translate = useTranslate();
  const refreshView = useRefresh();

  const saveMainButtonRef = useRef<HTMLButtonElement>(null);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);

  const isDeleteAvailable = useMemo(() => {
    const tabs = wmsMetaData.tabs[selectedTabIndex ?? 0];

    let moduleName: string | undefined;
    let tableName: string | undefined;
    if (tabs?.item?.table?.config) {
      moduleName = tabs.item.table.config['moduleName'];
      tableName = tabs.item.table.config['moduleTableName'];
    }

    return !!(tableName && moduleName);
  }, [selectedTabIndex]);

  useEffect(() => {
    formActionsHandler = actorGetActionValue('formGlobalProps')!.formActionsHandler;
  }, []);

  useEffect(() => {
    checkForMeta();
  }, [selectedTabIndex]);

  /**
   * It gets `metaData` for received `print` data and registers that to `redux`
   * @function checkForMeta
   * @returns { Promise<void> }
   */
  const checkForMeta = async (): Promise<void> => {
    const print = tabs[selectedTabIndex ?? 0]?.item.print ?? null;

    const printResource = getPrintResource(print?.id);
    if (printResource) {
      await getMetaData(printResource);

      reduxDispatch({
        type: 'RA/REGISTER_RESOURCE',
        payload: {
          name: printResource,
          options: {},
        },
      });
    }
  };

  /**
   * It shows a `dialog` to get the user confirmation and then runs `formActionsHandler` in the main controller to save the data
   * @function onSaveClick
   * @returns { void }
   */
  const onSaveClick = (): void => {
    setIsLoading(true);
    actorDispatch(
      'quickDialog',
      {
        confirmationIsOpen: true,
        data: {
          enableOnlyOkButton: false,
          content: translate('ra.message.are_you_sure'),
          onConfirm,
          onCancel,
          onClose: onCancel,
        },
      },
      { replaceAll: true },
    );
  };

  /**
   * handle confirm callback dialog
   * @function onConfirm
   * @param {function} resolve
   * @return {void}
   */
  const onConfirm = useCallback(
    (confirmationCallback?: () => void): void => {
      formActionsHandler(FormActions.Save, {
        validationActionId,
        actionUniqueId: saveActionId,
        onSuccess: async (response: Record<string, unknown>) => {
          confirmationCallback?.();

          const data = response.data as Record<string, unknown>;
          const printData: Partial<ActionPrintInterface> = {};
          if (data.report && data.printUniqueId) {
            printData.printUniqueId = data.printUniqueId as string;
            printData.report = data.report as string;
            printData.withPreview = (data.withPreview ?? false) as boolean;
          }

          // prettier-ignore
          if (wmsMetaData.tabs[selectedTabIndex!].item.actionExecutionConfirm === false) {
            showNotification(response.userMessage, 'info');
            printProcess(printData as ActionPrintInterface);
          } else {
            actorDispatch(
              'quickDialog',
              {
                confirmationIsOpen: true,
                data: {
                  content: response.userMessage,
                  enableOnlyOkButton: true,
                  icon: 'fa fa-info-circle',
                  onConfirm: () => {
                    confirmationCallback?.();
                    printProcess(printData as ActionPrintInterface);
                  },
                },
              },
              { replaceAll: true },
            );
          }
        },
        onFailure: () => {
          setIsLoading(false);
          confirmationCallback?.();
        },
      });
    },
    [selectedTabIndex],
  );

  /**
   * disable loading on close dialog
   * @function onCancel
   * @return {void}
   */
  const onCancel = useCallback((): void => {
    setIsLoading(false);
  }, []);

  /**
   * Based on `report` and `print` data shows the `print` with page to the user
   * @function printProcess
   * @param { object } reportData
   * @returns { Promise<void> }
   */
  const printProcess = async (
    reportData: ActionPrintInterface | null | undefined,
  ): Promise<void> => {
    setIsLoading(false);

    requestAnimationFrame(() => {
      actorDispatch('resetForm', {
        saveType: 'wmsSaveAfterServiceHasBeenRan',
      });
      refreshView();
    });

    // FIXME: Check filters
    if (!isEmptyObject(reportData)) {
      openPrintProcess(reportData!, reportData.withPreview);
    } else {
      console.warn('`printProcess`: invalid report data after running a service ', {
        reportData,
      });
    }
  };

  /**
   * It shows a `dialog` to get the user confirmation and then sends some data to the server to delete
   * @function onDeleteClick
   * @returns { void }
   */
  const onDeleteClick = (): void => {
    if (!isDeleteAvailable) {
      console.warn('delete is not available!');
      return;
    }

    const tabs = wmsMetaData.tabs[selectedTabIndex ?? 0];

    const moduleName = tabs.item.table?.config?.['moduleName'];
    const tableName = tabs.item.table?.config?.['moduleTableName'];
    const resource = `${moduleName}/${tableName}`;

    let selectedIdsForDelete =
      (actorGetActionValue('gridIDs', resource)
        ?.selectedIDs as unknown as number[]) ?? [];

    let deleteMessage;
    if (selectedIdsForDelete.length > 0) {
      deleteMessage = 'confirm.areYouSureYouWantToDeleteThisCountOfItem';
    }

    // delete the whole data
    else {
      deleteMessage = 'confirm.areYouSureYouWantToDeleteWholeTable';
      selectedIdsForDelete =
        (actorGetActionValue('gridIDs', resource)?.allIDs as unknown as number[]) ??
        [];
    }

    actorDispatch('quickDialog', {
      confirmationIsOpen: true,
      data: {
        content: translate(deleteMessage, {
          smart_count: selectedIdsForDelete.length,
        }),
        onConfirm: (confirmationCallback?: () => void) => {
          confirmationCallback?.();

          if (selectedIdsForDelete.length > 0) {
            reduxDispatch(crudDeleteMany(resource, selectedIdsForDelete));
          }
          // FIXME: Set `loading` to `false` in the action's callback
          setIsLoading(false);
        },
        onCancel: () => {
          setIsLoading(false);
        },
      },
    });
  };

  /**
   * @function handleKeyPressed
   * @returns { void } void
   */
  const handleKeyPressed = (): void => {
    saveMainButtonRef.current?.focus();
    saveMainButtonRef.current?.click();
  };

  useKeyPress(['s'], handleKeyPressed, true);

  /**
   * @function onShortcutDeleteClick
   * @returns { void } void
   */
  const onShortcutDeleteClick = (): void => {
    deleteButtonRef.current?.focus();
    deleteButtonRef.current?.click();
  };

  useKeyPress(['d'], onShortcutDeleteClick, true);

  return (
    <WMSButtonToolbarView
      onDeleteClick={onDeleteClick}
      onSaveClick={onSaveClick}
      isDeleteAvailable={isDeleteAvailable}
      saveMainButtonRef={saveMainButtonRef}
      deleteButtonRef={deleteButtonRef}
      saveActionId={saveActionId}
      isLoading={isLoading}
    />
  );
};

export default WMSButtonToolbarController;
