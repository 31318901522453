import { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useTranslate } from 'react-admin';
import lodashDebounce from 'lodash/debounce';

import {
  CONFIG_LAST_CHOSEN_COLUMNS_BEFORE_SAVING_A_CUSTOM_FILTER,
  CONFIG_LIST_COLUMN_CHOICE,
  CONFIG_LIST_LAST_FILTER,
} from '../../core/configProvider';
import {
  clone,
  isEmpty,
  isEmptyAndNotEqualToNull,
  isEmptyObject,
} from '../../helper/data-helper';
import { getAppSettings, setAppSettings } from '../../helper/settings-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  GetListRequestParametersInterface,
  actorRemoveAction,
  FilterFormFieldInterface,
} from '../../type/actor-setup';
import { FilterFormView } from './filter-form.view';
import { showNotification } from '../../helper/general-function-helper';
import {
  emptyAllFilterInputsValues,
  emptyFilterInputValue,
  FilterValueStructureEnum,
  generateFilterKeyForAppSetting,
  generateFilterValueForAppSetting,
  getFinalSanitizedFilters,
  getFormattedDateFilterData,
} from './filter-form.helper';

import type {
  FilterFormPropsBaseInterface,
  FilterItemBaseType,
  FinalFiltersType,
} from './filter-form.type';
import type { FieldType } from '../../helper/Types';
import { findRowStateColorField } from '../../helper/RowColorHelper';

/**
 * @TODO: @honarvar Refactor the following component by using `object` instead of `array` of `object`s to improve performance and cleaning up codes
 */

const FilterFormController: FC<FilterFormPropsBaseInterface> = props => {
  const {
    selectedFilterFields,
    hasError,
    resource,
    customSaveButtonText,
    saveSettings,
    defaultDisabledSubmitButton,
    checkFiltersIsChangedBeforeSubmitting,
    className,
    onFiltersChange,
  } = props;

  const translate = useTranslate();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(
    defaultDisabledSubmitButton ?? false,
  );
  const [filterFormFields, setFilterFormFields] = useState<
    FilterFormFieldInterface[]
  >([]);

  const filterDataRef = useRef<Record<string, FilterFormFieldInterface>>({});
  const onDispatchData = useRef<(keyof ActorActionList)[]>([]);

  /**
   * this ref created to be able to access this variable all over use effects
   * for example in calender, `selectedFilterFields` is not a state and its just a memoized variable
   * so useEffect cannot understand its changes, with this trick, we are sure that the value is always stay update
   */
  const selectedFilterFieldsAccessRef = useRef<
    FilterFormFieldInterface[] | undefined
  >();
  selectedFilterFieldsAccessRef.current = clone(selectedFilterFields);

  useEffect(() => {
    return cleanUpComponent;
  }, []);

  useEffect(() => {
    if (selectedFilterFields == null) {
      setFilterFormFields([]);
      return;
    }

    for (const filter of selectedFilterFields) {
      const filterKey = filter.fieldData.key ?? filter.fieldData.name;
      filter.value[FilterValueStructureEnum.VALUE] = '';
      filterDataRef.current[filterKey] = {
        ...filter,
      };
    }
    setFilterFormFields(selectedFilterFieldsAccessRef.current ?? []);
  }, [selectedFilterFields]);
  /**
   * @function handleKeyDown
   * @param {globalThis.KeyboardEvent} event
   * @return {void}
   */
  const handleKeyDown = (event: globalThis.KeyboardEvent): void => {
    if (event.ctrlKey && event.key === 'Enter') {
      formSubmitHandler();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    setFilterFormFields(selectedFilterFields ?? []);

    actorOnDispatch(
      'filterFormFields',
      _filterFormFields => {
        if (resource in _filterFormFields === false) return;
        updateFormFields(_filterFormFields[resource]);
      },
      {
        preserve: false,
      },
    );

    onDispatchData.current.push('filterFormFields');

    actorOnDispatch(
      'gridData',
      gridData => {
        const currentFilters = gridData[resource]?.requestParameters?.filter;
        if (!(Array.isArray(currentFilters) && currentFilters.length > 0)) return;

        setSubmitButtonDisabled(false);
      },
      {
        preserve: false,
        callerScopeName: 'FilterFormController => useEffect(..., [resource])',
      },
    );

    onDispatchData.current.push('gridData');

    actorOnDispatch('resetOriginalFieldsToFilter', clearAllFilters, {
      preserve: false,
      callerScopeName: 'FilterFormController => useEffect(..., [resource])',
    });

    onDispatchData.current.push('resetOriginalFieldsToFilter');

    actorOnDispatch('clearAllFilterValues', clearAllFilterValues, {
      preserve: false,
      callerScopeName: 'FilterFormController => useEffect(..., [resource])',
    });

    onDispatchData.current.push('clearAllFilterValues');

    return cleanUpComponent;
  }, [resource]);

  /**
   * change data type for handle color field with SelectFilterInput and calculate values
   * @function changeColorFieldData
   * @param {FilterFormFieldInterface[]} filterFields
   * @return {FilterFormFieldInterface[]}
   */
  const changeColorFieldData = useCallback(
    (filterFields: FilterFormFieldInterface[]) => {
      return filterFields.map(field => {
        const rowStateColorField = findRowStateColorField([field.fieldData]);
        if (rowStateColorField) {
          return {
            ...field,
            fieldData: {
              ...rowStateColorField,
              dataType: {
                erp: 'colorSelectField',
                simple: 'number',
                defaultOperator: 'Equals',
              },
            },
            value: field?.value ?? [],
          };
        }
        return field;
      });
    },
    [],
  );

  /**
   * @function filterInputChangeHandler
   * @param { FieldType } field
   * @param { string } inputValue
   * @param { string } operator
   * @returns { void } void
   */
  const filterInputChangeHandler = useCallback(
    (field: FieldType, inputValue: unknown, operator = 'equals'): void => {
      const filterKey = field.key ?? field.name;

      const filterData: FilterFormFieldInterface = {
        ...filterDataRef.current[filterKey],
      };

      // prettier-ignore
      filterData.value[FilterValueStructureEnum.OPERATOR] = operator || field.dataType.defaultOperator.toLowerCase();

      /**
       * Don't use `getTypeByField` because it checks a lot cases,
       * That not necessary here (i.e. by every changing we shouldn't to check those cases)
       */
      // prettier-ignore
      const isDropdownField = field.dataType?.erp === 'dropdown' && !isEmptyObject(field.dropdown) && !field.dropdown.filterMultiSelect;
      // prettier-ignore
      if (isDropdownField && typeof inputValue === 'object' && !Array.isArray(inputValue)) {
        /**
         * PAY ATTENTION: In this situation we have a selected `dropdown` option
         */
        const _inputValue = (inputValue ?? {}) as Record<string, unknown>;

        let filterValue = '';
        if (isEmpty(field.dropdown.filterValueMember)) {
          console.warn(
            'FilterFormController => filterInputChangeHandler: Invalid `filterValueMember` in meta data',
          );
        } else {
          filterValue = _inputValue[field.dropdown.filterValueMember!] as string;
        }

        // prettier-ignore
        filterData.value[FilterValueStructureEnum.VALUE] = filterValue;
        filterData.fullDropdownItem = _inputValue;
      }

      if (!isDropdownField) {
        filterData.value[FilterValueStructureEnum.VALUE] = inputValue ?? '';
      }

      if (isEmptyObject(filterData.fieldData)) {
        filterData.fieldData = field;
      }

      filterDataRef.current[filterKey] = { ...filterData };
      changeSubmitButtonDisableStatus(filterDataRef.current[filterKey].fieldData);
    },
    [],
  );

  /**
   * @function changeSubmitButtonDisableStatus
   * @param { Record<string, unknown> } fieldData
   * @returns { void } void
   */
  const changeSubmitButtonDisableStatus = useCallback(
    lodashDebounce((fieldData: Record<string, unknown>) => {
      let isSubmitButtonDisabled = checkFiltersIsChangedBeforeSubmitting ?? false;
      const filterDataKeys = Object.keys(filterDataRef.current);
      const filterDatKeysLength = filterDataKeys.length;
      const currentFilterDataInActor =
        (actorGetActionValue(
          'gridData',
          `${resource}.requestParameters.filter`,
        ) as unknown as GetListRequestParametersInterface['filter']) ?? [];

      for (let index = 0; index < filterDatKeysLength; index++) {
        const targetFilterInActor = currentFilterDataInActor.find(
          item =>
            Array.isArray(item) &&
            (item[0] === fieldData.key || item[0] === fieldData.name),
        );

        const currentFilterValue =
          filterDataRef.current[filterDataKeys[index]].value[
            FilterValueStructureEnum.VALUE
          ];
        const currentOperator =
          filterDataRef.current[filterDataKeys[index]].value[
            FilterValueStructureEnum.OPERATOR
          ];

        const isRequired =
          filterDataRef.current[filterDataKeys[index]].fieldData.required;

        const isEmptyCurrentValue = isEmptyAndNotEqualToNull(currentFilterValue); // We want to use `null` for `noValue` operator
        if (isEmptyCurrentValue) {
          if (isRequired) {
            isSubmitButtonDisabled = true;
            break;
          } else {
            isSubmitButtonDisabled = false;
          }
        } else {
          isSubmitButtonDisabled = false;
        }

        if (targetFilterInActor) {
          const [, operator, value] = targetFilterInActor;
          if (currentFilterValue !== value || currentOperator !== operator) {
            isSubmitButtonDisabled = false;
          }
        }

        actorSetActionValue('filterFormFields', filterDataRef.current, {
          path: resource,
          replaceAll: true,
          callerScopeName: 'FilterFormController => changeSubmitButtonDisableStatus',
        });
      }

      // prettier-ignore
      setSubmitButtonDisabled(isSubmitButtonDisabled);
    }, 100),
    [resource],
  );

  /**
   * @function hideFilterFieldHandler
   * @param { FieldType } fieldToHide
   * @returns { void } void
   */
  const hideFilterFieldHandler = useCallback((fieldToHide: FieldType): void => {
    const filterKey = fieldToHide.key ?? fieldToHide.name;
    if (filterKey in filterDataRef.current) {
      delete filterDataRef.current[filterKey];
    }

    if (isEmptyObject(filterDataRef.current)) {
      updateGridFilters(getFinalFilters());

      if (saveSettings) {
        setAppSettings({
          key: generateFilterKeyForAppSetting(resource, CONFIG_LIST_LAST_FILTER),
          value: {},
          forUser: true,
          onFailure: () => {
            showNotification(translate('ra.updatingSettingsFailed'), 'error');
          },
        });
      }
    }

    actorSetActionValue('filterFormFields', filterDataRef.current, {
      replaceAll: true,
      path: resource,
      callerScopeName: 'FilterFormController => formSubmitHandler',
    });

    setFilterFormFields([...Object.values(filterDataRef.current)]);

    actorDispatch(
      'filterFormFieldHiddenChanged',
      { [resource]: fieldToHide },
      {
        replaceAll: true,
        callerScopeName: 'FilterFormController => hideFilterFieldHandler',
      },
    );
  }, []);

  /**
   * @function formSubmitHandler
   * @returns { void } void
   */
  const formSubmitHandler = useCallback((): void => {
    setSubmitButtonDisabled(checkFiltersIsChangedBeforeSubmitting ?? false);

    if (saveSettings) {
      setAppSettings({
        key: generateFilterKeyForAppSetting(resource, CONFIG_LIST_LAST_FILTER),
        value: generateFilterValueForAppSetting(filterDataRef.current),
        forUser: true,
        onFailure: (): void => {
          showNotification(translate('ra.updatingSettingsFailed'), 'error');
        },
      });
    }

    actorSetActionValue('filterFormFields', filterDataRef.current, {
      replaceAll: true,
      path: resource,
      callerScopeName: 'FilterFormController => formSubmitHandler',
    });

    const finalFilters = getFinalFilters();
    updateGridFilters(finalFilters);
  }, [saveSettings, checkFiltersIsChangedBeforeSubmitting, resource]);

  /**
   * @function updateGridFilters
   * @param { FinalFiltersType } finalFilters
   * @returns { void } void
   */
  const updateGridFilters = useCallback(
    (finalFilters: FinalFiltersType): void => {
      actorDispatch(
        'filterDataIsChanged',
        { [resource]: finalFilters },
        {
          replaceAll: true,
          callerScopeName: 'FilterFormController => updateGridFilters',
        },
      );
      onFiltersChange?.(finalFilters);
    },
    [resource],
  );

  /**
   * @function getFinalFilters
   * @returns { FinalFiltersType } an array of strings
   */
  const getFinalFilters = useCallback((): FinalFiltersType => {
    // prettier-ignore
    const finalFilters: ((string | FilterItemBaseType)[] | FilterItemBaseType)[] = [];
    const filterDataKeys = Object.keys(filterDataRef.current);

    for (const filterKey of filterDataKeys) {
      let fieldDataType = '';
      if ('dataType' in filterDataRef.current[filterKey].fieldData) {
        fieldDataType = (filterDataRef.current[filterKey].fieldData as FieldType)
          .dataType.simple;
      }

      if (fieldDataType === 'date' || fieldDataType === 'datetime') {
        finalFilters.push(
          getFormattedDateFilterData(filterKey, filterDataRef.current),
        );
        continue;
      }
      finalFilters.push(filterDataRef.current[filterKey].value);
    }
    return getFinalSanitizedFilters(finalFilters);
  }, []);

  /**
   * @function updateFormFields
   * @param {Record<string, FilterFormFieldInterface> | null} newFilterFormFields
   * @returns {void} void
   */
  const updateFormFields = useCallback(
    (newFilterFormFields: Record<string, FilterFormFieldInterface> | null): void => {
      if (isEmptyObject(newFilterFormFields)) {
        /**
         * FIXME:
         * We want a way to combine current filter fields with new filter fields,
         *  without any duplication and to be updated every key
         */
        filterDataRef.current = {};
        setFilterFormFields([]);
        return;
      }

      for (const key of Object.keys(newFilterFormFields!)) {
        const field = newFilterFormFields![key];
        const filterKey = field.fieldData.key ?? field.fieldData.name;
        filterDataRef.current[filterKey] = { ...field };
      }

      setFilterFormFields([...Object.values(filterDataRef.current)]);
    },
    [],
  );

  const clearAllFilters = useCallback((): void => {
    const currentFilterFormFieldsKeys = Object.keys(filterDataRef.current);
    for (const filterKey of currentFilterFormFieldsKeys) {
      emptyFilterInputValue(filterKey, resource);

      if (!filterDataRef.current[filterKey].fieldData.required) {
        delete filterDataRef.current[filterKey];
        continue;
      }

      /**
       * Change by reference to set new value in `DynamicFilterInput`
       */
      // prettier-ignore
      filterDataRef.current[filterKey].value = [...filterDataRef.current[filterKey].value];
      filterDataRef.current[filterKey].value[FilterValueStructureEnum.VALUE] = '';

      // For more readable only
      const filterOperator =
        filterDataRef.current[filterKey].value[FilterValueStructureEnum.OPERATOR];
      if (filterOperator === 'noValue') {
        // Set the operator to empty then `DynamicFilterInput` will decide to set the correct operator based on defaults of the field
        // prettier-ignore
        filterDataRef.current[filterKey].value[FilterValueStructureEnum.OPERATOR] = '';
      }
    }

    if (saveSettings) {
      const lastChosenColumnListBeforeSavingACustomFilter = getAppSettings<number[]>(
        CONFIG_LAST_CHOSEN_COLUMNS_BEFORE_SAVING_A_CUSTOM_FILTER + '_' + resource,
        true,
      ).value;

      setAppSettings({
        key: CONFIG_LIST_COLUMN_CHOICE + '_' + resource,
        value: lastChosenColumnListBeforeSavingACustomFilter,
        forUser: true,
        onSuccess: (): void => {
          actorDispatch('refreshView', resource);
        },
      });
    }

    setFilterFormFields([...Object.values(filterDataRef.current)]);
    formSubmitHandler();
  }, [resource]);

  /**
   * @function clearAllFilterValues
   * @returns { void } void
   */
  const clearAllFilterValues = useCallback((): void => {
    const filterFormKeys = Object.keys(filterDataRef.current);
    for (const key of filterFormKeys) {
      filterDataRef.current[key].value[FilterValueStructureEnum.VALUE] = '';
    }

    emptyAllFilterInputsValues(resource);

    if (saveSettings) {
      setAppSettings({
        key: generateFilterKeyForAppSetting(resource, CONFIG_LIST_LAST_FILTER),
        value: generateFilterValueForAppSetting(filterDataRef.current),
        forUser: true,
        onFailure: (): void => {
          showNotification(translate('ra.updatingSettingsFailed'), 'error');
        },
      });
    }

    setFilterFormFields(Object.values(filterDataRef.current));
  }, [resource]);

  /**
   * @function cleanUpComponent
   * @returns {void} void
   */
  const cleanUpComponent = useCallback(() => {
    filterDataRef.current = {};

    for (const item of onDispatchData.current) {
      actorRemoveAction({
        actionName: item,
        prune: true, // We have to remove the action and all its listeners
      });
    }
  }, []);

  if (filterFormFields.length === 0) {
    return null;
  }

  const _filterFormFields = changeColorFieldData(filterFormFields);

  return (
    <FilterFormView
      className={className}
      hasError={hasError}
      resource={resource}
      selectedFilterFields={_filterFormFields}
      hideFilterFieldHandler={hideFilterFieldHandler}
      filterInputChangeHandler={filterInputChangeHandler}
      submitButtonDisabled={submitButtonDisabled}
      submitHandler={formSubmitHandler}
      filterDataRecord={filterDataRef.current}
      customSaveButtonText={customSaveButtonText}
    />
  );
};

export default FilterFormController;
