import { actorDispatch } from '../../../type/actor-setup';

const useSelection = entries => {
  const { resource, onSelectCheckbox } = entries;

  const changeCheckboxSelection = (checkBoxSelectedInfo): void => {
    const { selectedRowKeys, currentDeselectedRowKeys } = checkBoxSelectedInfo;

    actorDispatch('gridIDs', selectedRowKeys, {
      path: `${resource}.selectedIDs`,
    });

    if (onSelectCheckbox && typeof onSelectCheckbox === 'function') {
      onSelectCheckbox(selectedRowKeys, currentDeselectedRowKeys);
    }
  };

  return {
    changeCheckboxSelection,
  };
};

export default useSelection;
