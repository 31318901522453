import { useEffect } from 'react';
import { actorOnDispatch } from '../../../type/actor-setup';

const useColumnChooser = entries => {
  const { resource, gridRef } = entries;

  useEffect(() => {
    actorOnDispatch('showColumnChooser', detail => {
      if (resource == Object.keys(detail)[0]) {
        gridRef?.current?.instance.showColumnChooser();
      }
    });
  }, []);
};

export default useColumnChooser;
