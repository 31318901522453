import { FC, RefObject, memo, useEffect, useMemo, useState } from 'react';
import lodashMap from 'lodash/map';
import lodashMapValues from 'lodash/mapValues';
import lodashMethod from 'lodash/method';
import { icon } from 'leaflet';
import lodashFind from 'lodash/find';

import LoadingBox from '../../LoadingBox';
import { clone, isEmptyObject } from '../../../helper/data-helper';
import { actorOnDispatch, actorRemoveAction } from '../../../type/actor-setup';
import { useStyles } from '../leaflet-map/leaflet-map.style';
import type { DynamicMapControllerPropsInterface } from './dynamic-map.type';

// leaflet
import L from 'leaflet';
import { LeafletMap } from '../leaflet-map';
import type { LatLngExpression, MapConfig } from '../map.type';
import type {
  LeafletMapDataInterface,
  LeafletMarkerInterface,
  PolylineInterface,
} from '../leaflet-map/leaflet-map.type';

// map box
import { MapBox } from '../map-box';
import { MAP_BOX_ACCESS_TOKEN, getValue } from '../../../core/configProvider';
import { MapRef } from '../../dynamic-input/polygon-input/polygon-input.type';

export const tehranLatLong: LatLngExpression = [35.6892, 51.389];

const DynamicMapController: FC<DynamicMapControllerPropsInterface> = props => {
  const {
    metaData,
    data,
    mapOptions,
    hideGrid,
    clusterMode = false,
    allowDrawPolygon,
    mapRef,
    onMapLoad,
    inputValue,
    noControls,
    noLegends,
  } = props;

  const [mapKey, setMapKey] = useState(0);

  const classes = useStyles({
    isFullScreen: !metaData?.mapConfig?.showInGrid || !!hideGrid,
  });

  const [preparedMapData, setPreparedMapData] = useState<LeafletMapDataInterface>({
    markerList: [],
    polyLines: [],
  });

  useEffect(() => {
    const id = actorOnDispatch('signal', signalKey => {
      if (signalKey === 'reloadMap') {
        setMapKey(prev => prev + 1);
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'signal',
        listenerId: id,
      });
    };
  }, []);

  useEffect(() => {
    prepareDataForMap(data);
  }, [data]);

  useEffect(() => {
    setMapKey(prev => prev + 1);
  }, [clusterMode]);

  const mapBoxAccessToken = useMemo(() => {
    return getValue(MAP_BOX_ACCESS_TOKEN);
  }, []);

  /**
   * @function mapConfigColumns
   * @returns { mapConfig }
   */
  const mapConfigColumns = (): MapConfig => {
    return lodashMapValues(metaData?.mapConfig, lodashMethod('toLowerCase'));
  };

  /**
   * @function prepareDataForMap
   * @param {Record<string, unknown>[]} data
   * @returns { void }
   */
  const prepareDataForMap = (data?: Record<string, unknown>[]): void => {
    let processedData: LeafletMapDataInterface = {
      markerList: [],
      polyLines: [],
    };
    if (!isEmptyObject(data) && metaData?.mapConfig) {
      const { latitude, longitude, markerIcon, markerToolTip } = mapConfigColumns();

      const prepareMarkers: LeafletMarkerInterface[] = [];
      const preparePolyLines: PolylineInterface[] = [];

      lodashMap(data, (row: Record<string, number | string>) => {
        if (row?.[latitude] && row?.[longitude]) {
          if (!row?.islinedisable) {
            preparePolyLines.push({
              lat: row[latitude],
              lng: row[longitude],
              lineColor: row?.nextlinecolor,
            });
          }
          prepareMarkers.push({
            lat: row[latitude] as number,
            long: row[longitude] as number,
            icon: row[markerIcon] as string,
            popupContent: row[markerToolTip] as string,
            markerTooltip: row?.markertooltip as number,
          });
        }
      });

      processedData = {
        markerList: prepareMarkers,
        polyLines: preparePolyLines,
      };
    }

    setPreparedMapData(processedData);
  };

  /**
   * @function getMapCenter
   * @returns { void }
   */
  const getMapCenter = (): void => {
    let center = clone(tehranLatLong);
    if (mapOptions.current.center) {
      center = mapOptions.current.center;
    } else if (preparedMapData.polyLines?.length > 0) {
      center = preparedMapData.polyLines[0];
    }
    return center;
  };

  /**
   * @function getMarkerIcon
   * @param iconName {string}
   * @returns { unknown }
   */
  const getMarkerIcon = (
    iconName: string,
    markerTooltip: number | undefined,
    lat: string,
    long: string,
  ): unknown => {
    const iconList = [
      'defaultMarkerMap',
      'download',
      'mapIconGreen',
      'mapIconYellow',
      'mapIconRed',
      'mapIcongrey',
      'mapIconBlue',
      'end',
      'start',
      'start1',
      'start2',
      'phone',
      'order',
      'notVisit',
    ];

    //when click the grid this ref will to fill
    const center = mapOptions.current.center;

    let fileName = 'defaultMarkerMap';
    if (lodashFind(iconList, item => item === iconName)) {
      fileName = iconName;
    }
    const isCenterClicked = center?.lat == lat && center?.lng == long;

    if (markerTooltip) {
      return L.divIcon({
        className: classes.customDivIcon,
        html: `<div class=${
          isCenterClicked ? classes.markerPinSelect : classes.markerPin
        }><div class=${classes.iconNumber}>${
          markerTooltip ? markerTooltip : ''
        }</div></div>`,

        iconSize: [12, 12],
        iconAnchor: [0, 0],
      });
    }

    return icon({
      iconUrl: require(`../../../images/icon/map/${fileName}.svg`).default,
      iconSize: isCenterClicked ? [24, 24] : [16, 16],
      iconAnchor: [12, 12],
    });
  };

  if (!metaData) {
    return <LoadingBox />;
  }

  if (mapBoxAccessToken) {
    return (
      <MapBox
        markerList={preparedMapData.markerList}
        polylinePositions={preparedMapData.polyLines}
        token={mapBoxAccessToken}
        headerMetaData={metaData.header ?? ''}
        allowDrawPolygon={!!allowDrawPolygon}
        componentRef={mapRef as RefObject<MapRef>}
        onMapLoad={onMapLoad}
        inputValue={inputValue}
        noControls={noControls}
        noLegends={noLegends}
      />
    );
  }

  return (
    <LeafletMap
      mapCenter={getMapCenter()}
      getMarkerIcon={getMarkerIcon}
      polylinePositions={preparedMapData.polyLines}
      markerList={preparedMapData.markerList}
      zoom={mapOptions.current.zoom}
      isFullScreen={!metaData.mapConfig?.showInGrid || !!hideGrid}
      hasTracing={metaData.mapConfig?.tracing}
      headerMetaData={metaData.header ?? ''}
      clusterMode={clusterMode}
      mapKey={mapKey}
    />
  );
};

export default memo(DynamicMapController);
