import { useCallback, useMemo } from 'react';
import { useDispatch, useStore } from 'react-redux';
import {
  refreshView,
  setListSelectedIds,
  useLocale,
  useTranslate,
} from 'react-admin';
import lodashGet from 'lodash/get';
import lodashMerge from 'lodash/merge';

import {
  ApiResponseWithValidationErrors,
  Action,
  FormActionProps,
  CreateEditFullFormSaveParams,
  ValidateInputParams,
  ServiceDialogFormParams,
  ProfileFormParams,
  RelationEditDialogFormSaveParams,
  GridFormParamsInterface,
} from '../form.type';
import { push as redirectToPage } from 'connected-react-router';

import {
  clone,
  isEmpty,
  isEmptyObject,
  objectToLowerCaseProperties,
} from '../../../helper/data-helper';
import { FieldType } from '../../../helper/Types';
import {
  actorDispatch,
  actorGetActionValue,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  GridDataInterface,
  RecordKeyMode,
} from '../../../type/actor-setup';
import {
  crudCreateWithCallbackAction as dispatchCrudCreate,
  crudUpdateWithCallbackAction as dispatchCrudUpdate,
} from '../../../redux/crud/action';
import { checkValidationErrorsInApiResponse } from '../form.helper';
import {
  QuickEditDialogFormSaveParams,
  DropdownQuickCreateFormSaveParams,
} from '../form.type';
import {
  getResponseMessage,
  INLINE_CELL_UPDATE,
  RUN_SERVICE,
} from '../../../core/data-Provider.helper';
import { prepareGlobalParameters } from '../profile-form/profile-form.helper';
import {
  API_NAME,
  CONFIG_CACHED_MENU,
  getValue,
  removeValue,
  SERVICE_WORKER_CACHE_VERSION,
  setValue,
  USER_WAREHOUSE_ID,
  USER_WAREHOUSE_TITLE,
} from '../../../core/configProvider';
import { getSessionIdInUrl, isEmbeddedPage } from '../../../helper/UrlHelper';
import { ChangePasswordParams } from '..';
import {
  deviceCallbackFunction,
  showNotification,
} from '../../../helper/general-function-helper';
import lodashMap from 'lodash/map';
import lodashFilter from 'lodash/filter';
import { getGridData } from './grid-data.helper';
import dataProvider from '../../../core/dataProvider';
import { string } from 'prop-types';

export function useFormSave(
  type: keyof FormActionProps,
): (
  data: Record<string, unknown>,
  props: FormActionProps[typeof type],
) => Promise<void> {
  let handleAction;
  const reduxDispatch = useDispatch();
  const reduxStore = useStore();
  const reduxState = useMemo(() => reduxStore.getState(), [reduxStore]);
  const locale = useLocale();
  const translate = useTranslate();

  const prepareUrl = (newRecordId: number) => {
    const sessionIdInUrl = getSessionIdInUrl();
    const isEmbedPage = isEmbeddedPage();

    const currentResource = actorGetActionValue('resources')!.current;

    let url = `/${currentResource.value}/${newRecordId}/show?`;

    if (sessionIdInUrl) {
      url = `${url}&sessionid=${sessionIdInUrl}`;
    }

    if (isEmbedPage) {
      url = `${url}&embed=true`;
    }

    return url;
  };
  /**
   * Save dropdown quick form.
   * @function dropdownQuickCreateSave
   * @param {Record<string, unknown>} data
   * @param {DropdownQuickCreateFormSaveParams} params
   * @returns {void}
   */
  const dropdownQuickCreateSave = (
    data: Record<string, unknown>,
    params: DropdownQuickCreateFormSaveParams,
  ): void => {
    const currentResource = actorGetActionValue('resources')!.current;
    actorDispatch('loading', { [currentResource.value]: true });
    reduxDispatch(
      dispatchCrudCreate(
        currentResource.value,
        data,
        action => dropdownQuickCreateAfterSave(action, params),
        false, // disable notification
        true, // execute callback on failure
        {},
      ),
    );
  };

  /**
   * Handle after dropdown quick save.
   * @function dropdownQuickCreateAfterSave
   * @param {Action} action
   * @param {DropdownQuickCreateFormSaveParams} params
   * @returns {void}
   */
  const dropdownQuickCreateAfterSave = (
    action: Action,
    params: DropdownQuickCreateFormSaveParams,
  ): void => {
    const {
      dropdownMeta,
      isSaveAndView,
      isSaveAndNew,
      mustRefresh,
      onCreate,
      validationParams,
    } = params;
    const responseData = lodashGet(action, ['payload', 'data']);
    const newRecordId = lodashGet(responseData, 'id');
    const currentResource = actorGetActionValue('resources')!.current;
    const { uniqueId } = dropdownMeta;

    actorDispatch('loading', { [currentResource.value]: false });

    if (action?.error?.data) {
      handleError(action, validationParams);
    } else if (action.error && !action.error?.data) {
      showNotification(action.error, 'error');
    }

    if (dropdownMeta) {
      // Append new drop data to ACTOR store
      const dropDownData = actorGetActionValue('dropDownData');
      if (dropDownData && dropDownData![uniqueId]) {
        const allData = dropDownData![uniqueId]['ALL']?.concat([responseData]);
        actorDispatch('dropDownData', {
          [uniqueId]: {
            DATA: [responseData],
            ALL: allData,
            LOADING: false,
          },
        });
      }
    }

    if (mustRefresh) {
      reduxDispatch(refreshView());
    }

    if (isSaveAndView && !isEmpty(currentResource.value) && !isEmpty(newRecordId)) {
      reduxDispatch(redirectToPage(prepareUrl(newRecordId)));
      // should close dialog
      actorDispatch('closeDialogs', true);
    }

    if (isSaveAndNew) {
      actorDispatch(
        'resetForm',
        {},
        { callerScopeName: 'use-form-save:dropdownQuickCreateAfterSave:2' },
      );
    } else {
      actorDispatch('remove', {
        resource: currentResource.value,
        type: currentResource.type,
      });
      // should close dialog
      actorDispatch('closeCurrentDialog', true);

      if (typeof onCreate === 'function') {
        setTimeout(() => {
          onCreate({
            ...responseData,
            value: newRecordId,
          });
        }, 500); // FIXME: use actor proxy
      }
    }
  };

  /**
   *
   * @param data
   * @param params
   */
  const gridFormSave = async (
    data: Record<string, unknown>,
    params: GridFormParamsInterface,
  ): Promise<void> => {
    const { isCreateMode, onFailure, onSuccess, additionalFormData, resource } =
      params;
    data = { ...data, ...additionalFormData };

    actorDispatch('loading', { [resource]: true });

    if (isCreateMode) {
      actorDispatch(
        'crudAction',
        {
          type: 'CRUD_CREATE',
          entity: 'grid',
          resource: resource,
          data,
          onSuccess: async response => {
            getGridData(resource, response.data);
            onSuccess?.(response);
          },
          onFailure: error => {
            onFailure?.(error);
          },
        },
        {
          disableDebounce: true,
          replaceAll: true,
          callerScopeName: 'useFormSave => gridFormSave',
        },
      );
    }
  };

  /**
   * Dispatch an action to send a request to API to save form data
   * @function save
   * @param {Record<string, unknown>} data The form data that should be saved within a request (update/create)
   * @param {object} params The parameters that we should send in our request
   * @returns {void}
   */
  const createEditFullFormSave = (
    data: Record<string, unknown>,
    params: CreateEditFullFormSaveParams,
  ): void => {
    const { id } = params;
    const currentResource = actorGetActionValue('resources')!.current;

    actorDispatch('loading', { [currentResource.value]: true });
    const recordWithoutRelationData = actorGetActionValue(
      'record',
      `${currentResource.value}.${currentResource.type}.${RecordKeyMode.FORM}`,
    )!;

    if (!isEmpty(id) && params) {
      reduxDispatch(
        dispatchCrudUpdate(
          currentResource.value,
          id,
          data,
          !isEmptyObject(recordWithoutRelationData) ? recordWithoutRelationData : {},
          action => createEditFullFormAfterSave(action, params),
          false, // disable notification
          {}, // other params
          true, // execute callback on failure
        ),
      );
      return;
    }

    reduxDispatch(
      dispatchCrudCreate(
        currentResource.value,
        data,
        action => createEditFullFormAfterSave(action, params),
        false, // disable notification
        true, // execute callback on failure
        {},
      ),
    );
  };

  /**
   * Handle validation after save.
   * @function handleError
   * @param {Action} action
   * @param {ValidateInputParams} validationParams
   * @returns {void}
   */
  const handleError = (
    action: Action,
    validationParams: ValidateInputParams,
  ): void => {
    const currentResource = actorGetActionValue('resources')!.current;
    const allFields = actorGetActionValue('allFields', [
      currentResource.value,
      currentResource.type,
    ])! as unknown as Array<FieldType>;

    // We sure that here we always have `formData` and `initialData` already
    const formData =
      (actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null) ?? {};

    const preparedApiErrorObject = {
      apiErrors: action.error.data,
      requestId: action.error.requestId,
    } as ApiResponseWithValidationErrors;

    checkValidationErrorsInApiResponse(formData, preparedApiErrorObject, {
      ...validationParams,
      allFields,
    });
  };

  /**
   * `createEditFullFormAfterSave` is a function that passes to redux action to call when needed. it receives an action object from `reduxAction` that includes the result
   * of action like API response or API error or even exceptions. And the second parameter is an object that includes extra parameters that we
   * need like `resetDefaultValues` function. It should handle the API response and fill the error state if needed.
   *  @function createEditFullFormAfterSave
   *  @param {Action} action redux action
   *  @param {ExtraParamsInterface} extraParams - extra params
   *  @returns {void}
   */
  const createEditFullFormAfterSave = (
    action: Action,
    extraParams: RelationEditDialogFormSaveParams,
  ): void => {
    const {
      isQuickForm,
      isSaveAndNew,
      validationParams,
      mustRefresh,
      relationMode,
      isSaveAndView,
      isRelationEditDialogOpen,
      onSuccess,
      onFailure,
    } = extraParams;

    const currentResource = actorGetActionValue('resources')!.current;
    actorDispatch('loading', { [currentResource.value]: false });
    if (action?.error?.data) {
      onFailure?.();
      handleError(action, validationParams);
    } else if (action.error && !action.error?.data) {
      onFailure?.();
      showNotification(action.error, 'error');
    } else {
      showNotification(
        action.payload.userMessage ?? 'ra.notification.successfully_executed',
        'success',
      );

      onSuccess?.();

      deviceCallbackFunction('afterSaveForm');

      const { payload: response } = action;
      const { data: newData, exceptions } = response;

      const newRecordId = lodashGet(newData, 'id');

      if (isEmptyObject(exceptions)) {
        if (
          isSaveAndView &&
          !isEmpty(currentResource.value) &&
          !isEmpty(newRecordId)
        ) {
          // should close dialog
          actorDispatch('closeDialogs', true);
          reduxDispatch(redirectToPage(prepareUrl(newRecordId)));
        }

        if (isSaveAndNew) {
          if (relationMode) {
            actorDispatch('refreshView', currentResource.value, {
              disableDebounce: true,
              callerScopeName: 'useFormSave => createEditFullFormAfterSave 1',
            });
          }
          actorDispatch(
            'resetForm',
            { saveType: 'saveAndNew' },
            { callerScopeName: 'use-form-save:createEditFullFormAfterSave:1' },
          );
        }

        if (isQuickForm && !isSaveAndNew && !isSaveAndView) {
          if (mustRefresh) {
            actorDispatch('refreshView', 'all', {
              callerScopeName: 'useFormSave => createEditFullFormAfterSave 3',
            });
          }

          //TODO: make in function
          if (relationMode || isRelationEditDialogOpen) {
            if (!mustRefresh) {
              actorDispatch('refreshView', currentResource.value, {
                callerScopeName: 'useFormSave => createEditFullFormAfterSave 4',
              });
            }

            actorDispatch('remove', {
              resource: currentResource.value,
              type: currentResource.type,
            });
          } else {
            actorDispatch(
              'formMessages',
              {},
              {
                path: `${currentResource.value}.${currentResource.type}`,
                replaceAll: true,
              },
            );

            actorRemoveAction({
              actionName: 'formData',
              path: `${currentResource.value}.${currentResource.type}`,
            });

            actorRemoveAction({
              actionName: 'record',
              path: `${currentResource.value}.${currentResource.type}`,
            });

            // just in quickCreateDialog
            type === 'quickCreateDialog' && actorDispatch('resetForm', {});
          }

          // should close dialog
          actorDispatch('closeCurrentDialog', true);
          // reduxDispatch(quickCloseDialog());
        }

        if (!isQuickForm && !isSaveAndNew) {
          actorDispatch('closeDialogs', true);
          const parentUrl = actorGetActionValue(
            'quickCreateSupplementaryData',
            `${currentResource.value}.${FormKeyMode.RELATION}.parentUrl`,
          ) as unknown as string | undefined;

          parentUrl && actorDispatch('resetForm', { resource: currentResource });

          reduxDispatch(redirectToPage(parentUrl ?? prepareUrl(newData.id)));
        }
        if (
          currentResource.type === FormKeyMode.RELATION &&
          !isSaveAndNew &&
          !isSaveAndView
        ) {
          const rootResource = actorGetActionValue('resources')!.stack;

          actorSetActionValue(
            'resources',
            {
              stack: [
                {
                  type: FormKeyMode.ROOT,
                  value: rootResource[0].value,
                },
              ],
              current: {
                type: FormKeyMode.ROOT,
                value: rootResource[0].value,
              },
            },
            {
              callerScopeName: 'useFormSave => createEditFullFormAfterSave',
            },
          );
        }
      }
    }
  };

  /**
   * Dispatch an action to send a request to API to edit form data
   * @function quickEditDialogFormSave
   * @param {Record<string, unknown>} data The form data that should be saved within a request (update/create)
   * @param {object} params The parameters that we should send in our request
   * @returns {void}
   */
  const quickEditDialogFormSave = async (
    data: Record<string, unknown>,
    params: QuickEditDialogFormSaveParams,
  ): Promise<void> => {
    const { id, target, onFailure } = params;
    const currentResource = actorGetActionValue('resources')!.current;

    if (!isEmpty(id) && params && !isEmptyObject(data)) {
      actorDispatch('loading', { [currentResource.value]: true });

      try {
        const result = await dataProvider(
          INLINE_CELL_UPDATE,
          currentResource.value,
          {
            data,
            id,
            target,
          },
        );
        quickEditDialogFormAfterSave(result, params);
      } catch (error) {
        showNotification(error, 'error');
        onFailure?.();
        actorDispatch('loading', { [currentResource.value]: false });
      }
    }
  };

  /**
   * check errors and show notification and close dialog or when success close dialog
   *  @function quickEditDialogFormAfterSave
   *  @param {Action} action redux action
   *  @param {QuickEditDialogFormSaveParams} extraParams - extra params
   *  @returns {void}
   */
  const quickEditDialogFormAfterSave = (
    action: Action,
    extraParams: QuickEditDialogFormSaveParams,
  ): void => {
    const compatibleData = objectToLowerCaseProperties(action.data);

    const { validationParams, closeDialog } = extraParams;
    const currentResource = actorGetActionValue('resources')!.current;
    actorDispatch('loading', { [currentResource.value]: false });
    const allFields = actorGetActionValue('allFields', [
      currentResource.value,
      currentResource.type,
    ])! as unknown as Array<FieldType>;

    // We sure that here we always have `formData` and `initialData` already
    const formData =
      (actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null) ?? {};

    if (action.error && action.error.data) {
      // fill the state to pass to validation HOC

      const preparedApiErrorObject = {
        apiErrors: action.error.data,
        requestId: action.error.requestId,
      } as ApiResponseWithValidationErrors;

      checkValidationErrorsInApiResponse(formData, preparedApiErrorObject, {
        ...validationParams,
        allFields,
      });
    } else if (!isEmpty(action.error)) {
      showNotification(action.error.data || action.error.toString(), 'error');
      actorDispatch('refreshView', 'record', {
        callerScopeName: 'useFormSave => quickEditDialogFormAfterSave 1',
      });
      closeDialog();
      // reduxDispatch(refreshView());
    } else {
      actorDispatch('record', compatibleData, {
        path: `${currentResource.value}.${currentResource.type}.${RecordKeyMode.FULL}`,
        replaceAll: true,
      });

      actorDispatch('refreshView', 'all', {
        disableDebounce: true,
        callerScopeName: 'useFormSave => quickEditDialogFormAfterSave 2',
      });
      closeDialog();

      // reduxDispatch(refreshView());
    }
  };

  /*
   * this is save function of a service-dialog-form
   *
   * @function serviceDialogFullFormSave
   * @param {Record<string, unknown>} params
   * @param {ServiceDialogFormParams} props
   * @returns {Promise<void>}
   */
  const serviceDialogFullFormSave = async (
    params: Record<string, unknown>,
    props: ServiceDialogFormParams,
  ): Promise<void> => {
    const {
      selectedService,
      selectedIds,
      customRefresh,
      parentResource,
      onFailure,
    } = props;

    const { current: currentResource, stack } = actorGetActionValue('resources')!;

    actorDispatch('loading', true, {
      path: currentResource.value,
    });

    // We need parentResource for this reason We get previous resource from stack resources
    const resource = !isEmpty(parentResource)
      ? parentResource
      : stack[stack.length - 2]?.value;

    if (!resource) return;

    let resourcesData: Record<string, unknown>[] | undefined = lodashGet(
      reduxState,
      ['admin', 'resources', resource, 'data'],
    );

    //in relation mode grid data saved in actor
    let selectedIdList = clone(
      selectedIds.length
        ? selectedIds
        : (actorGetActionValue('gridIDs', resource)
            ?.selectedIDs as unknown as number[]) ?? [],
    );

    if (isEmptyObject(resourcesData)) {
      const gridData = actorGetActionValue(
        'gridData',
        resource,
      ) as GridDataInterface;

      selectedIdList =
        gridData?.selectedIds?.map(Number) ??
        (actorGetActionValue('gridIDs', resource)
          ?.selectedIDs as unknown as number[]) ??
        [];
      resourcesData = gridData?.data;
    }

    const recordInActor = actorGetActionValue(
      'record',
      `${resource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
    ) as Record<string, unknown> | null;

    const isReport = resource.indexOf('report') === 0;
    const [serviceModuleName, serviceModuleTableName] = resource.split('/');

    selectedIdList = selectedIdList?.map(Number);
    let serviceParameter: Record<string, unknown> = {
      actionUniqueId: selectedService?.uniqueId,
      data: {
        params:
          lodashGet(selectedService, 'related') === 'SingleRecord'
            ? lodashMerge(resourcesData?.[selectedIdList[0]], params)
            : params,
        items: !isEmptyObject(resourcesData)
          ? lodashFilter(resourcesData, (value, index) =>
              selectedIdList.includes(Number(value.id)),
            )
          : [{ ...recordInActor, ...params }],
      },
    };

    if (isReport) {
      serviceParameter = {
        ...serviceParameter,
        reportId: serviceModuleTableName,
      };
    } else {
      serviceParameter = {
        ...serviceParameter,
        serviceModuleName,
        serviceModuleTableName,
      };
    }

    try {
      const response = await dataProvider(RUN_SERVICE, resource, serviceParameter);
      const message = lodashGet(response, 'userMessage');

      showNotification(!isEmpty(message) ? message : 'service.success', 'success', {
        forceSnackbar: true,
      }); // show success notification
      customRefresh?.();
      // TODO: maybe need to refresh all relations
      // reduxDispatch(refreshRelations());

      // reduxDispatch(refreshOneRelationAction({ resource }));
      reduxDispatch(refreshView());
      actorDispatch('closeCurrentDialog', true);

      /**
       * When we have `recordOpenForm` It means there is a redirection
       * So we don't need to `refreshView`, Otherwise should `refreshView` be done
       */

      //TODO: Do we have to check `recordEditForm` for `services` too?
      if (isEmptyObject(response.actionOutput.recordOpenForm)) {
        actorDispatch('refreshView', 'all', {
          disableDebounce: true,
          callerScopeName: 'useFormSave => serviceDialogFullFormSave',
        });
      }

      setTimeout(() => {
        reduxDispatch(setListSelectedIds(resource, [])); // clear grid selection

        actorDispatch('loading', false, {
          path: currentResource.value,
        });
      }, 500);
    } catch (error) {
      onFailure?.(error);

      let errorMessage = '';
      if (typeof error === 'object') {
        // TODO: After refactoring `dataProvider`, write a proper type instead of `Record<string, any>`
        errorMessage = getResponseMessage(error as any);
      } else if (typeof error === 'string') {
        errorMessage = error;
      }

      actorDispatch('loading', false, {
        path: currentResource.value,
      });

      // TODO: Do we need to check this situation? it's will not happen
      if (isEmpty(errorMessage)) return;

      showNotification(errorMessage, 'error', {
        forceSnackbar: true,
        fromQuickCreateDialog: true, // to render notification in <portal/> with id: `serviceCustomSnackContainer`
      });
    }
  };

  const failureChangePasswordCallback = useCallback((action: string) => {
    if (typeof action === 'string') {
      showNotification(action, 'error');
    }
  }, []);

  const successChangePasswordCallback = useCallback(
    (props: ChangePasswordParams) => {
      props?.closeDialog();
      showNotification('notification.changePassword.success', 'success');
    },
    [],
  );

  const profileFormFullSave = (
    params: Record<string, unknown>,
    props: ProfileFormParams,
  ): void => {
    const { current: currentResource } = actorGetActionValue('resources')!;
    const { handleCloseProfileFormDialog } = actorGetActionValue(
      'handleCloseProfileFormDialog',
    )!;

    const { fields: profileSettingFields } = props;

    if (isEmptyObject(params)) return;

    actorDispatch('loading', true, {
      path: currentResource.value,
    });

    const value = +lodashGet(params, lodashGet(profileSettingFields, ['0', 'name']));
    const wareHouseTitle = lodashGet(params, '__currentWareHouseID_value');

    const newGlobalParamsData = prepareGlobalParameters(
      value,
      profileSettingFields[0],
    );

    setValue(USER_WAREHOUSE_ID, value);
    setValue(USER_WAREHOUSE_TITLE, wareHouseTitle);

    //remove cache after change warehouse
    removeValue(CONFIG_CACHED_MENU);
    setValue(SERVICE_WORKER_CACHE_VERSION, 'removeServiceWorker');

    actorSetActionValue('formData', value, {
      path: `account/${getValue(API_NAME)}/profile.${profileSettingFields[0].name}`,
      callerScopeName: 'form/hooks/use-form-save.ts profileFormFullSave',
    });

    actorDispatch('profileSetting', {
      USER_WAREHOUSE_ID: value,
    });
    handleCloseProfileFormDialog();

    actorDispatch('changeProfile', {
      data: newGlobalParamsData,
      successCallback: reloadPage,
    });

    setTimeout(() => {
      actorDispatch('loading', false, {
        path: currentResource.value,
      });
    }, 1000);
  };

  const reloadPage = () => {
    location.reload();
  };
  // FIXME: Clean up body of this function
  /**
   * It does some processes to send received data to the server
   * @function wmsSubmitForm
   * @param { object } data
   * @param { object } params
   * @returns { void }
   */
  const wmsSubmitForm = (
    data: Record<string, unknown>,
    params: Record<string, unknown>,
  ): void => {
    const currentResource = actorGetActionValue('resources')!.stack[0];
    const allFields = actorGetActionValue(
      'allFields',
      `${currentResource.value}.${currentResource.type}`,
    );

    let hasValidationCheck = false;
    const { validationActionId, actionUniqueId, isCRUDCreate } = params;

    if (isCRUDCreate) {
      const { resource, disableNotification, executeCallbackOnFailure, callback } =
        params;

      reduxDispatch(
        dispatchCrudCreate(
          resource as string,
          data,
          callback as (...args) => void,
          disableNotification as boolean,
          executeCallbackOnFailure as boolean,
          {
            filedList: allFields,
            translate,
            showNotification,
            locale,
          },
        ),
      );

      return;
    }

    // The following code is about SERVICEs
    if (validationActionId) {
      hasValidationCheck = true;

      actorDispatch(
        'crudAction',
        {
          entity: 'wms',
          type: 'RUN_SERVICE',
          // payload: {
          actionUniqueId: validationActionId,
          data: {
            params: data,
          },
          onSuccess: (): void => {
            runWMSService({
              ...params,
              data: {
                params: data,
              },
            });
          },
          onFailure: (error: unknown): void => {
            let _userMessage;
            if (error instanceof Error) {
              _userMessage = error.message;
            } else if (typeof error === 'string') {
              _userMessage = error;
            }

            showNotification(_userMessage, 'error');

            if (typeof params.onFailure === 'function') {
              params.onFailure();
            }
          },
        },
        {
          disableDebounce: true,
          replaceAll: true,
          callerScopeName: 'useFormSave => wmsSubmitForm',
        },
      );
    }

    if (!hasValidationCheck && actionUniqueId) {
      runWMSService({
        ...params,
        data: {
          params: data,
        },
      });

      return;
    }
  };

  /**
   * @function runWMSService
   * @param { Record<string, unknown> } params
   * return { void } void
   */
  const runWMSService = (params: Record<string, unknown>): void => {
    const { actionUniqueId, data, onSuccess } = params;

    actorDispatch(
      'crudAction',
      {
        entity: 'wms',
        type: 'RUN_SERVICE',
        actionUniqueId,
        data,
        onSuccess: (response: Record<string, unknown>): void => {
          if (typeof onSuccess === 'function') {
            onSuccess(response);
          } else {
            showNotification(response.userMessage, 'info');
          }
        },
        onFailure: (error: unknown): void => {
          showNotification(error, 'error');

          if (typeof params.onFailure === 'function') {
            params.onFailure();
          }
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'useFormSave => runWMSService',
      },
    );
  };

  /**
   *
   * @function simpleFormSubmit
   * @param { object } data
   * @param { object } params
   * @returns { void }
   */
  const simpleFormSubmit = (
    data: Record<string, unknown>,
    params: Record<string, unknown>,
  ): void => {
    actorDispatch('runActionsService', {
      actionUniqueId: params?.actionUniqueId,
      params: data,
      failureCallback: error => showNotification(error, 'error'),
      successCallback: params?.onSuccess,
    });
  };

  const changePasswordSave = (
    params: Record<string, unknown>,
    props: ChangePasswordParams,
  ): void => {
    const action = {
      failureCallback: failureChangePasswordCallback,
      successCallback: successChangePasswordCallback,
      data: { params, props },
    };
    actorDispatch('changePassword', action);
  };

  switch (type) {
    case 'createEditRecordPage':
    case 'quickCreateDialog':
    case 'relationEditDialogForm':
      handleAction = createEditFullFormSave;
      break;

    case 'gridForm':
      handleAction = gridFormSave;
      break;

    case 'dropdownQuickCreateForm':
      handleAction = dropdownQuickCreateSave;
      break;
    case 'quickEditDialog':
      handleAction = quickEditDialogFormSave;
      break;

    case 'serviceDialogForm':
      handleAction = serviceDialogFullFormSave;
      break;
    case 'profileForm':
      handleAction = profileFormFullSave;
      break;
    case 'changePassword':
      handleAction = changePasswordSave;
      break;

    case 'wms':
      handleAction = wmsSubmitForm;
      break;

    case 'simpleForm':
      handleAction = simpleFormSubmit;
      break;

    default:
      break;
  }

  return useCallback(handleAction, [type]);
}
