import { useTranslate } from 'react-admin';
import dxDataGrid from 'devextreme/ui/data_grid';

import { CONFIG_VISIBLE_COLUMNS } from '../../../core/configProvider';
import { setAppSettings } from '../../../helper/settings-helper';

const useVisibleColumns = entries => {
  const { gridRef, resource } = entries;

  const translate = useTranslate();

  function setGridVisibleColumnsSetting(visibleColumns: string[]): void {
    gridRef.current?.instance.beginCustomLoading(translate('form.sendingData'));

    setAppSettings({
      key: `${CONFIG_VISIBLE_COLUMNS}_${resource}`,
      value: visibleColumns,
      forUser: true,
      onSuccess: () => {
        gridRef.current?.instance.endCustomLoading();
      },
    });
  }

  function getVisibleColumns(grid: dxDataGrid): string[] {
    const columnsOptions: string[] = [];
    const count = grid.columnCount();
    for (let index = 0; index < count; index++) {
      const columnOptions = grid.columnOption(index);
      if (columnOptions.visible !== undefined && columnOptions.visible) {
        columnsOptions.push(columnOptions.name);
      }
    }

    return columnsOptions;
  }

  return {
    setGridVisibleColumnsSetting,
    getVisibleColumns,
  };
};

export default useVisibleColumns;
