import { type ReactElement, memo } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import _ from 'lodash';
import DataGrid, {
  Column,
  Editing,
  Selection,
  Summary,
  TotalItem,
  Scrolling,
  FilterRow,
  KeyboardNavigation,
  Export,
  GroupPanel,
  GroupItem,
  ColumnFixing,
} from 'devextreme-react/data-grid';

import { GridCell } from './grid-cell';
import { useStyles } from './grid.style';
import ActionEditTableCell from '../devExGrid/ActionEditTableCell';
import './customStyle.css';
import { replaceFarsiCharactersWithArabic } from '../../helper/TextHelper';
import { GridCustomInput } from './grid-custom-input';

import type { GridViewPropsInterface } from './grid.type';
import { isEmptyObject } from '../../helper/data-helper';
import { FilterValueStructureEnum } from '../filter-form/filter-form.helper';
import { ListHeaderFooter } from '../list-header-footer';
import { checkGridColumnHasDynamic } from './grid.helper';

import './customStyle.css';

const GridView = (props: GridViewPropsInterface): ReactElement => {
  const {
    // selection
    changeCheckboxSelection,
    enableSelection,

    // summery
    formatTotalSummaryGroupItem,
    formatTotalSummaryCell,
    totalSummaryItems,

    // filters
    addToFilterRequestList,
    isTopFilterOpen,
    filterValues,

    // form
    onFailureSaveForm,
    onEditorPreparing,
    handleOnRowClick,
    focusOnLastInput,
    allowEditInline,
    onEditCanceled,
    allowAddInGrid,
    onEditingStart,
    onCellPrepared,
    isEnableInput,
    onChangeInput,
    onInitNewRow,
    onCellClick,
    onSaving,
    quickEditButton,
    lastFocusCell,
    formMode,

    // event handling
    onOptionChanged,

    // context menu
    onContextMenuPreparing,

    // styles
    groupCellTemplate,
    getGridClass,
    setRowColor,

    // inputs
    inputsCustomFunctionRef,

    // exports
    enableClientExportExcel,

    // resource and data
    relationResource,
    parentInfo,
    basePath,
    redirect,
    resource,
    metaData,
    rows,

    // column chooser
    hasColumnChooser,
    columns,

    // grouping
    isGroupingOpen,
    groupColumns,

    // fixed columns
    fixedColumns,

    // flags
    onlyClientSort,
    relationMode,
    hasShow,
    hasEdit,
    isMap,

    // references
    gridRef,

    // sorting
    sort,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();

  const hasDynamic = checkGridColumnHasDynamic(rows, metaData);

  return (
    <div
      className={isMap ? classes.containerMap : classes.container}
      id="gridContainer"
    >
      {!isMap && <ListHeaderFooter message={metaData?.translatedHeader?.[locale]} />}

      {/* @ts-ignore */}
      <DataGrid
        columnChooser={{
          emptyPanelText: translate('grid.columnChooser'),
          enabled: hasColumnChooser,
          title: translate('grid.columnChooser'),
        }}
        allowColumnReordering={hasDynamic}
        ref={gridRef}
        dataSource={rows}
        onContextMenuPreparing={onContextMenuPreparing}
        keyExpr="id"
        showBorders={true}
        rowAlternationEnabled={true}
        showRowLines={true} //hide horizontal border in main grid
        showColumnLines={true} //hide horizontal border in main grid
        rtlEnabled={locale !== 'en'}
        allowColumnResizing={true}
        columnResizingMode={'widget'}
        paging={{ enabled: false }}
        onEditingStart={onEditingStart}
        onInitNewRow={onInitNewRow}
        onEditCanceled={onEditCanceled}
        onSelectionChanged={data => changeCheckboxSelection(data)}
        className={getGridClass()}
        onRowClick={({ data, rowType }) => handleOnRowClick(data, rowType)}
        onSaving={onSaving}
        onRowInserting={data => (data.cancel = true)}
        remoteOperations={{ sorting: true, filtering: true }}
        onCellClick={onCellClick}
        noDataText={translate('imageDialog.dataNotFound')}
        onRowPrepared={setRowColor}
        onOptionChanged={onOptionChanged}
        onCellPrepared={onCellPrepared}
        onEditorPreparing={onEditorPreparing}
        onFocusedCellChanged={e => {
          //don't set focus when click on checkbox
          if (!e.column?.type || e.column?.type != 'selection') {
            lastFocusCell.current = e;
          }
        }}
        repaintChangesOnly={true}
      >
        <FilterRow showOperationChooser={false} visible={isTopFilterOpen} />
        <ColumnFixing
          enabled
          texts={{
            fix: translate('grid.fixColumn'),
            leftPosition: translate('grid.fixColumnLeft'),
            rightPosition: translate('grid.fixColumnRight'),
            unfix: translate('grid.unfixColumn'),
          }}
        />

        <GroupPanel
          emptyPanelText={translate('grid.groupingEmptyText')}
          visible={isGroupingOpen}
        />

        <FilterRow showOperationChooser={false} visible={isTopFilterOpen} />

        <Scrolling
          // useNative={true}
          showScrollbar={'always'}
          preloadEnabled={true}
          mode={'virtual'}
          scrollByThumb={true}
        />

        {enableSelection && (
          <Selection
            mode="multiple"
            selectAllMode={true}
            showCheckBoxesMode={'always'}
          />
        )}
        <KeyboardNavigation editOnKeyPress={true} />
        {formMode == 'add' ? (
          <Editing
            allowUpdating={true}
            allowAdding={allowAddInGrid()}
            mode={'row'}
          />
        ) : (
          <Editing
            allowUpdating={true}
            allowAdding={allowAddInGrid()}
            mode={'cell'}
            useIcons={true}
          />
        )}
        {hasEdit && (
          <Column
            type="buttons"
            width={'40px'}
            cellRender={({ data }) => (
              <ActionEditTableCell
                quickEditButton={quickEditButton}
                classes={classes}
                basePath={basePath}
                currentUrl={redirect}
                translate={translate}
                metaData={metaData}
                parentInfo={parentInfo}
                relationMode={relationMode}
                relationResource={relationResource}
                row={data}
              />
            )}
          />
        )}
        {metaData?.columnsGroups?.map(columnsGroupInfo => {
          return (
            // @ts-ignore
            <Column caption={columnsGroupInfo.caption}>
              {
                // @ts-ignore
                columns.map(columnInfo => {
                  if (
                    columnInfo &&
                    columnInfo?.field &&
                    columnsGroupInfo.columns.find(
                      item => item.toString() === columnInfo.name.toString(),
                    )
                  ) {
                    const fixedColumnPosition = fixedColumns?.find(
                      column => column.name === columnInfo.name,
                    )?.fixedPosition;
                    return (
                      <Column
                        groupCellRender={groupCellTemplate(columnInfo?.field)}
                        key={columnInfo.name}
                        name={columnInfo.name}
                        groupIndex={
                          isGroupingOpen
                            ? groupColumns?.find(
                                column => column.columnName === columnInfo.name,
                              )?.groupIndex ?? undefined
                            : -1
                        }
                        fixed={fixedColumnPosition ? true : false}
                        fixedPosition={fixedColumnPosition}
                        // validationRules={[{ type: 'numeric' }]}
                        caption={columnInfo.title}
                        sortIndex={
                          sort && (columnInfo.name === sort?.field ? 1 : undefined)
                        }
                        sortOrder={
                          sort && columnInfo.name === sort?.field
                            ? sort?.order?.toLowerCase()
                            : undefined
                        }
                        sortingMethod={
                          onlyClientSort || hasDynamic
                            ? undefined
                            : () => {
                                //this function should be here to prevent sorting
                                return;
                              }
                        }
                        // dataType={getColumnDataType(columnInfo.field)}
                        allowEditing={allowEditInline(columnInfo.field)}
                        allowSorting={columnInfo.field?.allowSort}
                        cellRender={cellData => (
                          <GridCell
                            key={`gridCell${columnInfo.name}`}
                            column={columnInfo}
                            row={cellData.data}
                            basePath={basePath}
                            redirect={redirect}
                            hasShow={hasShow}
                            resource={resource}
                            relationMode={!!relationMode}
                            metaData={metaData}
                            addToFilterRequestList={addToFilterRequestList}
                            value={cellData.data[columnInfo.field?.name as string]}
                            hasEdit={hasEdit && cellData.data.iseditable}
                            onFailureSaveForm={onFailureSaveForm}
                          />
                        )}
                        editCellComponent={() => (
                          <GridCustomInput
                            key={columnInfo.field?.name}
                            focusOnLastInput={focusOnLastInput}
                            inputsCustomFunctionRef={inputsCustomFunctionRef}
                            onChangeInput={onChangeInput}
                            isDisabled={
                              !isEnableInput(columnInfo.field?.name as string)
                            }
                            field={columnInfo.field}
                            resource={resource}
                          />
                        )}
                        width={columnInfo.field?.width}
                        selectedFilterOperation={'contains'}
                        defaultSelectedFilterOperation={'contains'}
                        filterValue={
                          isTopFilterOpen && filterValues?.[columnInfo.field?.name]
                            ? replaceFarsiCharactersWithArabic(
                                filterValues?.[columnInfo.field?.name]?.[
                                  FilterValueStructureEnum.VALUE
                                ] as string,
                              )
                            : null
                        } //set top filter value
                      />
                    );
                  }
                })
              }
            </Column>
          );
        })}
        {isEmptyObject(metaData?.columnsGroups) &&
          _.map(columns, columnInfo => (
            // @ts-ignore
            <Column
              groupCellRender={groupCellTemplate(columnInfo?.field)}
              name={columnInfo.name}
              key={columnInfo.name}
              dataField={columnInfo.name}
              groupIndex={
                isGroupingOpen
                  ? groupColumns?.find(
                      column => column.columnName === columnInfo.name,
                    )?.groupIndex ?? undefined
                  : -1
              }
              caption={columnInfo.title}
              sortIndex={sort && (columnInfo.name === sort?.field ? 1 : undefined)}
              fixed={
                fixedColumns?.find(column => column.name === columnInfo.name)
                  ? true
                  : false
              }
              fixedPosition={
                fixedColumns?.find(column => column.name === columnInfo.name)
                  ?.fixedPosition
              }
              sortOrder={
                sort && columnInfo.name === sort?.field
                  ? sort?.order?.toLowerCase()
                  : undefined
              }
              sortingMethod={
                onlyClientSort || hasDynamic
                  ? undefined
                  : () => {
                      //this function should be here to prevent sorting
                      return;
                    }
              }
              // dataType={getColumnDataType(columnInfo.field)}
              allowEditing={allowEditInline(columnInfo.field)}
              allowSorting={columnInfo.field?.allowSort}
              cellRender={cellData => (
                <GridCell
                  key={`gridCell${columnInfo.name}`}
                  column={columnInfo}
                  row={cellData.data}
                  basePath={basePath}
                  redirect={redirect}
                  hasShow={hasShow}
                  resource={resource}
                  relationMode={!!relationMode}
                  metaData={metaData}
                  addToFilterRequestList={addToFilterRequestList}
                  value={cellData?.data[columnInfo?.field?.name]}
                  hasEdit={hasEdit && cellData.data.iseditable}
                  onFailureSaveForm={onFailureSaveForm}
                />
              )}
              editCellComponent={() => (
                <GridCustomInput
                  key={columnInfo.field.name}
                  inputsCustomFunctionRef={inputsCustomFunctionRef}
                  focusOnLastInput={focusOnLastInput}
                  onChangeInput={onChangeInput}
                  isDisabled={!isEnableInput(columnInfo.field.name)}
                  field={columnInfo.field}
                  resource={resource}
                />
              )}
              width={columnInfo.field?.width}
              selectedFilterOperation={'contains'}
              defaultSelectedFilterOperation={'contains'}
              filterValue={
                isTopFilterOpen && filterValues?.[columnInfo.field?.name]
                  ? replaceFarsiCharactersWithArabic(
                      filterValues?.[columnInfo.field?.name]?.[
                        FilterValueStructureEnum.VALUE
                      ] as string,
                    )
                  : null
              } //set top filter value
            />
          ))}
        {enableClientExportExcel && <Export enabled={true} />}
        {/* @ts-ignore */}
        <Summary recalculateWhileEditing={true}>
          {_.map(totalSummaryItems, (totalItem, index) => (
            <TotalItem
              column={totalItem.columnName}
              summaryType={totalItem.type}
              customizeText={() => formatTotalSummaryCell(totalItem.columnName)}
              cssClass={`${classes.totalSummary} data-test-column-sum-${totalItem.columnName}`}
              key={`TotalItem_${index}`}
            />
          ))}
          {!!isGroupingOpen &&
            _.map(totalSummaryItems, totalItem => (
              <GroupItem
                alignByColumn={true}
                showInColumn={true}
                column={totalItem.columnName}
                summaryType={totalItem.type}
                displayFormat="{0}"
                customizeText={event => formatTotalSummaryGroupItem(event.value)}
              />
            ))}
        </Summary>
      </DataGrid>
      <ListHeaderFooter message={metaData?.translatedFooter?.[locale]} />
    </div>
  );
};

export default memo(GridView);
