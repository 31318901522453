import { useEffect } from 'react';
import { useTranslate } from 'react-admin';

import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';

const useLoading = entries => {
  const { resource, gridRef, prepareAllFields, setFormMode, data } = entries;

  const translate = useTranslate();

  useEffect(() => {
    const onDispatchId = actorOnDispatch('loading', loadingList => {
      if (loadingList?.[resource]) {
        gridRef.current?.instance.beginCustomLoading(translate('form.showData'));
      } else {
        gridRef.current?.instance.endCustomLoading();
      }

      if (loadingList?.processChangeLineButtons) {
        prepareAllFields(); //get allFields again ,when process changed;
        setFormMode('edit');
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'loading',
        listenerId: onDispatchId,
      });
    };
  }, []);

  useEffect(() => {
    gridRef.current?.instance.endCustomLoading();
  }, [data]);

  const actorIsLoading = actorGetActionValue('loading')?.[resource];

  return { actorIsLoading };
};

export default useLoading;
