// FIXME: https://jira.samiansoft.com/browse/RCT-3093
import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Icon, IconButton, makeStyles } from '@material-ui/core';
import ProcessIcon from '@material-ui/icons/CallMade';
import { push } from 'connected-react-router';
import lodashGet from 'lodash/get';
import { linkToRecord } from 'ra-core';
import {
  Button as ReactAdminButton,
  Toolbar,
  useCreate,
  useTranslate,
  useUpdate,
} from 'react-admin';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getProcessLines,
  getRelationsInForm,
  isRecordEditable,
  isSingleRecordTable,
} from '../helper/MetaHelper';
import {
  FieldType,
  GeneralMetaData,
  MetaData,
  MetaDataBase,
  ValidationError,
} from '../helper/Types';
import { handleServerSideValidationErrors } from '../helper/validation-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  RecordKeyMode,
} from '../type/actor-setup';
import CustomFormButton from './form-component-old/CustomFormButton';

import PrintButtonContainer from '../container/PrintButtonContainer';
import ServiceButtonContainer from '../container/ServiceButtonContainer';
import {
  deviceCallbackFunction,
  showNotification,
} from '../helper/general-function-helper';
import { getProcessList, getVisibleServices } from '../helper/meta-helper';
import useKeyPress from '../hooks/useKeyPress';
import { ExtraParamsInterface, FormActionProps, FormActions } from './form';
import { sanitizeFormData } from './form/form.helper';

import type { CustomTheme } from '../core/themeProvider';
import { descriptionFields } from './dialogs-stack/user-description-dialog/user-description-dialog.helper';
import { SaveType } from './QuickCreateButtonToolbar';
import { showImageDialog } from './list/list.helper';
import { isEmpty, isEmptyObject } from '../helper/data-helper';

interface DumbButtonType {
  className?: string;
  disabled: boolean;
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  children: ReactNode;
}

interface ProcessLineChangeButtonType {
  processLine: {
    id: string | undefined;
    title: string;
    color?: string;
    isNecessaryDescription?: boolean;
    priority?: number;
  };

  locale;
  record: Record<string, unknown>;
  redirect: string | boolean;
  handleSubmitWithRedirect: Function;
  disabled: boolean;
  metaData: MetaData;
  isCreateMode: boolean;
  resource: string;
  redirectToPage: Function;
  editedFormData: object;
  basePath: string;
}

interface FormActionButtonListType {
  allFormData: object[];
  closeDialog: Function;
  redirectToPage: Function;
  resetForm: Function;
  redirect: string | boolean;
  record: Record<string, unknown>;
  crudCreateWithCallback: Function;
  submitOnEnter: boolean;
  metaData: MetaData;
  locale;
  handleSubmitWithRedirect: Function;
  customSubmit: Function;
  isLoading: boolean;
  processLineList: { id: string | undefined; title: string }[];
  processTaskInfo: object;
  formData: object;
  isCreateMode: boolean;
  resource: string;
  isDefaultMode: boolean;
  editedFormData: object;
  setRedirect: Function;
  rest: Record<string, unknown>;
  formName?: keyof FormActionProps;
}

const useStyles = makeStyles<CustomTheme>(theme => ({
  container: {
    margin: 0,
    padding: '0 16px',
    backgroundColor: `${theme.palette.primary.appPrimaryToolbarBackgroundColor}`,
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.45)',
    '&.MuiToolbar-regular': {
      minHeight: '45px !important',
    },
    [theme.breakpoints.up('sm')]: {
      position: 'sticky',
      top: (props: { isCreateMode: boolean }) =>
        props.isCreateMode ? '50px' : '94px', //header div height
      zIndex: 2,
    },
  },
  dumbButton: {
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      padding: '5px 0 !important',
    },
    background: 'rgba(38,57,72,0.04)',
  },

  grow: {
    flexGrow: 1,
  },

  iconButton: {
    padding: 5,
    margin: '0 6px',
  },
  spacer: {
    display: 'none',
  },

  mobileToolbar: {
    padding: 0,
  },
}));

const DumbButton = (props: DumbButtonType) => {
  const { onClick, children, className, disabled = false } = props;
  return (
    <Button disabled={disabled} className={className} onClick={onClick}>
      {children}
    </Button>
  );
};

const ProcessLineChangeButton = (props: ProcessLineChangeButtonType) => {
  const {
    processLine,
    locale,
    disabled,
    record,
    metaData,
    isCreateMode,
    redirectToPage,
    basePath,
  } = props;

  const classes = useStyles({ isCreateMode });
  const translate = useTranslate();

  const [formData, setFormData] = useState<Record<string, unknown>>(record);

  const onDispatchRef = useRef<{ actionName: keyof ActorActionList; id: symbol }[]>(
    [],
  );

  const currentResource = actorGetActionValue('resources')!.current;

  const recordWithoutRelationData = actorGetActionValue(
    'record',
    `${currentResource.value}.${currentResource.type}.${RecordKeyMode.FORM}`,
  )! as Record<string, unknown>;

  const { checkValidationClientSide } = actorGetActionValue('formGlobalProps')!;

  const prevValidationMessages =
    (actorGetActionValue('formMessages', [
      currentResource.value,
      currentResource.type,
    ]) as ValidationError | null) ?? {};

  const allFields = actorGetActionValue('allFields', [
    currentResource.value,
    currentResource.type,
  ])! as unknown as Array<FieldType>;

  useEffect(() => {
    let id = actorOnDispatch('formData', newFormData => {
      newFormData &&
        setFormData(
          newFormData?.[currentResource.value]?.[currentResource.type] ?? {},
        );
    });
    onDispatchRef.current.push({ actionName: 'formData', id });

    id = actorOnDispatch('signal', signalKey => {
      if (signalKey === 'formDefaultValuesSet') {
        const newFormData =
          actorGetActionValue('formData')?.[currentResource.value]?.[
            currentResource.type
          ] ?? {};

        setFormData(newFormData);
      }
    });
    onDispatchRef.current.push({ actionName: 'formData', id });

    return () => {
      for (const onDispatchData of onDispatchRef.current) {
        actorRemoveAction({
          actionName: onDispatchData.actionName,
          listenerId: onDispatchData.id,
        });
      }
    };
  }, []);

  const sanitizedFormData = sanitizeFormData(formData);

  const additionalData = {
    ...sanitizedFormData,
    __processuniqueid: record.__processuniqueid,
    __lineid: processLine.id,
  };

  const onSuccess = data => {
    deviceCallbackFunction('successUpdateProcessLine');
    const _record = data?.data;
    showNotification('ra.notification.updated', 'info');
    actorDispatch('loading', false, { path: 'processChangeLineButtons' });
    redirectToPage(`${linkToRecord(basePath, _record && _record?.id)}/show`);
  };

  const onFailure = error => {
    actorDispatch('loading', false, { path: 'processChangeLineButtons' });

    if (typeof error === 'string') {
      showNotification(error, 'error');
    } else {
      const { data, requestId } = error;
      try {
        handleServerSideValidationErrors(
          metaData as MetaDataBase,
          [],
          { apiErrors: data, requestId },
          formData,
          showNotification,
          translate,
          locale,
        );
      } catch (error) {
        console.log('FormActionButtonList:142.js >> error in catch', { error });
      }
    }
  };

  const [create, { loading: createLoading }] = useCreate(
    currentResource.value,
    additionalData,
    {
      onSuccess,
      onFailure,
    },
  );

  const [updateProcess, { loading }] = useUpdate(
    currentResource.value,
    lodashGet(recordWithoutRelationData, 'id'),
    additionalData,
    recordWithoutRelationData, //previousData
    {
      undoable: false,
      onSuccess,
      onFailure,
    },
  );

  useEffect(() => {
    actorDispatch('loading', loading, { path: 'editProcess' });
  }, [loading]);

  /**
   * submit the process with confirm's dialog formData
   * @function onSubmitConfirmForm
   * @param {Record<string,unknown>} formData this formData merged with main formData
   * @returns {Promise<void>}
   */
  const onSubmitConfirmForm = (formData: Record<string, unknown>): Promise<void> =>
    new Promise((resolve, reject) => {
      actorDispatch('loading', true, { path: 'processChangeLineButtons' });

      if (isCreateMode) {
        create(currentResource.value, formData, {
          OnSuccess: resolve,
          OnFailure: reject,
        });
      } else {
        updateProcess(
          currentResource.value,
          lodashGet(recordWithoutRelationData, 'id'),
          formData,
          recordWithoutRelationData, //previousData
          {
            OnSuccess: resolve,
            OnFailure: reject,
            undoable: false,
          },
        );
      }
    });

  /**
   * open confirm's dialog for the processes that need description
   * @function openConfirmDialog
   * @returns {void}
   */
  const openConfirmDialog = (): void => {
    actorDispatch('quickDialog', {
      userDescriptionDialogIsOpen: true,
      data: {
        onSubmit: onSubmitConfirmForm,
        fields: descriptionFields,
        dialogTitle: translate('form.pleaseFillTheDescription'),
      },
    });
  };

  /**
   * Submit and redirect to specified page
   * @function handleClick
   * @returns void
   */
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (
      !checkValidationClientSide(
        prevValidationMessages,
        formData,
        allFields,
        currentResource,
      )
    ) {
      return;
    }

    if (processLine.isNecessaryDescription) {
      openConfirmDialog();
      return;
    }

    actorDispatch('loading', true, { path: 'processChangeLineButtons' });

    if (isCreateMode) {
      create();
    } else {
      updateProcess();
    }
  };

  if (!formData?.['iseditable']) {
    return null;
  }

  return (
    <Button
      color="primary"
      className={classes.dumbButton}
      onClick={handleClick}
      disabled={disabled || loading || createLoading}
      data-test-process-id={processLine.id}
    >
      <ProcessIcon htmlColor={processLine?.color} /> &nbsp;
      {lodashGet(processLine, ['translatedTitle', locale], processLine.title)}
    </Button>
  );
};

const FormActionButtonList = (props: FormActionButtonListType) => {
  const {
    redirectToPage,
    redirect,
    locale,
    handleSubmitWithRedirect,
    isLoading,
    isCreateMode,
    resource,
    isDefaultMode,
    editedFormData,
    formName,
    ...rest
  } = props;

  const translate = useTranslate();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const saveMainButtonRef = useRef<HTMLButtonElement | null>(null);
  const onDispatchRef = useRef<{ actionName: keyof ActorActionList; id: symbol }[]>(
    [],
  );

  const [loading, setLoading] = useState(false);

  //---------------- ACTOR ---------------------------------------------------
  const { current: currentResource } = actorGetActionValue('resources')!;
  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
  const basePath = `/${currentResource.value}`;

  const metaData = actorGetActionValue(
    'metaData',
    currentResource.value,
  )! as unknown as GeneralMetaData;

  const record = actorGetActionValue('record', [
    currentResource.value,
    currentResource.type,
    RecordKeyMode.FULL,
  ])! as Record<string, unknown>;

  // TODO: Check if .toString() is necessary or not
  const processLineList = getProcessLines(
    metaData,
    lodashGet(record, '__processuniqueid', null),
    lodashGet(record, 'positionid', null)?.toString(),
    lodashGet(record, 'stateid', null)?.toString(),
  );

  const isSaveDisabled = !isCreateMode ? !isRecordEditable(metaData, record) : false;
  const isSingleRecord = isSingleRecordTable(metaData);
  const processList = getProcessList(metaData as GeneralMetaData).all;

  const visibleServices = getVisibleServices(metaData, record);

  const allSubPanelList = getRelationsInForm(metaData, processList) ?? [];
  const classes = useStyles({
    isCreateMode: isCreateMode || allSubPanelList?.length === 0,
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const id = actorOnDispatch('loading', loadingRecord => {
      setLoading(
        loadingRecord['service'] ||
          loadingRecord['processChangeLineButtons'] ||
          loadingRecord[currentResource.value] ||
          false,
      );
    });
    onDispatchRef.current.push({ actionName: 'loading', id });

    return () => {
      for (const onDispatchData of onDispatchRef.current) {
        actorRemoveAction({
          actionName: onDispatchData.actionName,
          listenerId: onDispatchData.id,
        });
      }
    };
  }, []);

  /**
   * Redirect to specified page
   * @function handleDumbBtnClick
   * @returns void
   */
  const handleDumbBtnClick = () => {
    if (!isEmptyObject(metaData?.config)) {
      const { treeLevel } = metaData.config; // It's surely doesn't equal null or undefined

      if (!isEmpty(treeLevel)) {
        redirectToPage(`/tree/${currentResource.value}`);
        return;
      }
    }

    if (redirect == 'show') {
      redirectToPage(basePath);
    } else if (
      redirect &&
      redirect !== 'edit' &&
      redirect !== 'list' &&
      redirect !== 'false'
    ) {
      redirectToPage(redirect);
    } else if (location.hash.includes(record?.id as string)) {
      redirectToPage(basePath);
    } else {
      if (
        actorGetActionValue('urlInfo')?.location.hash.includes('fromDropdown=true')
      ) {
        window.close();
      } else {
        window.history.back();
      }
    }
  };

  /**
   * useful to prevent click bubbling in a data grid with rowClick
   * @function stopPropagations
   * @param {event}
   * @returns {void}
   */
  const stopPropagation = event => event.stopPropagation();

  /**
   * Handle 3 save type.
   * @function handleCustomSave
   * @param {SaveType} type
   * @returns {void}
   */
  const handleCustomSave = (type: SaveType) => (): void => {
    const params = {
      isSaveAndNew: false,
      isSaveAndView: false,
      formName,
    };

    switch (type) {
      case 'save':
        formActionsHandler?.(FormActions.Save, {
          ...params,
        } as ExtraParamsInterface);
        break;

      case 'saveAndNew':
        formActionsHandler?.(FormActions.Save, {
          ...params,
          isSaveAndNew: true,
        } as ExtraParamsInterface);
        break;

      case 'saveAndView':
        formActionsHandler?.(FormActions.Save, {
          ...params,
          isSaveAndView: true,
        } as ExtraParamsInterface);
        break;

      default:
        console.log(`This type ${type} is not support`);
        break;
    }
  };

  /**
   * it will make image url and call showImageDialog with it.
   * @function getProcessSchematic
   * @returns {void}
   */
  const getProcessSchematic = () => {
    showImageDialog({
      url: `${basePath}/${record.id}/process/view`,
      isGetFileMode: true,
    });
  };

  /**
   * @function handleKeyPressed
   * @returns { void } void
   */
  const handleKeyPressed = (): void => {
    saveMainButtonRef.current?.focus();
    saveMainButtonRef.current?.click();
  };

  useKeyPress(['s'], handleKeyPressed, true);

  return (
    <Toolbar
      {...rest}
      className={classes.container}
      classes={{
        spacer: classes.spacer,
        mobileToolbar: classes.mobileToolbar,
      }}
      handleSubmitWithRedirect={handleSubmitWithRedirect}
      data-action-list="true"
      data-style-toolbar-form="toolbarForm"
    >
      <CustomFormButton
        id="formMainSaveButton"
        onClick={handleCustomSave('save')}
        disabled={loading || isSaveDisabled}
        variant="contained"
        label={translate('ra.action.save')}
        buttonRef={saveMainButtonRef}
      />

      {!isSingleRecord && isCreateMode && (
        <CustomFormButton
          id="formSaveAndNewButton"
          onClick={handleCustomSave('saveAndNew')}
          disabled={loading || isSaveDisabled || !!record?.__processuniqueid}
          variant="text"
          label={translate('form.createAndNew')}
          buttonRef={buttonRef}
        />
      )}

      {processLineList?.map(processLine => (
        <ProcessLineChangeButton
          handleSubmitWithRedirect={handleSubmitWithRedirect}
          key={processLine.id}
          processLine={processLine}
          locale={locale}
          disabled={loading}
          redirect={redirect}
          record={record}
          metaData={metaData}
          isCreateMode={isCreateMode}
          resource={resource}
          redirectToPage={redirectToPage}
          editedFormData={editedFormData}
          basePath={basePath}
        />
      ))}

      {!isSingleRecord && (
        <DumbButton
          className={classes.dumbButton}
          onClick={handleDumbBtnClick}
          data-test-form-cancel-button="true"
          disabled={loading}
        >
          {translate('ra.action.cancel')}
        </DumbButton>
      )}

      {!isDefaultMode && (
        <>
          <div className={classes.grow}></div>

          {record && !!record.id && processList && processList.length > 0 && (
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={getProcessSchematic}
              id="schematicViewButton"
              disabled={loading}
            >
              <Icon>map</Icon>
            </IconButton>
          )}

          {visibleServices && record && !!record.id && (
            <ServiceButtonContainer
              locale={locale}
              resource={currentResource.value}
              selectedIds={[record.id]}
              metaData={metaData}
              initialData={[record]}
              visibleServices={visibleServices}
            />
          )}
          {record && !!record.id && (
            <PrintButtonContainer
              locale={locale}
              resource={currentResource.value}
              selectedIds={[record.id]}
              metaData={metaData}
            />
          )}

          {record && !!record.id && (
            <ReactAdminButton
              color="primary"
              component={Link}
              to={`${linkToRecord(basePath, record && record.id)}/show`}
              onClick={stopPropagation}
              disabled={loading}
              {...(rest as any)}
            >
              <Icon>visibility</Icon>
            </ReactAdminButton>
          )}
        </>
      )}
    </Toolbar>
  );
};

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
});

const mapDispatchToProps = {
  redirectToPage: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormActionButtonList);
