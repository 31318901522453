import { useEffect, useState } from 'react';
import { useLocale } from 'react-admin';
import lodashMap from 'lodash/map';
import lodashFind from 'lodash/find';

import { getAppSettings, setAppSettings } from '../../../helper/settings-helper';
import { prepareColumnsForGridFromMeta } from '../grid.helper';
import { CONFIG_LIST_COLUMN_WIDTH } from '../../../core/configProvider';
import { isEmptyObject } from '../../../helper/data-helper';

const useColumnWidth = entries => {
  const {
    resource,
    relationResource,
    idDropDown,
    relationMode,
    gridRef,
    fields,
    metaData,
  } = entries;

  const locale = useLocale();

  const [columnWidthSetting, setColumnWidthSetting] = useState<Record<
    string,
    unknown
  > | null>(null);

  useEffect(() => {
    const columnWidthSetting =
      getAppSettings<Record<string, unknown>>(getConfigColumnWidthKey(), true)
        .value ?? {};

    setColumnWidthSetting(columnWidthSetting);
  }, [resource, relationResource, idDropDown]);

  const getConfigColumnWidthKey = (): string => {
    let key = `${CONFIG_LIST_COLUMN_WIDTH}_`;
    key += relationMode ? relationResource : resource;

    if (idDropDown) {
      key += '_dropDown_' + idDropDown;
    }
    return key;
  };

  const setNewColumnsWidthToSetting = (
    columnWidthList: Record<string, number>,
  ): void => {
    const key = getConfigColumnWidthKey();

    setAppSettings({
      key,
      value: columnWidthList,
      forUser: true,
      onSuccess: () => {
        gridRef.current?.instance.endCustomLoading();
      },
    });
  };

  const onGridColumnWidthChange = event => {
    const columns = prepareColumnsForGridFromMeta({
      fields,
      metaData,
      locale,
      columnWidthSetting,
    });

    const visibleColumns = event.component?.getVisibleColumns();

    const columnWidthList: Record<string, number> = {};

    lodashMap(visibleColumns, gridColumn => {
      const columnInfo = lodashFind(columns, ['name', gridColumn.name]);
      if (!isEmptyObject(columnInfo)) {
        const columnKey = columnInfo.field?.id
          ? columnInfo.field.id
          : gridColumn.name;
        columnWidthList[columnKey] = gridColumn.width > 500 ? 500 : gridColumn.width;
      }
    });

    setNewColumnsWidthToSetting(columnWidthList);
  };

  return {
    columnWidthSetting,
    onGridColumnWidthChange,
  };
};

export default useColumnWidth;
