import { useEffect, useState } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import dxDataGrid from 'devextreme/ui/data_grid';
import momentJalali from 'moment-jalaali';
import moment from 'moment';

import { actorOnDispatch, actorRemoveAction } from '../../../type/actor-setup';
import { getAppSettings, setAppSettings } from '../../../helper/settings-helper';
import { CONFIG_GROUP_COLUMN } from '../../../core/configProvider';
import { getCalendarType } from '../../../helper/DateHelper';
import { getTypeByField, inputTypes } from '../../../helper/InputHelper';

import type { FieldType } from '../../../helper/Types';
import type { GroupColumnsInterface } from '../grid.type';

const useGrouping = entries => {
  const { isGroupingOpen, resource, gridRef } = entries;

  const translate = useTranslate();
  const locale = useLocale();

  const [isGridGroupingEnable, setIsGridGroupingEnable] = useState(isGroupingOpen);

  useEffect(() => {
    const onDispatchId = actorOnDispatch('isGridGroupingEnable', groupingEnabled => {
      if (groupingEnabled[`${resource}`] !== undefined) {
        setIsGridGroupingEnable(groupingEnabled[`${resource}`]);
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'isGridGroupingEnable',
        listenerId: onDispatchId,
      });
    };
  }, []);

  const groupColumns = getAppSettings(`${CONFIG_GROUP_COLUMN}_${resource}`, true)
    ?.value as Array<GroupColumnsInterface>;

  const setGroupGridSetting = (groupColumns: GroupColumnsInterface[]): void => {
    gridRef.current?.instance.beginCustomLoading(translate('form.sendingData'));

    setAppSettings({
      key: `${CONFIG_GROUP_COLUMN}_${resource}`,
      value: groupColumns,
      forUser: true,
      onSuccess: () => {
        gridRef.current?.instance.endCustomLoading();
      },
    });
  };

  const getGridGroupItems = (grid: dxDataGrid): GroupColumnsInterface[] => {
    const columnsOptions: GroupColumnsInterface[] = [];
    const count = grid.columnCount();
    for (let index = 0; index < count; index++) {
      const columnOptions = grid.columnOption(index);
      if (columnOptions.groupIndex !== undefined) {
        columnsOptions.push({
          columnName: columnOptions.name,
          groupIndex: columnOptions.groupIndex,
        });
      }
    }

    return columnsOptions;
  };

  const groupCellTemplate =
    (field: FieldType) =>
    (options: any): React.ReactNode => {
      const currentCalendar = getCalendarType(field?.fixCalendar);

      let finalValue = options.value;
      if (getTypeByField(field) == inputTypes.DATE_FIELD) {
        finalValue =
          currentCalendar === 'jalali'
            ? momentJalali(options.value).format('jYYYY-jMM-jDD')
            : moment(options.value).format('YYYY-MM-DD');
        finalValue = <div>{finalValue}</div>;
      }
      return (
        <>
          {field.translatedCaption?.[locale]}:{finalValue}
        </>
      );
    };

  return {
    isGridGroupingEnable,
    groupColumns,
    setGroupGridSetting,
    getGridGroupItems,
    groupCellTemplate,
  };
};

export default useGrouping;
