import { FC, forwardRef, useImperativeHandle, useMemo, useState } from 'react';

import ShowRecordWithRelationView from './relation-list.view';
import { RelationListPropsInterface } from './relation-list.type';
import { getRelationResource } from './relation-list-helper';
import { GeneralMetaData } from '../../../helper/Types';
import { isEmpty } from '../../../helper/data-helper';

const RelationList: FC<RelationListPropsInterface> = forwardRef(
  (props, forwardedRef) => {
    const { relations, parentMetaData } = props;

    const [parentActiveTabIndex, setParentActiveTabIndex] = useState<number>(0);

    const updateParentActiveTabIndex = (newTabIndex: number): void => {
      setParentActiveTabIndex(newTabIndex);
    };

    // this hook will fill forwarded ref with custom local functions
    useImperativeHandle(forwardedRef, () => ({
      onActiveTabChange: updateParentActiveTabIndex,
    }));

    const hiddenSubPanels = useMemo(() => {
      const hiddenResources: string[] = [];

      relations.forEach(relation => {
        const relationLayoutId = relation.relationItemInMetaData.layoutID;
        const activeTab = (parentMetaData as GeneralMetaData).tabPages?.[
          parentActiveTabIndex
        ];

        if (isEmpty(relationLayoutId) || !activeTab) return;

        if (!activeTab.groups.map(String).includes(String(relationLayoutId))) {
          hiddenResources.push(getRelationResource(relation));
        }
      });

      return hiddenResources.filter(resource => !!resource);
    }, [relations, parentMetaData, parentActiveTabIndex]);

    return (
      <ShowRecordWithRelationView
        relations={relations}
        hiddenSubPanels={hiddenSubPanels}
      />
    );
  },
);

export default RelationList;
