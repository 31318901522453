import { getRequiredFilters } from '../filter-form.helper';
import { Locale } from '../../../type/global-types';

import type { FieldType } from '../../../helper/Types';

/**
 * @function createFormattedFilterFields
 * @param {
 *   fields: FieldType[];
 *   resource: string;
 *   locale: string;
 * } params
 * @returns {FieldType[]} Array of fields
 */
export const createFormattedFilterFields = (params: {
  fields: FieldType[];
  resource: string;
  locale: Locale;
}): FieldType[] => {
  const { fields, resource, locale } = params;
  const isReport = resource.indexOf('report') === 0;

  const requiredFilterFields = getRequiredFilters(resource, locale);

  const formattedFields = [...fields];

  const formattedFieldsLength = formattedFields.length;
  for (let index = 0; index < formattedFieldsLength; index++) {
    const field = formattedFields[index];

    field.source = field.resource ?? field.name;
    field.disabled = false;
    field.readOnly = false;
    field.required = false; // To avoid get a error when a field is not a required filter
    field.label = field['translatedCaption']?.[locale] ?? field.caption;
    field.defaultOperator =
      field.defaultOperator?.toLowerCase() ??
      field.dataType.defaultOperator.toLowerCase();

    if (field.defaultOperator === 'empty') {
      field.defaultOperator = 'equals';
    } else if (field.defaultOperator === 'like') {
      field.defaultOperator = 'contains';
    }

    const defaultValue = isReport ? field.defaultValue : '';

    field.defaultValue = [
      field.key ?? field.name,
      field.defaultOperator,
      defaultValue ?? '', //based on Mr.gharavi this should not be applied
    ];
  }

  for (const requiredField of requiredFilterFields.fields) {
    const targetIndex = formattedFields.findIndex(
      field => field.name === requiredField.name,
    );
    if (targetIndex === -1) continue;
    formattedFields[targetIndex] = requiredField;
  }

  return formattedFields;
};
