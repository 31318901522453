import { useLocale } from 'react-admin';
import momentJalaali from 'moment-jalaali';
import Tree from 'react-d3-tree';
import { Avatar, Button, Typography } from '@material-ui/core';

import {
  gregorianDateTimeFormat,
  jalaliDateTimeFormat,
} from '../../../../../helper/CalendarMetaHelper';
import { isEmpty } from '../../../../../helper/data-helper';
import { apiUrl, apiVersion } from '../../../../../core/data-Provider.helper';
import { getValue, SESSION_ID } from '../../../../../core/configProvider';
import DoubleTickIcon from '../../../../../icon/DoubleTickIcon';
import TickIcon from '../../../../../icon/TickIcon';

import { useStyles } from './diagram-container.style';
import LoadingBox from '../../../../loading-box';

const sessionId = getValue(SESSION_ID);
const nodeSize = { x: 300, y: 250 };
const separation = { siblings: 1, nonSiblings: 2 };
const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -125 };

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
  classes,
  locale,
}) => {
  let seenDate = '';
  if (!isEmpty(nodeDatum.seendate)) {
    seenDate =
      locale === 'fa'
        ? momentJalaali(nodeDatum.seendate).format(jalaliDateTimeFormat)
        : momentJalaali(nodeDatum.seendate).format(gregorianDateTimeFormat);
  }

  return (
    <>
      {nodeDatum ? (
        <foreignObject {...foreignObjectProps}>
          <Button
            className={
              nodeDatum.children
                ? classes.buttonHasChildren
                : classes.buttonNotChildren
            }
            variant="contained"
            onClick={toggleNode}
          >
            <div className={classes.cardBoxContainer}>
              <div
                className={classes.cardColorBox}
                //for increase performance,we had to use inline css
                style={{
                  background: nodeDatum.colorcode ?? '#16E0BD',
                }}
              />
              <div className={classes.mailInfoBox}>
                <Avatar
                  src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${nodeDatum.topersonimage}`}
                  alt={nodeDatum.tocontactinfo}
                  className={classes.avatar}
                />
                <div className={classes.name}>{nodeDatum.tocontactinfo}</div>
                <div>{nodeDatum.messageparaph}</div>
                <div>
                  {locale === 'fa'
                    ? momentJalaali(nodeDatum.createdate).format(
                        jalaliDateTimeFormat,
                      )
                    : momentJalaali(nodeDatum.createdate).format(
                        gregorianDateTimeFormat,
                      )}
                </div>
                <div className={classes.attributes} title={seenDate}>
                  {!isEmpty(nodeDatum.seendate) ? <DoubleTickIcon /> : <TickIcon />}
                </div>
              </div>
            </div>
          </Button>
        </foreignObject>
      ) : (
        <>
          <LoadingBox />
        </>
      )}
    </>
  );
};

export default function DiagramContainerView(props) {
  const { data } = props;
  const locale = useLocale();
  const classes = useStyles();

  return (
    <div className={classes.containerStyles}>
      <Tree
        enableLegacyTransitions={true}
        initialDepth={1}
        data={data}
        nodeSize={nodeSize}
        separation={separation}
        pathFunc="diagonal"
        renderCustomNodeElement={rd3tProps =>
          renderForeignObjectNode({
            ...rd3tProps,
            foreignObjectProps,
            classes,
            locale,
          })
        }
        orientation="vertical"
      />
    </div>
  );
}
