import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  type ChangeEvent,
  type FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslate, useLocale } from 'react-admin';
import {
  generateRandomString,
  isEmpty,
  isEmptyAndNotEqualToNull,
  isEmptyObject,
} from '../../helper/data-helper';
import {
  getModeList,
  HIDE_FILTER,
  NO_FILTER_OPERATOR,
  onlyEqualModeList,
} from '../../helper/FilterHelper';
import {
  BOOLEAN_FIELD,
  DATE_FIELD,
  DATETIME_FIELD,
  DECIMAL_FIELD,
  DROPDOWN_FIELD,
  DROP_BASE_MULTI_SELECT_FIELD,
  getTypeByField,
  NUMBER_FIELD,
  COLOR_SELECT_FIELD,
  STRING_SELECT_FIELD,
  STRING_MULTI_SELECT_FIELD,
} from '../../helper/InputHelper';
import {
  actorSetActionValue,
  type FilterFormFieldInterface,
} from '../../type/actor-setup';

import BooleanFilterInput from './BooleanFilterInput';
import DateFilterInput from './DateFilterInput';
import DropdownFilterInput from './DropdownFilterInput';
import NumberFilterInput from './NumberFilterInput';
import TextFilterInput from './TextFilterInput';
import { AutocompleteFilterInput } from './autocomplete-filter-input';
import {
  SingleSelectFilterInput,
  SingleSelectFilterInputControllerProps,
} from './single-select-filter-input';
import { ColorSelectFilterInput } from './color-select-filter-input';

import type { FieldType, ModeItemInterface } from '../../helper/Types';
import type { FilterItemBaseType } from '../filter-form';

const useStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexBasis: '100%',
    position: 'static',
    border: '1px solid #BDBDBD',
    borderRadius: theme.shape.borderRadius,
    padding: '1px 5px',
    margin: '5px 7px',
    '&:last-of-type': {
      marginInlineEnd: 'auto',
    },
  },

  fieldsetError: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  legend: {
    lineHeight: '11px',
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
    width: 'auto',
    marginBottom: 0,
    borderBottom: 0,
  },

  legendError: {
    width: 'auto',
    lineHeight: '11px',
    fontSize: 12,
    color: theme.palette.error.main,
  },

  selectOperatorContainer: {
    '& .MuiFormControl-root': {
      minWidth: 'initial',
    },

    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },

  select: {
    margin: '0 3px',
    '&:before': {
      border: 'none',
    },

    '&:after': {
      border: 'none',
    },
  },

  selectRoot: {
    fontSize: 12,
  },

  selectMenu: {
    padding: 0,
    paddingRight: 32,
  },

  divider: {
    border: `1px solid ${theme.palette.divider}`,
    height: 25,
  },

  closeFilterIcon: {
    fontSize: 16,
    margin: '0 5px',
    color: 'rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
  },

  menuItem: {
    fontSize: 12,
    padding: 5,
  },

  // to silence warnings, set empty classes
  form: {},
  body: {},
  icon: {},

  disabledFilter: {
    '& input': {
      backgroundColor: theme.palette.grey[300],
    },
    backgroundColor: theme.palette.grey[300],
  },

  activeOperators: {
    '& > div': {
      backgroundColor: '#ffffff',
    },
  },
}));

interface DynamicFilterInputInterface {
  field: FieldType;
  handleHide: Function;
  resource: string;
  onChange?: Function;
  onBlur?: Function;
  disabled?: boolean;
  value: FilterItemBaseType;
  filterData: Record<string, FilterFormFieldInterface> | undefined;
}

const DynamicFilterInput: FC<DynamicFilterInputInterface> = props => {
  // prettier-ignore
  const { handleHide, field, resource, onChange, value, filterData, ...rest } = props;

  const classes = useStyle(props);
  const translate = useTranslate();

  const {
    dropdown,
    name: fieldName,
    required,
    label,
    onlyEqualCondition,
    defaultOperator,
    translatedCaption,
    caption,
  } = field;

  const modeList: ModeItemInterface[] = useMemo(
    () => (onlyEqualCondition ? onlyEqualModeList : getModeList(field)),
    [fieldName],
  );

  /**
   * If the "defaultOperator" has a value and the "onlyEqualCondition" does not have a value,
   * then the "operator" value is equal to the "defaultOperator" value. Otherwise, it will be set to "on"
   */
  const [operator, setOperator] = useState<string>('');

  const [firstInputValue, setFirstInputValue] = useState<unknown>();
  const [secondInputValue, setSecondInputValue] = useState<unknown>();
  const [hasError, setHasError] = useState(required);
  const [disabled, setDisabled] = useState(false);

  const modeInfoRef = useRef<ModeItemInterface>({
    name: '',
    operator,
    both: false,
  });
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isReport = resource.indexOf('report') === 0;
  const isMultiReport = resource.indexOf('multi-report') === 0;

  const locale = useLocale();

  useEffect(() => {
    setDisabled(false);
    if (!Array.isArray(value)) return;

    const [, currentOperator, currentValue] = value;

    if (isEmpty(currentOperator)) {
      const _operator =
        defaultOperator && !onlyEqualCondition
          ? defaultOperator.toLowerCase() // Equals => equals, Between => between
          : modeList[0].operator;

      setOperatorMode(_operator);
    } else {
      if (currentOperator === 'like') {
        setOperatorMode('contains');
      } else {
        // `null` value is a valid value when we did choose `noValue` operator
        if (currentOperator === 'noValue' && currentValue === null) {
          setDisabled(true);

          clearFilterValue(null);
          setOperatorMode(NO_FILTER_OPERATOR.operator);
        } else {
          setOperatorMode(currentOperator);
        }
      }
    }

    if (isEmpty(currentValue)) {
      clearFilterValue();
      return;
    }

    if (currentOperator === 'between') {
      const [firstValue, secondValue] = (currentValue as string).split(',');

      //filter=[["createdate","between","2024-02-22 000:00:00","2024-02-22 23:59:59"]]
      //this filter means user selected "=" for operator
      if (
        firstValue.includes('00:00:00') &&
        secondValue.includes('23:59:59') &&
        firstValue.split(' ')[0] == secondValue.split(' ')[0]
      ) {
        setFirstInputValue(firstValue.split(' ')[0]);
        setOperatorMode('=');
        return;
      }

      setFirstInputValue(firstValue);
      setSecondInputValue(secondValue);
    } else {
      setFirstInputValue(currentValue);
    }
  }, [value]);

  useEffect(() => {
    if (modeInfoRef.current.both) {
      let value = '';
      if (
        !isEmptyAndNotEqualToNull(firstInputValue) &&
        !isEmptyAndNotEqualToNull(secondInputValue)
      ) {
        value = `${firstInputValue},${secondInputValue}`;
      }

      onChange?.(field, value, modeInfoRef.current.operator);
    } else {
      onChange?.(field, firstInputValue, modeInfoRef.current.operator);
    }
  }, [firstInputValue]);

  useEffect(() => {
    if (modeInfoRef.current.both) {
      let value = '';
      if (
        !isEmptyAndNotEqualToNull(firstInputValue) &&
        !isEmptyAndNotEqualToNull(secondInputValue)
      ) {
        value = `${firstInputValue},${secondInputValue}`;
      }

      onChange?.(field, value, modeInfoRef.current.operator);
    } else {
      onChange?.(field, secondInputValue, modeInfoRef.current.operator);
    }
  }, [secondInputValue]);

  useEffect(() => {
    notifyChange();

    // WillUpdate
    return () => {
      if (operator === NO_FILTER_OPERATOR.operator) {
        setFirstInputValue('');
        setSecondInputValue('');
      }
    };
  }, [operator]);

  const getRef = useCallback((ref: HTMLElement | null) => {
    if (ref == null) return;

    if (['searchDialog', 'dropdown'].includes(field?.dataType?.erp)) {
      inputRef.current = ref as HTMLInputElement;
      return;
    }

    if (ref['input'] != null) {
      // DatePicker component
      inputRef.current = ref['input'];
    } else if (ref['open']) {
      // Dropdown component
      inputRef.current = ref['open'];
    } else {
      inputRef.current = ref.querySelector?.('input');
    }
  }, []);

  const setOperatorMode = useCallback((operator: string) => {
    const targetMode =
      modeList.find(
        mode => mode.operator === operator || mode.name.toLowerCase() === operator,
      ) ?? modeList[0];

    if (targetMode) {
      modeInfoRef.current = targetMode;
      setOperator(targetMode.operator);
    }
  }, []);

  /**
   * `select` HTML tag trigger this function when change the mode item.
   * @function handleModeChange
   * @param event
   * @returns {void}
   */
  const handleModeChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ): void => {
    const operator = event.target.value;

    setDisabled(false);

    if (operator === HIDE_FILTER) {
      handleHide(field);
      return;
    }

    if (operator === NO_FILTER_OPERATOR.operator) {
      setFirstInputValue(null);

      if (modeInfoRef.current.both) {
        setSecondInputValue(null);
      }

      setOperator(NO_FILTER_OPERATOR.operator);
      setOperatorMode(NO_FILTER_OPERATOR.operator);
      setDisabled(true);
      return;
    }

    if (typeof operator === 'string') {
      setOperator(operator);
      setOperatorMode(operator);
    }
  };

  /**
   * Check `modeInfo` and create `params` for run input `onChange`.
   * @function notifyChange
   * @returns {void}
   */
  const notifyChange = (): void => {
    // no ready to notify parent form
    if (
      !modeInfoRef.current ||
      (!modeInfoRef.current.both && isEmpty(firstInputValue)) ||
      (modeInfoRef.current.both &&
        (isEmpty(firstInputValue) || isEmpty(secondInputValue)))
    ) {
      return;
    }

    let finalValue = firstInputValue;
    if (modeInfoRef.current.both) {
      finalValue = `${firstInputValue},${secondInputValue}`;
    }

    onChange?.(field, finalValue, modeInfoRef.current.operator);
  };

  const firstInputChangeHandler = useCallback((value: unknown) => {
    setFirstInputValue(value);
  }, []);

  const secondInputChangeHandler = useCallback((value: unknown) => {
    setSecondInputValue(value);
  }, []);

  /**
   * Set error for twin inputs.
   * @function handleTwinInputsError
   * @param {boolean} shouldShowError
   * @returns {void}
   */
  const handleTwinInputsError = (shouldShowError: boolean): void => {
    // should show error base of both inputs value
    const hasEmptyInput = isEmpty(firstInputValue) || isEmpty(secondInputValue);

    if (shouldShowError && field && required && hasEmptyInput) {
      if (hasError === false) {
        setHasError(true);
      }
    } else {
      // should not show Error at all because its not report or multi report
      if (hasError === true) {
        setHasError(false);
      }
    }
  };

  /**
   * Set error for a single input.
   * @function handleSingleInputError
   * @param {boolean} shouldShowError
   * @param {string | number | null} value
   * @returns {void}
   */
  const handleSingleInputError = (
    shouldShowError: boolean,
    value: string | number | null,
  ): void => {
    // should show error base of single input value
    if (shouldShowError && field && required && isEmpty(value)) {
      if (hasError === false) {
        setHasError(true);
      }
    } else {
      // should not show Error at all because its not report or multi report
      if (hasError === true) {
        setHasError(false);
      }
    }
  };

  /**
   * Filters validation check only required fields.
   * So here should set hasError state to 'true' to show required error on the rendered input.
   * It will only set state to 'true' when resource be a report or multi report.
   * @function handleInputError
   * @param {boolean} twinInputs
   * @param {string | number | null} value
   * @returns {void}
   */
  const handleInputError = (twinInputs, value) => {
    const isReport = resource.indexOf('report') === 0;
    const isMultiReport = resource.indexOf('multi-report') === 0;
    const isPermissionPage = resource.indexOf('permission/show') === 0;
    const isAdvancedPermissionPage =
      resource.indexOf('advance/permission/show') === 0;

    const shouldShowError =
      isReport || isMultiReport || isPermissionPage || isAdvancedPermissionPage;

    if (twinInputs) {
      handleTwinInputsError(shouldShowError);
    } else {
      handleSingleInputError(shouldShowError, value);
    }
  };

  /**
   * This function decide which type of input should be render based on field type.
   * Also should check the value and required param in field and set the `hasError` state if needed.
   * Set `hasError` state depends on `twinInputs` prop. if it was twin inputs, it should check
   * both input1 and input2 value for setting state. Otherwise, it should only check current input value.
   * @function renderInput
   * @param {string|null} value - Value in input
   * @param {function} onChange
   * @param {boolean} twinInputs
   * @returns {JSX.Element} - An input to render
   */
  const renderInput = (
    value: unknown,
    onChange: Function | undefined,
    twinInputs: boolean,
  ): JSX.Element => {
    const params = {
      ...rest,
      field,
      value,
      onChange,
      inputRef,
      getRef,
    };

    handleInputError(twinInputs, value);

    switch (getTypeByField(field)) {
      case DATE_FIELD:
        return (
          <DateFilterInput
            {...params}
            disabled={disabled}
            hastimepicker={false}
            key={generateRandomString()}
          />
        );
      case DATETIME_FIELD: {
        return (
          <DateFilterInput
            {...params}
            disabled={disabled}
            hastimepicker={false} //temporary disable time chooser
            key={generateRandomString()}
          />
        );
      }

      case BOOLEAN_FIELD:
        return <BooleanFilterInput {...params} disabled={disabled} />;

      case DROPDOWN_FIELD: {
        if (isEmptyObject(dropdown)) return <></>;
        if (dropdown.filterMultiSelect) {
          return (
            <AutocompleteFilterInput
              {...params}
              field={params.field}
              disabled={disabled ?? false}
              onChange={onChange}
              value={String(value ?? '')}
            />
          );
        }
        return (
          <DropdownFilterInput
            {...params}
            value={value}
            dropdownMeta={dropdown}
            parentResource={resource}
            disabled={disabled}
            filterData={filterData}
          />
        );
      }

      case DROP_BASE_MULTI_SELECT_FIELD:
        return (
          <AutocompleteFilterInput
            {...params}
            field={params.field}
            disabled={disabled ?? false}
            onChange={onChange}
            value={String(value ?? '')}
          />
        );

      case NUMBER_FIELD:
      case DECIMAL_FIELD:
        return (
          <NumberFilterInput
            {...params}
            value={value as string | number}
            disabled={disabled}
            key={field.id}
          />
        );
      case COLOR_SELECT_FIELD:
        return (
          <ColorSelectFilterInput
            {...params}
            value={value as number | undefined}
            field={field}
            disabled={disabled}
            onChange={onChange!}
          />
        );

      case STRING_SELECT_FIELD:
      case STRING_MULTI_SELECT_FIELD:
        return (
          <SingleSelectFilterInput
            {...(params as unknown as SingleSelectFilterInputControllerProps)}
            disabled={disabled}
          />
        );

      default:
        return (
          <TextFilterInput
            {...params}
            value={value as string}
            disabled={disabled}
            key={field.id}
          />
        );
    }
  };

  /**
   * Clear filter value.
   * @function clearFilterValue
   * @param { unknown } value // Maybe string or number or null etc...
   * @returns {void}
   */
  const clearFilterValue = useCallback((value: unknown = ''): void => {
    firstInputChangeHandler(value);
    secondInputChangeHandler(value);
  }, []);

  return (
    <fieldset
      className={`${classes.container} ${
        hasError && !disabled ? classes.fieldsetError : null
      } ${disabled ? classes.disabledFilter : null}`}
      data-test-has-error={hasError}
      data-test-field-name={fieldName}
      data-style-filter-field="filterField"
    >
      <legend
        className={`${hasError && !disabled ? classes.legendError : classes.legend}`}
        onDoubleClick={clearFilterValue}
        data-style-legend-field="legendField"
      >
        {label ?? translatedCaption?.[locale] ?? caption}
        {(isReport || isMultiReport) && required ? '*' : ''}
      </legend>
      <div className={classes.selectOperatorContainer}>
        <FormControl>
          <Select
            id={`operatorInput${fieldName}`}
            className={`${classes.select} ${
              disabled ? classes.activeOperators : null
            }`}
            value={operator}
            onChange={handleModeChange}
            inputProps={{
              name: 'operator',
              id: 'operatorInput' + fieldName,
            }}
            classes={{
              root: classes.selectRoot,
              selectMenu: classes.selectMenu,
            }}
          >
            {(isReport || isMultiReport) && required ? null : (
              <MenuItem
                value={HIDE_FILTER}
                className={classes.menuItem}
                data-test-filter-operator-item={HIDE_FILTER}
              >
                {translate('ra.action.remove_filter')}
              </MenuItem>
            )}
            {modeList &&
              modeList.map(item => (
                <MenuItem
                  key={item.name}
                  value={item.operator}
                  className={classes.menuItem}
                  data-test-filter-operator-item={item.operator}
                >
                  {translate(`filter.mode.${item.name}`)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {renderInput(
          firstInputValue,
          firstInputChangeHandler,
          Boolean(modeInfoRef.current.both),
        )}

        {modeInfoRef.current && modeInfoRef.current.both && (
          <Fragment>
            <span className={classes.divider} />
            {renderInput(
              secondInputValue,
              secondInputChangeHandler,
              Boolean(modeInfoRef.current.both),
            )}
          </Fragment>
        )}
      </div>
      {hasError && !disabled && (
        <Typography variant="caption" color="error">
          {translate('ra.validation.required')}
        </Typography>
      )}
    </fieldset>
  );
};

export default DynamicFilterInput;
