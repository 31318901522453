import { Fragment, useState, useEffect, useRef } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import { useTranslate, setListSelectedIds, refreshView } from 'react-admin';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import lodashMerge from 'lodash/merge';

import { getParameterName, getServices } from '../helper/MetaHelper';
import ServiceButton from './ServiceButton';
import { RUN_SERVICE } from '../core/data-Provider.helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
} from '../type/actor-setup';
import { clone, isEmpty, isEmptyObject } from '../helper/data-helper';
import { setResource } from '../helper/resource-helper';
import { ServiceDialogDataItem } from '../component/dialogs-stack';
import { ApiResponseWithValidationErrors } from '../component/form';
import { FieldType, MetaData } from '../helper/Types';
import { handleServerSideValidationErrors } from '../helper/validation-helper';
import { showNotification } from '../helper/general-function-helper';
import dataProvider from '../core/dataProvider';

const useStyles = makeStyles(theme => ({
  IconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },

  icon: {
    fontSize: 20,
  },
}));

interface ErrorType {
  data: Record<string, unknown>;
  requestId: string;
}

export interface SelectedServiceInterface {
  uniqueId: string;
  manualExecute: boolean;
}

const ServiceButtonContainer = props => {
  const {
    locale,
    metaData,
    resource,
    button,
    resourcesData,
    setListSelectedIds,
    customRefresh,
    refreshView,
    customAnchorEl,
    withoutButton,
    customEmptyState,
    selectedIds,
    relationMode,
    visibleServices,
    rootResource,
  } = props;

  const translate = useTranslate();
  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedService, setSelectedService] =
    useState<SelectedServiceInterface | null>(null);
  const [serviceParams, setServiceParams] = useState({});
  const [loading, setLoading] = useState(false);

  const onDispatchRefs = useRef<{ [name: string]: symbol }>({});
  /**
   * this ref is for the context menu services on each row in grid check this function in grid controller : gridContextMenu
   * this ref doesn't effect any thing in this component because after run service we are turning it null again
   */
  const targetSelectedIdRef = useRef<number | null>(null);
  const tooltipRef = useRef<any>();

  const setEmptyState = () => {
    setAnchorEl(null);
    setLoading(false);
    removeServiceResource(selectedService?.uniqueId as string | undefined);
    setSelectedService(null);
    setServiceParams({});
    actorDispatch('selectedService', {
      service: null,
    });
  };

  useEffect(() => {
    customAnchorEl && setAnchorEl(customAnchorEl);
  }, [customAnchorEl]);

  useEffect(() => {
    onDispatchRefs.current['runServiceDirectly'] = actorOnDispatch(
      'runServiceDirectly',
      detail => {
        if (detail.service) {
          selectServiceAndOpenDialog(
            detail.service,
            detail.serviceParams,
            detail.targetSelectedId,
          );
        }
      },
      { preserve: false },
    );

    onDispatchRefs.current['activeTab'] = actorOnDispatch(
      'activeTab',
      activeTabs => {
        const isMultiResult =
          (actorGetActionValue('metaData', resource) as unknown as MetaData)?.[
            'reportType'
          ] === 'MultiResult';

        if (isMultiResult && tooltipRef.current) {
          tooltipRef.current.style.display = activeTabs[resource] ? 'none' : 'block';
        }
      },
    );

    onDispatchRefs.current['loading'] = actorOnDispatch('loading', loadingRecord => {
      setLoading(
        loadingRecord['processChangeLineButtons'] ||
          loadingRecord['service'] ||
          loadingRecord[resource] ||
          false,
      );
    });

    return () => {
      Object.keys(onDispatchRefs.current).forEach(key => {
        actorRemoveAction({
          actionName: key as keyof ActorActionList,
          listenerId: onDispatchRefs.current[key],
        });
      });
    };
  }, [resource]);

  /**
   * onSuccess for `getServiceDefaultValue` dispatch.
   * @function onSuccess
   * @param {Record<string, unknown>} params
   * @param {Record<string, unknown>} service
   * @returns {void}
   */
  const onSuccess = (
    params: Record<string, unknown>,
    service: Record<string, unknown>,
  ): void => {
    if (!isEmpty(selectedService?.uniqueId)) {
      actorSetActionValue('inputsRef', null, {
        path: `action/${selectedService!.uniqueId}.${FormKeyMode.SERVICE}`,
        callerScopeName: 'onSuccess',
      });
    }

    openDialog(params, service);
  };

  /**
   * onFailure for `getServiceDefaultValue` dispatch.
   * @function onFailure
   * @param {unknown} error
   * @param {Record<string, unknown>} service
   * @returns {void}
   */
  const onFailure = (error: unknown, service: Record<string, unknown>): void => {
    console.log('ServiceButtonContainer.tsx:94 >> error', { error });
    openDialog(serviceParams, service);
  };

  /**
   * Open quick dialog with `actorDispatch`.
   * @function openDialog
   * @param {Record<string, unknown>} serviceParams
   * @param {Record<string, unknown>} service
   * @returns {void}
   */
  const openDialog = (
    serviceParams: Record<string, unknown>,
    service: Record<string, unknown>,
  ): void => {
    actorDispatch(
      'quickDialog',
      {
        serviceIsOpen: true,
        onCloseDialogCallback: setEmptyState,
        data: {
          data: {
            locale,
            service,
            isSending: loading,
            serviceParams: { ...serviceParams, customRefresh },
            parentResource: resource,
            runService,
          } as ServiceDialogDataItem,
        },
      },
      {
        replaceAll: true,
      },
    );
  };

  /**
   * Select `service` and set `parameter` open dialog if service is manual execute.
   * @function selectServiceAndOpenDialog
   * @param {Object} service
   * @param {Record<string,unknown>} _serviceParams
   * @param {number | undefined} targetSelectedId
   * @returns {Void}
   */
  const selectServiceAndOpenDialog = (
    service,
    _serviceParams = serviceParams,
    targetSelectedId?: number,
  ) => {
    setSelectedService(service);
    actorDispatch('selectedService', {
      service: service,
    });
    closeMenu();

    let currentSelectedRow = {};

    const gridData = actorGetActionValue('gridData', resource);

    targetSelectedIdRef.current = targetSelectedId ?? selectedIds?.[0];

    if (relationMode) {
      const selectedRow = (
        gridData?.data as Array<Record<string, unknown>> | undefined
      )?.find(row => row.id == targetSelectedIdRef.current) as Record<
        string,
        unknown
      > | null;

      if (selectedRow) {
        currentSelectedRow = selectedRow;
      }
    } else {
      const selectedRowInActor = actorGetActionValue(
        'record',
        `${resource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
      ) as Record<string, unknown> | null;

      if (
        targetSelectedIdRef.current !== null &&
        resourcesData[targetSelectedIdRef.current]
      ) {
        currentSelectedRow = resourcesData[targetSelectedIdRef.current];
      }

      const selectedFirstRecord = (
        gridData?.data as Array<Record<string, unknown>>
      )?.find(item => item.id == targetSelectedIdRef.current);

      if (!isEmptyObject(selectedFirstRecord)) {
        currentSelectedRow = selectedFirstRecord!;
      }

      if (!isEmptyObject(selectedRowInActor)) {
        currentSelectedRow = {
          ...currentSelectedRow,
          ...selectedRowInActor!,
        };
      }
    }

    //when selectedIds.length ===1,we should map params from selected row
    if (
      service.parameters &&
      service.parameters.length &&
      (selectedIds.length === 1 || targetSelectedIdRef.current != null)
    ) {
      // each parameter has a field object, which "field.relatedParameterName", must be filled with selected row's "field.name"
      service.parameters.forEach(({ field }) => {
        _serviceParams[getParameterName(field)] =
          currentSelectedRow?.[lodashGet(field, 'name')];
      });
    }

    if (service.manualExecute) {
      setResource(resource, FormKeyMode.SERVICE);

      actorDispatch(
        'getServiceDefaultValue',
        {
          service,
          params: { ...currentSelectedRow, ..._serviceParams },
          onSuccess,
          onFailure,
        },
        {
          replaceAll: true,
        },
      );
    }
    setResource(`action/${service.uniqueId}`, FormKeyMode.SERVICE);
  };

  useEffect(() => {
    if (selectedService && !selectedService.manualExecute) {
      // running service needs it's params
      runService(serviceParams);
    }
  }, [selectedService, serviceParams]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    customEmptyState?.();
  };

  /**
   *@function removeServiceResource
   *@param {uniqueId} string | undefined
   *@return {void}
   */
  const removeServiceResource = (uniqueId: string | undefined) => {
    actorDispatch('remove', {
      resource: `action/${uniqueId}`,
      type: FormKeyMode.SERVICE,
    });
  };

  /**
   * Handle error after service runs.
   * @function handleServiceError
   * @param {ErrorType} error
   * @returns {void}
   */
  const handleServiceError = (error: ErrorType): void => {
    const currentResource = clone(actorGetActionValue('resources')!.current);
    const allFields = clone(
      actorGetActionValue('allFields', [
        currentResource.value,
        currentResource.type,
      ])! as unknown as Array<FieldType>,
    );

    // We sure that here we always have `formData` and `initialData` already
    const formData = clone(
      (actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null) ?? {},
    );
    const preparedApiErrorObject = {
      apiErrors: error?.data,
      requestId: error?.requestId,
    } as ApiResponseWithValidationErrors;
    handleServerSideValidationErrors(
      metaData,
      allFields,
      preparedApiErrorObject,
      formData,
      showNotification,
      translate,
      locale,
    );
  };

  const prepareItems = () => {
    let finalResource: string = resource;
    if (metaData?.reportType === 'MultiResult') {
      const activeTab = actorGetActionValue('activeTab', resource) ?? 0;
      finalResource = `${resource}/${activeTab}`;
    }

    const gridData = actorGetActionValue('gridData', finalResource);
    const currentRecord = actorGetActionValue(
      'record',
      `${resource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
    );
    const items = selectedIds?.map(id => {
      if (!isEmptyObject(resourcesData?.[id])) {
        return resourcesData[id];
      }

      const recordInGrid = (gridData?.data as Array<Record<string, unknown>>)?.find(
        item => item.id == id,
      );
      if (!isEmptyObject(gridData?.data) && !isEmptyObject(recordInGrid)) {
        return recordInGrid;
      }

      if (currentRecord?.id === id) {
        return currentRecord;
      }
    });

    return items;
  };

  /**
   * this function check service and prepare param for run service.
   * @param {object} params
   * @returns {void}
   */
  const runService = async (params = {}) => {
    const isReport = resource.indexOf('report') === 0;
    const [serviceModuleName, serviceModuleTableName] = resource.split('/');

    let serviceParameter: object = {
      actionUniqueId: selectedService ? selectedService.uniqueId : null,
      data: {
        params:
          lodashGet(selectedService, 'related') === 'SingleRecord'
            ? lodashMerge({ id: targetSelectedIdRef.current }, params)
            : params,
        items: prepareItems(),
      },
    };
    targetSelectedIdRef.current = null;

    if (isReport) {
      serviceParameter = {
        ...serviceParameter,
        reportId: serviceModuleTableName,
      };
    } else {
      serviceParameter = {
        ...serviceParameter,
        serviceModuleName,
        serviceModuleTableName,
      };
    }

    try {
      actorDispatch('loading', true, { path: 'service' });

      const response = await dataProvider(RUN_SERVICE, resource, serviceParameter);
      const message = lodashGet(response, 'userMessage');
      if (response?.actionOutput?.additionalData) {
        actorDispatch('quickDialog', {
          dynamicDialogIsOpen: true,
          data: {
            response,
            serviceParameter,
          },
        });
      }
      showNotification(!isEmpty(message) ? message : 'service.success'); // show success notification

      setListSelectedIds(resource, []); // clear grid selection

      if (typeof customRefresh === 'function') {
        customRefresh();
        // TODO: maybe need to refresh all relations
        // refreshRelation();
      } else {
        refreshView();
        // TODO: maybe need to refresh all relations
        // refreshRelation();
      }
      actorDispatch('refreshView', 'all', {
        disableDebounce: true,
      });

      setEmptyState();
    } catch (error: any) {
      if (error?.['data']) {
        handleServiceError(error);
      } else if (error && !error?.['data']) {
        showNotification(error, 'error');
      }

      if (!selectedService?.manualExecute) {
        setEmptyState();
      }
    } finally {
      actorDispatch('loading', false, { path: 'service' });
    }
  };

  const serviceList = visibleServices ?? getServices(metaData);

  const activeTab = actorGetActionValue('activeTab', resource) ?? 0;
  const forceDisable = metaData?.reportType === 'MultiResult' && activeTab != 0;

  if (isEmptyObject(serviceList)) {
    return null;
  }

  return (
    <Fragment>
      {!withoutButton && (
        <>
          {button ? (
            <Button onClick={handleClick} color="primary" disabled={loading}>
              <Icon>flash_on</Icon>
              {translate('grid.services')}
            </Button>
          ) : (
            <Tooltip title={translate('grid.services')} ref={tooltipRef}>
              <IconButton
                className={classes.IconButton}
                onClick={handleClick}
                color="primary"
                id="servicesButtonId"
                disabled={loading}
              >
                <Icon className={classNames(classes.icon)}>flash_on</Icon>
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      <Menu
        id="service-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        onMouseDown={closeMenu}
        onContextMenu={e => e.preventDefault()}
        variant="menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {serviceList.map(service => (
          <ServiceButton
            key={service.id || service.uniqueId}
            service={service}
            locale={locale}
            selectedIds={selectedIds}
            onClick={selectServiceAndOpenDialog}
            forceDisable={forceDisable}
          />
        ))}
      </Menu>
    </Fragment>
  );
};

const mapStateToProps = (state, { resource, initialData }) => {
  const resourcesData = lodashGet(state, ['admin', 'resources', resource, 'data']);
  const prepareInitialData = {};
  if (initialData && initialData.length) {
    for (const row of initialData) {
      prepareInitialData[row.id] = row;
    }
  }

  return {
    resourcesData: !isEmptyObject(resourcesData)
      ? resourcesData
      : prepareInitialData,
  };
};

const mapDispatchToProps = {
  setListSelectedIds,
  refreshView,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceButtonContainer);
