import { getAppSettings } from '../../../helper/settings-helper';
import { CONFIG_FIXED_COLUMNS } from '../../../core/configProvider';

import type { WebSettingFixedColumnsInterface } from '../grid.type';

const useFixedColumns = entries => {
  const { resource } = entries;

  const fixedColumns = getAppSettings(`${CONFIG_FIXED_COLUMNS}_${resource}`, true)
    ?.value as WebSettingFixedColumnsInterface[];

  return {
    fixedColumns,
  };
};

export default useFixedColumns;
