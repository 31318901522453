import { useCallback, useEffect } from 'react';
import lodashDebounce from 'lodash/debounce';
import { isGridInlineEditable } from '../../../helper/MetaHelper';
import { FieldType, GeneralMetaData } from '../../../helper/Types';
import { isEmptyObject } from '../../../helper/data-helper';
import {
  FormKeyMode,
  actorDispatch,
  actorGetActionValue,
} from '../../../type/actor-setup';
import { extractAllFieldsFromTabList } from '../../form/form.helper';
import { prepareViewFields } from '../../show-record-with-relation/show-record-with-relation.helper';

const useDataPreparation = entries => {
  const {
    allowAddInGrid,
    metaData,
    resource,
    preparedFieldsRef,
    ids,
    data,
    selectedRows,
    gridRef,
  } = entries;

  useEffect(() => {
    prepareAllFields();
  }, []);

  const preparedIdsFromData = data?.map?.(item => item?.id);

  useEffect(() => {
    actorDispatch('gridIDs', ids ?? preparedIdsFromData, {
      path: `${resource}.allIDs`,
    });

    selectRowsByDebounce(selectedRows);
  }, [ids, preparedIdsFromData]);

  const selectRowsByDebounce = useCallback(
    lodashDebounce((selectedIds: number[]) => {
      gridRef.current?.instance.selectRows(selectedIds, false);
    }, 1000),
    [],
  );

  async function prepareAllFields(): Promise<void> {
    if (!allowAddInGrid() && !isGridInlineEditable(metaData)) {
      return;
    }

    const record = actorGetActionValue(
      'record',
      `${resource}.${FormKeyMode.ROOT}`,
    )! as {
      FORM?: Record<string, unknown>;
      PARENT_RECORD?: Record<string, unknown>;
      FULL?: Record<string, unknown>;
    };

    const tabList = prepareViewFields(
      record?.FORM ?? {},
      metaData as GeneralMetaData,
    );

    const allFields: Array<FieldType> = extractAllFieldsFromTabList(tabList);

    preparedFieldsRef.current = { allFields };
  }

  function getPreparedRows(): Record<string, unknown>[] {
    if (isEmptyObject(data)) return [];

    const dataArray = Object.values(data);

    return ids && ids.filter(item => item).length
      ? ids.map(id => dataArray.find(item => item?.['id'] === id))
      : (Object.values(data) as Array<Record<string, unknown>>);
  }

  return {
    prepareAllFields,
    getPreparedRows,
  };
};

export default useDataPreparation;
