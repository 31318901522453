import lodashGet from 'lodash/get';

import {
  formatNumber,
  wrapNegativeNumberWithParentheses,
} from '../../dynamic-input/number-input/number-text-field/number-text-field.helper';

import type { TotalSummaryItemsInterface } from '../grid.type';
import { FieldType } from '@web/dropdown';
import { useEffect, useRef } from 'react';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
} from '../../../type/actor-setup';
import { isEmptyObject } from '../../../helper/data-helper';

const useSummery = entries => {
  const { fields, updatedResourceRef, resource, summaryData, data, gridRef } =
    entries;

  const summaryDataRef = useRef<Record<string, unknown>>({});

  useEffect(() => {
    const onDispatchId = actorOnDispatch(
      'additionalData',
      data => {
        summaryDataRef.current = data?.[updatedResourceRef.current]?.summary ?? {};
      },
      { preserve: false },
    );

    summaryDataRef.current =
      actorGetActionValue('additionalData', resource)?.summary ?? {};

    return () => {
      actorRemoveAction({
        actionName: 'additionalData',
        listenerId: onDispatchId,
      });
    };
  }, []);

  useEffect(() => {
    if (isEmptyObject(summaryData)) return;

    actorSetActionValue('additionalData', data.additionalData, {
      path: resource,
    });

    summaryDataRef.current = summaryData!;

    if (gridRef.current) {
      gridRef.current.instance.repaint();
      gridRef.current.instance.endCustomLoading();
    }
  }, [summaryData]);

  const formatTotalSummaryGroupItem = (value: string): string => {
    return String(wrapNegativeNumberWithParentheses(formatNumber(Number(value))));
  };

  const formatTotalSummaryCell = (columnName: string): string => {
    let summaryValue = lodashGet(summaryDataRef.current, columnName, 0); //typescript error
    summaryValue = formatNumber(summaryValue?.toFixed(2));
    return String(wrapNegativeNumberWithParentheses(summaryValue));
  };

  const getTotalSummaryItems = (): TotalSummaryItemsInterface[] => {
    const tempTotalSummaryItems: TotalSummaryItemsInterface[] = [];
    fields.forEach((field: FieldType) => {
      if (!field) {
        return;
      }

      if (field.hasSummary) {
        tempTotalSummaryItems.push({
          columnName: String(field.name),
          type: 'sum',
          format: field.format,
        });
      }
    });
    return tempTotalSummaryItems;
  };

  return {
    summaryDataRef,
    formatTotalSummaryGroupItem,
    formatTotalSummaryCell,
    getTotalSummaryItems,
  };
};

export default useSummery;
