import lodashGet from 'lodash/get';

import {
  findRowStateColorField,
  getColorById,
} from '../../../helper/RowColorHelper';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';
import { useStyles } from '../grid.style';
import { useEffect } from 'react';

const useCustomStyles = entries => {
  const { metaData, relationMode, getPreparedRows, gridRef } = entries;
  const classes = useStyles();

  useEffect(() => {
    const drawerOnDispatchId = actorOnDispatch(
      'isDrawerOpen',
      isDrawerOpen => {
        const scrollableDivElement = gridRef.current?.['_element'].querySelector(
          '.dx-scrollable-container',
        );
        if (!isDrawerOpen || scrollableDivElement == null) return;

        /**
         * Drawer and all its `ui` functionalities works with `transition`, so we can't use `addEventListener('transitioned', ...)`
         * so we have to do the following, unfortunately
         */
        setTimeout(() => {
          scrollableDivElement.scrollLeft = scrollableDivElement.scrollWidth;
        }, 300);
      },
      {
        preserve: false,
      },
    );

    const zoomOnDispatchId = actorOnDispatch('changeScreenZoom', scale => {
      const gridElement = gridRef.current?.['_element'];
      if (gridElement) {
        gridElement.style.transform = `scale(${scale})`;
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'isDrawerOpen',
        listenerId: drawerOnDispatchId,
      });

      actorRemoveAction({
        actionName: 'changeScreenZoom',
        listenerId: zoomOnDispatchId,
      });
    };
  }, []);

  function getGridClass(): string {
    const rows = getPreparedRows();

    if (relationMode) {
      if (rows?.length == 0) {
        return classes.emptyRelationGrid;
      }

      return actorGetActionValue('fullScreenItem')
        ? classes.relationGridFullScreen
        : classes.relationGrid;
    } else {
      return classes.grid;
    }
  }

  function setRowColor(e: any): void {
    if (e.rowType != 'data') return;

    const rowStateColorField = findRowStateColorField(
      lodashGet(metaData, 'fields', lodashGet(metaData, 'columns')),
    );

    if (!rowStateColorField) return;

    const targetKey = rowStateColorField.relatedName ?? rowStateColorField.title;

    const colorValue = e.data?.[targetKey];
    const isHexColor = /^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$/i.test(colorValue);
    const isValidColor =
      (!Number.isNaN(colorValue) && colorValue >= 0) || isHexColor;

    if (e.rowType === 'data' && isValidColor) {
      const customRowColor = isHexColor ? colorValue : getColorById(colorValue);

      if (customRowColor !== null) {
        e.rowElement.style.backgroundColor = customRowColor;
        e.rowElement.setAttribute(
          'class',
          'dx-row dx-data-row dx-row-lines dx-column-lines',
        );
        // if we don't set this, the row get zebra style(rowAlternationEnabled property)
      }
    }

    e.rowElement.setAttribute('data-test-grid-row', `${e.data?.id}`);
    e.rowElement.setAttribute('data-style-grid-row', 'gridRow');
  }

  return {
    getGridClass,
    setRowColor,
  };
};

export default useCustomStyles;
