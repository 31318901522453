/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-case-declarations */
import querystring from 'qs';
import axios from 'axios';
import lodashGet from 'lodash/get';
import lodashClone from 'lodash/clone';
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';

import {
  USER_TOKEN,
  CONFIG_PROFILE_SETTING,
  getValue,
  CONFIG_FIXED_HEADER_PARAMS,
  CONFIG_ROUTE_PREFIX,
  SUB_DOMAIN,
} from './configProvider';

import {
  clone,
  objectToLowerCaseProperties,
  isEmpty,
  isEmptyObject,
} from '../helper/data-helper';
import { CONFIG_CALENDAR, CONFIG_LOCALE } from './configProvider';
import { shouldUpdateProfile } from '../helper/ProfileHelper';
import {
  getSessionIdInUrl,
  createNewUrlBaseOfSessionIdInUrl,
} from '../helper/UrlHelper';
import { getRootTableId } from '../helper/meta-helper';
import { getRootResource } from '../helper/resource-helper';

import {
  GET_DROPDOWN,
  GET_MENU,
  GET_CODING,
  GET_META,
  RUN_SERVICE,
  GET_REPORT,
  UPDATE_REPORT,
  CUSTOM_UPDATE,
  CHANGE_PASSWORD,
  GET_FILE,
  GET_QUICK_ACCESS,
  INLINE_CELL_UPDATE,
  GET_DROPDOWN_DEFAULT_VALUES,
  UPDATE_PROFILE,
  GET_AUTHORITY_DELEGATION,
  PUT_AUTHORITY_DELEGATION,
  POST_STREAM_FILE,
  POST_STREAM_MULTIPLE_FILE,
  GET_DEFAULT_FORM_VALUES,
  RUN_VALIDATION,
  LOG_ERROR,
  CUSTOM_GET,
  CUSTOM_POST,
  PROCESS_HISTORY_RESOURCE,
  apiUrl,
  apiVersion,
  httpClient,
  isResponseOk,
  getResponseMessage,
  shouldParseResponseError,
  shouldParseResponseAdditionalDataError,
  checkResponseAndPlayAudio,
  checkResponseForOpenNewTab,
  showWarningsSnackbar,
  apiName,prepareFilterFromObject
} from './data-Provider.helper';
import { checkCache } from '../helper/WorkBoxHelper';

import { actorSetActionValue} from '../type/actor-setup';

const dataProvider = async (type, resource, params = {}) => {
  const {
    rawResponse = false,
    skipPrefix = false,
    queryParams = {},
    fieldName,
    dropdownId,
    dropdownResource,
    parameters,
    isWMS,
  } = params;

  const sessionIdInUrl = getSessionIdInUrl();

  // bring back timeout into its default value
  // because its possible to has been changed in get file case
  httpClient.defaults.timeout = 240000; // wait 240 seconds

  if (sessionIdInUrl && shouldUpdateProfile(sessionIdInUrl)) {
    actorSetActionValue('currentSessionIdInUrl', sessionIdInUrl);
  }

  const _prefix = getValue(CONFIG_ROUTE_PREFIX) || ''

  const prefix = !skipPrefix ? _prefix : '';

  const token = getValue(USER_TOKEN);
  const profileSetting = getValue(CONFIG_PROFILE_SETTING) || {};
  const fixedHeaderParams = getValue(CONFIG_FIXED_HEADER_PARAMS) || {};

  const calendarLocale = getValue(CONFIG_CALENDAR);
  const locale = getValue(CONFIG_LOCALE);
  const company = getValue(SUB_DOMAIN);

  const requestConfig = {
    headers: {
      ...fixedHeaderParams,
      calendarLocale,
      locale,
      settings: encodeURI(JSON.stringify(profileSetting)),
      authorization: `Bearer ${token}`,
      ...(!!company && { company })
    },
  };
  try {
    switch (type) {
      case GET_DROPDOWN:
        const dropdownPerPage = lodashGet(params, 'pagination.perPage') || 10;
        const dropdownPage = lodashGet(params, 'pagination.page') || 1;

        let dropdownQueryParameters = {
          search: `${params.search}` || '',
          parameters: parameters || '',
          skip: (dropdownPage - 1) * dropdownPerPage,
          takeCount: dropdownPerPage,
          sort: lodashGet(params, ['sort', 'field']),
          sortType: lodashGet(params, ['sort', 'order']),
          forceTreeLevel: params.forceTreeLevel ? 'true' : 'false',
          filters: '',
          CallFromFilter: params.isFilter ?? false
        };

        if (params.forceTreeLevel) {
          dropdownQueryParameters.filters = null;
        } else if (Array.isArray(params.filter) && params.filter.length){
          dropdownQueryParameters.filters = JSON.stringify(params.filter);
        }

        dropdownQueryParameters = querystring.stringify(dropdownQueryParameters);
        const dropdownUrl =
          !isEmpty(fieldName) && !isEmpty(dropdownId) && !isEmpty(dropdownResource)
            ? `${apiUrl}/${apiVersion}/${prefix}${dropdownResource}/dropdown/${dropdownId}/${fieldName}?${dropdownQueryParameters}`
            : `${apiUrl}/${apiVersion}/${prefix}dropdown/${resource}?${dropdownQueryParameters}`;

        const dropdownResponse = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(dropdownUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        checkResponseAndPlayAudio(dropdownResponse.data);
        checkResponseForOpenNewTab(
          dropdownResponse.data,
          dropdownQueryParameters.filters,
        );
        showWarningsSnackbar(dropdownResponse);

        if (!isResponseOk(dropdownResponse)) {
          throw getResponseMessage(dropdownResponse.data);
        }

        return dropdownResponse.data;

      case UPDATE_REPORT:
        const updateReportUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}?columns=${params.columns}`;
        const updateReportData = params.data;

        const updateReportResponse = await axios.put(
          createNewUrlBaseOfSessionIdInUrl(updateReportUrl, sessionIdInUrl, apiUrl),
          updateReportData,
          requestConfig,
        );

        showWarningsSnackbar(updateReportResponse);

        if (!isResponseOk(updateReportResponse)) {
          console.log(
            'dataProvider.js update report response is NOT OK',
            updateReportResponse,
          );
          throw getResponseMessage(updateReportResponse.data);
        }
        return objectToLowerCaseProperties(updateReportResponse);

      case GET_MENU:
        const menuUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;

        const menuResponse = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(menuUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        showWarningsSnackbar(menuResponse);

        if (!isResponseOk(menuResponse)) {
          throw getResponseMessage(menuResponse.data);
        }

        await checkCache(menuResponse.data.metaVersion);
        return menuResponse.data;

      case GET_META: {
        let metadataQueryStrings = '?';

        // add parent information to url
        if (resource === PROCESS_HISTORY_RESOURCE) {
          const [parentModule, parentTable] = getRootResource();

          if (parentModule && parentTable) {
            metadataQueryStrings += `${metadataQueryStrings === '?' ? '' : '&'
              }parentModule=${parentModule}&parentTable=${parentTable}`;
          }
        }

        const metaUrl = `${apiUrl}/${apiVersion}/${prefix}meta/${resource}${metadataQueryStrings}`;
        const metaResponse = await httpClient.get(
          await createNewUrlBaseOfSessionIdInUrl(metaUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        showWarningsSnackbar(metaResponse);

        if (!isResponseOk(metaResponse)) {
          throw getResponseMessage(metaResponse.data);
        }

        let relationsMeta = [];
        if (
          metaResponse.data.additionalData &&
          metaResponse.data.additionalData.relationsMeta
        ) {
          relationsMeta = metaResponse.data.additionalData.relationsMeta;
        }

        if (rawResponse) {
          return metaResponse.data;
        }
        return [metaResponse.data.data, ...relationsMeta];
      }

      case GET_LIST:
        const page = params.pagination?.page || 1;
        const perPage = params.pagination?.perPage || 25;
        let filterParams = clone(params.filter);

        let filtersForSendInPrints = [];
        // check is report
        const isReport = resource.indexOf('report') === 0;

        let finalSortParams = null;
        let finalSortTypeParams = null;
        if (params.sort) {
          finalSortParams = params.sort.field;
          finalSortTypeParams = params.sort.order;
        } else if (!isReport) {
          finalSortParams = 'id';
          finalSortTypeParams = 'ASC';
        }
        /**
         * If `perPage` was equal to `unlimited` then `skip` will be equal to `null`
         * so all of data will be get
         */
        let skip = null;
        if (perPage !== -1) {
          skip = (page - 1) * perPage;
        }
        const listObjectForQueryParameters = {
          ...queryParams,
          skip: params?.addPaginationToFilter ? 0 : skip, //VERP-6656
          takeCount: perPage === -1 ? null : perPage,
          sort: finalSortParams,
          sortType: finalSortTypeParams,
        };

        const hasSearchFilters =
        params.searchFilters && Object.keys(params.searchFilters).length;

        //https://jira.samiansoft.com/browse/VERP-6656
        if (params?.addPaginationToFilter) {
          filterParams = [
            ...filterParams,
            "and",
            ['skip', 'equal', skip],
            "and",
            ['takeCount', 'equal', perPage],
          ];
        }

        if (Array.isArray(filterParams) && filterParams.length) {
          listObjectForQueryParameters.filters = JSON.stringify(filterParams);
        } else if ( typeof filterParams === 'object' && !isEmptyObject(filterParams)) {
          
          const preparedFilter = prepareFilterFromObject(filterParams) ;
          listObjectForQueryParameters.filters = JSON.stringify(preparedFilter);
          filtersForSendInPrints = JSON.stringify(preparedFilter)
        }
        if (hasSearchFilters) {
          const preparedSearchFilters = params.searchFilters;

          listObjectForQueryParameters.searchFilters =
            JSON.stringify(preparedSearchFilters);

          filtersForSendInPrints = [
            ...filtersForSendInPrints,
            ...preparedSearchFilters,
          ];
        }

        if (params.fields && Array.isArray(params.fields)) {
          listObjectForQueryParameters.fields = params.fields.join(',');
        }

        const queryParameters = querystring.stringify(listObjectForQueryParameters);
        const listUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}${String(resource).includes('?') ? '&' : '?'
          }${queryParameters}`;

        const response = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(listUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        // useful data
        await checkCache(response?.data.metaVersion);

        checkResponseAndPlayAudio(response?.data);
        checkResponseForOpenNewTab(
          response?.data,
          filtersForSendInPrints.length ? filtersForSendInPrints : null,
        );

        showWarningsSnackbar(response?.data);

        if (!isResponseOk(response)) {
          throw getResponseMessage(response.data);
        }
        //TODO: remove this line after refactor WmsList
        if(isWMS){
          response.data.data.map((item,index) => {
            if (typeof item.id === 'undefined') {
              item.id = index;
            }
          });
        }
        return {...response.data, total: response.data.totalCount};

      case GET_ONE:
        const getOneParams = { ...queryParams };
        // prettier-ignore
        const getOneUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/${params.id}?${querystring.stringify(getOneParams)}`;

        const getOneResponse = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(getOneUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        await checkCache(getOneResponse.data.metaVersion);

        checkResponseAndPlayAudio(getOneResponse.data);
        checkResponseForOpenNewTab(getOneResponse.data, getOneParams?.filters);

        showWarningsSnackbar(getOneResponse);

        if (!isResponseOk(getOneResponse)) {
          throw getOneResponse.data;
          // TODO: When it isResponseOk === true , check that it works correctly
        }

        //TODO: Why do we have key `data`?
        return getOneResponse.data;

      case GET_CODING:
        let codingUrl = '';

        if (!isEmptyObject(params)) {
          if (params.editRequest) {
            codingUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/patternCodingValue/${params.id}`;
          } else {
            codingUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/codingDefaultValue/${params.id}`;
          }
        } else {
          codingUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/codingDefaultValue`;
        }

        const getCodingResponse = await axios.get(
          createNewUrlBaseOfSessionIdInUrl(codingUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        checkResponseAndPlayAudio(getCodingResponse.data);
        checkResponseForOpenNewTab(getCodingResponse.data);
        showWarningsSnackbar(getCodingResponse);

        if (!isResponseOk(getCodingResponse)) {
          throw getResponseMessage(getCodingResponse.data);
        }
        return getCodingResponse.data;

      case UPDATE:
        const updateData = clone(params.data);
        delete updateData._meta;
        if (updateData.__relationsData) {
          delete updateData.__relationsData;
        }

        const updateUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/${params.id}`;

        const updateResponse = await httpClient.put(
          createNewUrlBaseOfSessionIdInUrl(updateUrl, sessionIdInUrl, apiUrl),
          updateData,
          requestConfig,
        );
        checkResponseAndPlayAudio(updateResponse.data);
        checkResponseForOpenNewTab(updateResponse.data, params?.filters);

        showWarningsSnackbar(updateResponse);

        if (!isResponseOk(updateResponse) && updateResponse.data.messageType == "error") {
          throw shouldParseResponseError(updateResponse)
            ? updateResponse.data
            : shouldParseResponseAdditionalDataError(updateResponse)
              ? {
                requestId: updateResponse.data.requestId,
                data: updateResponse.data.additionalData[0].exception.data,
              }
              : getResponseMessage(updateResponse.data);
        }

        return updateResponse.data;

      case CHANGE_PASSWORD:
        const changePasswordData = clone(params.params);

        const changePasswordUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;

        const changePasswordResponse = await httpClient.put(
          createNewUrlBaseOfSessionIdInUrl(changePasswordUrl, sessionIdInUrl, apiUrl),
          changePasswordData,
          requestConfig,
        );

        checkResponseAndPlayAudio(changePasswordResponse.data);
        checkResponseForOpenNewTab(changePasswordResponse.data);

        showWarningsSnackbar(changePasswordResponse);

        if (!isResponseOk(changePasswordResponse) && changePasswordResponse.data.messageType == "error") {
          throw shouldParseResponseError(changePasswordResponse)
            ? changePasswordResponse.data
            : shouldParseResponseAdditionalDataError(changePasswordResponse)
              ? {
                requestId: changePasswordResponse.data.requestId,
                data: changePasswordResponse.data.additionalData[0].exception.data,
              }
              : getResponseMessage(changePasswordResponse.data);
        }

        return changePasswordResponse.data;

      case INLINE_CELL_UPDATE:
        const inlineUpdateData = clone(params.data);
        delete inlineUpdateData._meta;
        if (inlineUpdateData.__relationsData) {
          delete inlineUpdateData.__relationsData;
        }

        const inlineUpdateUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/${params.id}/inline/${params.target}`;

        const inlineUpdateResponse = await httpClient.put(
          createNewUrlBaseOfSessionIdInUrl(inlineUpdateUrl, sessionIdInUrl, apiUrl),
          inlineUpdateData,
          requestConfig,
        );

        showWarningsSnackbar(inlineUpdateResponse);

        if (!isResponseOk(inlineUpdateResponse) && inlineUpdateResponse.data.messageType == "error") {
          throw shouldParseResponseError(inlineUpdateResponse)
            ? inlineUpdateResponse.data
            : shouldParseResponseAdditionalDataError(inlineUpdateResponse)
              ? {
                requestId: inlineUpdateResponse.data.requestId,
                data: inlineUpdateResponse.data.additionalData[0].exception.data,
              }
              : getResponseMessage(inlineUpdateResponse.data);
        }

        return inlineUpdateResponse.data;

      case CUSTOM_UPDATE:
        let customUpdateData = '';
        params.data.map(item => {
          customUpdateData += item;
        });
        const customUpdateUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/${params.id}/${customUpdateData}`;

        const customUpdateResponse = await httpClient.put(
          createNewUrlBaseOfSessionIdInUrl(customUpdateUrl, sessionIdInUrl, apiUrl),
          {},
          requestConfig,
        );

        checkResponseAndPlayAudio(customUpdateResponse.data);
        checkResponseForOpenNewTab(customUpdateResponse.data, params?.filters);

        showWarningsSnackbar(customUpdateResponse);

        if (!isResponseOk(customUpdateResponse)  && customUpdateResponse.data.messageType == "error") {
          throw getResponseMessage(customUpdateResponse.data);
        }

        return customUpdateResponse.data;

      case CREATE:

        // set timeout value into 20 minutes
        // it should override in next data provider call
        httpClient.defaults.timeout = 1200000;
        
        let createData = clone(params.data);
        delete createData._meta;

        // if there is a file attached, send differently
        if (lodashGet(params, 'data.file.type')) {
          requestConfig.headers['content-type'] = 'multipart/form-data';

          createData = new FormData();
          createData.append('file', params.data.file);
          for (const key in params.data) {
            if (key === 'file') continue;
            createData.append(key, params.data[key]);
          }
        }

        const createUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;

        const createResponse = await httpClient.post(
          createNewUrlBaseOfSessionIdInUrl(createUrl, sessionIdInUrl, apiUrl),
          createData,
          requestConfig,
        );

        checkResponseAndPlayAudio(createResponse.data);
        checkResponseForOpenNewTab(createResponse.data, params?.filters);

        showWarningsSnackbar(createResponse);

        if (!isResponseOk(createResponse) && createResponse.data.messageType == "error") {
          throw shouldParseResponseError(createResponse)
            ? createResponse.data
            : shouldParseResponseAdditionalDataError(createResponse)
              ? {
                requestId: createResponse.data.requestId,
                data: createResponse.data.additionalData[0].exception.data,
              }
              : getResponseMessage(createResponse.data);
        }

        return createResponse.data;

      case RUN_SERVICE:
        const {
          actionUniqueId,
          data,
          serviceModuleName = '',
          serviceModuleTableName = '',
          reportId = '',
          macroId = '',
          fieldId = '',
        } = params;

        const runServiceUrl = `${apiUrl}/${apiVersion}/${prefix}action/${actionUniqueId}?moduleName=${serviceModuleName}&moduleTableName=${serviceModuleTableName}&reportId=${reportId}&macroId=${macroId}&fieldId=${fieldId}`;

        const runServiceResponse = await httpClient.post(
          createNewUrlBaseOfSessionIdInUrl(runServiceUrl, sessionIdInUrl, apiUrl),
          data,
          requestConfig,
        );
        checkResponseAndPlayAudio(runServiceResponse.data);
        checkResponseForOpenNewTab(runServiceResponse.data, params?.filters);

        if (rawResponse) {
          return runServiceResponse.data;
        }
        showWarningsSnackbar(runServiceResponse);

        if (
          !runServiceResponse?.data?.actionOutput?.additionalData &&
          !isResponseOk(runServiceResponse) &&
          runServiceResponse.data.messageType == "error"
        ) {
          if (shouldParseResponseError(runServiceResponse)) {
            throw runServiceResponse.data
          }

          if (shouldParseResponseAdditionalDataError(runServiceResponse)) {
            throw {
              requestId: runServiceResponse.data.requestId,
              data: runServiceResponse.data.additionalData[0].exception.data,
            }
          }

          throw getResponseMessage(runServiceResponse.data);
        }
        return runServiceResponse.data;

      case RUN_VALIDATION: {
        const { data, fieldName = '' } = params;

        const runValidationUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/validationactions/${fieldName}`;

        const runValidationResponse = await httpClient.post(
          createNewUrlBaseOfSessionIdInUrl(runValidationUrl, sessionIdInUrl, apiUrl),
          data,
          requestConfig,
        );

        checkResponseAndPlayAudio(runValidationResponse.data);
        checkResponseForOpenNewTab(runValidationResponse.data, params?.filters);

        if (!isResponseOk(runValidationResponse)) {
          throw {
            response: runValidationResponse,
            shouldParseResponseError: shouldParseResponseError(runValidationResponse),
            shouldParseResponseAdditionalDataError:
              shouldParseResponseAdditionalDataError(runValidationResponse),
            message: getResponseMessage(runValidationResponse.data),
          };
        }

        return runValidationResponse.data;
      }

      case DELETE:
        const deleteUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/${params.id}`;

        const deleteResponse = await httpClient.delete(
          createNewUrlBaseOfSessionIdInUrl(deleteUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        checkResponseAndPlayAudio(deleteResponse.data);
        checkResponseForOpenNewTab(deleteResponse.data, params?.filters);

        showWarningsSnackbar(deleteResponse);

        if (!isResponseOk(deleteResponse)) {
          throw getResponseMessage(deleteResponse.data);
        }

        return {
          data: params.previousData,
        };

      case DELETE_MANY:
        const deleteManyUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/bulk`;

        const deleteManyResponse = await httpClient.delete(
          createNewUrlBaseOfSessionIdInUrl(deleteManyUrl, sessionIdInUrl, apiUrl),
          {
            ...requestConfig,
            data: params.ids,
          },
        );

        checkResponseAndPlayAudio(deleteManyResponse.data);
        checkResponseForOpenNewTab(deleteManyResponse.data, params?.filters);

        if (rawResponse) {
          return deleteManyResponse.data;
        }

        showWarningsSnackbar(deleteManyResponse);

        if (!isResponseOk(deleteManyResponse)) {
          throw getResponseMessage(deleteManyResponse.data);
        }
        return { data: params.ids };

      case GET_MANY_REFERENCE: {
        const getManyPage = params.pagination.page || 1;
        const getManyPerPage = params.pagination.perPage || 25;
        const [parentModule, parentTable] = getRootResource();

        const { target, id, filter, sort } = params;

        let finalFilter = [[target, '=', id]];

        if (filter) {
          if (!Array.isArray(filter)) {
            console.warn('Invalid filter structure, It should be an array ', filter);
          } else if (filter.length > 0) {
            // Array type
            finalFilter.push('and', ...filter);
          }
        }

        // Just relations of type `process history` should send ['tableid', '=', '...']
        if (resource.includes('processtaskexecutionhistory')) {
          const rootTableId = getRootTableId();
          if (rootTableId) {
            finalFilter.push('and', ['tableid', '=', rootTableId + '']);
          }
        }
        const queryParameters = {
          ...queryParams,
          skip: (getManyPage - 1) * getManyPerPage,
          takeCount: getManyPerPage,
          sort: sort.field,
          sortType: sort.order,
          filters: JSON.stringify(finalFilter),
        };

        if (parentModule) {
          queryParameters['parentModule'] = parentModule;
        }

        if (parentTable) {
          queryParameters['parentTable'] = parentTable;
        }

        const getManyQueryParameters = querystring.stringify(queryParameters);
        const getManyUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}?${getManyQueryParameters}`;

        const getManyResponse = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(getManyUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        checkResponseAndPlayAudio(getManyResponse.data);
        checkResponseForOpenNewTab(getManyResponse.data, queryParameters.filters);

        showWarningsSnackbar(getManyResponse);

        if (!isResponseOk(getManyResponse)) {
          //TODO:  here should throw error as an object. but should check all notification handlers
          throw getResponseMessage(getManyResponse.data);
        }

        return {
          ...getManyResponse.data,
          total: getManyResponse.data.totalCount,
        };
      }

      case GET_FILE:
        // set timeout value into 20 minutes
        // it should override in next data provider call
        httpClient.defaults.timeout = 1200000;
        let getFileQueryParameters = '';
        if (!isEmptyObject(queryParams)) {
          const preparedParameters = {
            fields: queryParams.fields,
            sort: queryParams.sort?.field || 'id',
            sortType: queryParams.sort?.order || 'ASC',
            filters:
              queryParams.filter && Object.keys(queryParams.filter).length
                ? JSON.stringify(queryParams.filter)
                : '',
          };

          if (String(params?.link).includes('export/text')) {
            preparedParameters.withheader = queryParams.withheader;
            preparedParameters.encoding = queryParams.encoding;
          }

          getFileQueryParameters = querystring.stringify(preparedParameters);
        }

        requestConfig.responseType = 'arraybuffer';
        const getFileUrl = `${apiUrl}/${params.ignoreApiVersion ? '' : apiVersion
          }${prefix}${params.link}${getFileQueryParameters ? '?' + getFileQueryParameters : ''
          }`;

        const getFileResponse = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(getFileUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        return getFileResponse;

      // This case make a request to `quickaccess` to get new url
      // `shortcutKey` is important and required.
      // sample: `dataProvider(GET_QUICK_ACCESS, null, { shortcutKey })`
      case GET_QUICK_ACCESS:
        const getQuickAccessUrl = `${apiUrl}/${apiVersion}/${prefix}quickaccess/table/${params.shortcutKey}`;

        const getQuickAccessResponse = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(getQuickAccessUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        checkResponseAndPlayAudio(getQuickAccessResponse.data);
        checkResponseForOpenNewTab(getQuickAccessResponse.data, params?.filters);

        showWarningsSnackbar(getQuickAccessResponse);

        if (!isResponseOk(getQuickAccessResponse)) {
          throw getQuickAccessResponse.data;
          // TODO: When it isResponseOk === true , check that it works correctly
        }

        return getQuickAccessResponse.data;

      case GET_REPORT:
        const reportPage = params.pagination.page || 1;
        const reportPerPage = params.pagination.perPage || 25;
        const ReportObjectForQueryParameters = {
          skip: (reportPage - 1) * reportPerPage,
          takeCount: reportPerPage,
          filters: JSON.stringify(params.filters),
          sort: params.sort?.field || null,
          sortType: params.sort?.order || null,
        };

        const reportQueryParameters = querystring.stringify(
          ReportObjectForQueryParameters,
        );

        const reportUrl = `${apiUrl}/${apiVersion}/report/${params?.reportId}?${reportQueryParameters}`;

        const getReportResponse = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(reportUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        showWarningsSnackbar(getReportResponse);

        if (!isResponseOk(getReportResponse)) {
          throw getResponseMessage(getReportResponse.data);
        }

        return getReportResponse.data;

      case GET_DROPDOWN_DEFAULT_VALUES:
        const { processUniqueId, urlParameters } = params;

        const getDropdownDefaultValuesUrl = processUniqueId
          ? `${apiUrl}/${apiVersion}/${prefix}${resource}/dropdowns/defaultvalues/process/${processUniqueId}?${urlParameters}`
          : `${apiUrl}/${apiVersion}/${prefix}${resource}/dropdowns/defaultvalues?${urlParameters}`;

        const getDropdownDefaultValuesResponse = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(
            getDropdownDefaultValuesUrl,
            sessionIdInUrl,
            apiUrl,
          ),
          requestConfig,
        );

        return getDropdownDefaultValuesResponse.data;

      case GET_DEFAULT_FORM_VALUES: {
        const { processUniqueId, parentId, parentResource } = params;

        let getDefaultValuesUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/defaultvalues`;
        if (processUniqueId && parentId) {
          getDefaultValuesUrl = `${apiUrl}/${apiVersion}/${prefix}${parentResource}/${parentId}/${resource}/defaultvalues/process/${processUniqueId}`;
        } else if (parentId) {
          getDefaultValuesUrl = `${apiUrl}/${apiVersion}/${prefix}${parentResource}/${parentId}/${resource}/defaultvalues`;
        } else if (processUniqueId) {
          getDefaultValuesUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}/defaultvalues/process/${processUniqueId}`;
        }

        const getDefaultValuesResponse = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(
            getDefaultValuesUrl,
            sessionIdInUrl,
            apiUrl,
          ),
          requestConfig,
        );

        return getDefaultValuesResponse.data;
      }

      case UPDATE_PROFILE:
        const profileData = clone(params.data);
        const profileUrl = `${apiUrl}/${apiVersion}/account/${apiName}/settings`;

        const profileResponse = await httpClient.put(
          createNewUrlBaseOfSessionIdInUrl(profileUrl, sessionIdInUrl, apiUrl),
          profileData,
          requestConfig,
        );

        checkResponseAndPlayAudio(profileResponse.data);
        checkResponseForOpenNewTab(profileResponse.data, params?.filters);

        showWarningsSnackbar(profileResponse);

        if (!isResponseOk(profileResponse)) {
          shouldParseResponseError(profileResponse);
          throw shouldParseResponseError(profileResponse)
            ? profileResponse.data
            : shouldParseResponseAdditionalDataError(profileResponse)
              ? {
                requestId: profileResponse.data.requestId,
                data: profileResponse.data.additionalData[0].exception.data,
              }
              : getResponseMessage(profileResponse.data);
        }

        return profileResponse.data;

      case GET_AUTHORITY_DELEGATION:
        const delegationUrl = `${apiUrl}/${apiVersion}/account/${apiName}/AuthorityDelegation`;

        const delegationResponse = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(delegationUrl, sessionIdInUrl, apiUrl),
          requestConfig,
        );

        checkResponseAndPlayAudio(delegationResponse.data);
        checkResponseForOpenNewTab(delegationResponse.data, params?.filters);

        showWarningsSnackbar(delegationResponse);

        if (!isResponseOk(delegationResponse)) {
          shouldParseResponseError(delegationResponse);
          throw shouldParseResponseError(delegationResponse)
            ? delegationResponse.data
            : shouldParseResponseAdditionalDataError(delegationResponse)
              ? {
                requestId: delegationResponse.data.requestId,
                data: delegationResponse.data.additionalData[0].exception.data,
              }
              : getResponseMessage(delegationResponse.data);
        }

        return delegationResponse.data;

      case PUT_AUTHORITY_DELEGATION:
        const putDelegationUrl = `${apiUrl}/${apiVersion}${resource}`;

        const putDelegationResponse = await httpClient.put(
          createNewUrlBaseOfSessionIdInUrl(putDelegationUrl, sessionIdInUrl, apiUrl),
          {},
          requestConfig,
        );

        checkResponseAndPlayAudio(putDelegationResponse.data);
        checkResponseForOpenNewTab(putDelegationResponse.data, params?.filters);

        showWarningsSnackbar(putDelegationResponse);

        if (!isResponseOk(putDelegationResponse)) {
          shouldParseResponseError(putDelegationResponse);
          throw shouldParseResponseError(putDelegationResponse)
            ? putDelegationResponse.data
            : shouldParseResponseAdditionalDataError(putDelegationResponse)
              ? {
                requestId: putDelegationResponse.data.requestId,
                data: putDelegationResponse.data.additionalData[0].exception.data,
              }
              : getResponseMessage(putDelegationResponse.data);
        }

        return putDelegationResponse.data;

      case POST_STREAM_FILE:
        let streamData = clone(params.data);
        delete streamData._meta;
        // if there is a file attached, send differently
        requestConfig.headers['content-type'] = 'multipart/form-data';

        streamData = new FormData();
        streamData.append('file', params.data.file);
        for (const key in params.data) {
          if (key === 'file') continue;
          streamData.append(key, params.data[key]);
        }

        const streamUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;

        const streamResponse = await httpClient.post(
          createNewUrlBaseOfSessionIdInUrl(streamUrl, sessionIdInUrl, apiUrl),
          streamData,
          requestConfig,
        );

        checkResponseAndPlayAudio(streamResponse.data);
        checkResponseForOpenNewTab(streamResponse.data, params?.filters);

        showWarningsSnackbar(streamResponse);

        if (!isResponseOk(streamResponse)) {
          throw shouldParseResponseError(streamResponse)
            ? streamResponse.data
            : shouldParseResponseAdditionalDataError(streamResponse)
              ? {
                requestId: streamResponse.data.requestId,
                data: streamResponse.data.additionalData[0].exception.data,
              }
              : getResponseMessage(streamResponse.data);
        }

        return streamResponse.data;

      case POST_STREAM_MULTIPLE_FILE: {
        const {
          successCallback,
          successAllFilesUploadedCallback,
          failureCallback,
          data,
        } = params;
        let multipleStreamData = lodashClone(data);

        const streamErrors = [];
        const streamSuccessResponse = [];

        if (data.files == null || data.files.length === 0) return;

        // create url
        const streamUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;
        requestConfig.headers['content-type'] = 'multipart/form-data';

        for (const file of data.files) {
          try {
            multipleStreamData = new FormData();
            multipleStreamData.append('file', file);

            for (const key in data) {
              if (key === 'files') continue;
              multipleStreamData.append(key, data[key]);
            }

            // make request
            const multipleFilesStreamResponse = await httpClient.post(
              createNewUrlBaseOfSessionIdInUrl(streamUrl, sessionIdInUrl, apiUrl),
              multipleStreamData,
              requestConfig,
            );

            checkResponseAndPlayAudio(multipleFilesStreamResponse.data);
            checkResponseForOpenNewTab(
              multipleFilesStreamResponse.data,
              params?.filters,
            );

            showWarningsSnackbar(multipleFilesStreamResponse);

            if (!isResponseOk(multipleFilesStreamResponse)) {
              const errorMessage = shouldParseResponseError(
                multipleFilesStreamResponse,
              )
                ? multipleFilesStreamResponse.data
                : shouldParseResponseAdditionalDataError(multipleFilesStreamResponse)
                  ? {
                    requestId: multipleFilesStreamResponse.data.requestId,
                    data: multipleFilesStreamResponse.data.additionalData[0].exception
                      .data,
                  }
                  : getResponseMessage(multipleFilesStreamResponse.data);

              streamErrors.push({
                fileName: file.name,
                message: errorMessage,
              });
            }

            const { additionalData: streamAdditionalData = {} } =
              multipleFilesStreamResponse.data;
            successCallback({
              ...streamAdditionalData,
              data: multipleFilesStreamResponse.data.data,
              size: file.size,
            });
            streamSuccessResponse.push(multipleFilesStreamResponse.data);
          } catch (error) {
            const errorMessage = error.message ? String(error.message) : String(error);

            streamErrors.push({
              fileName: file.name,
              message: errorMessage,
            });
          }
        }
        successAllFilesUploadedCallback?.(streamSuccessResponse);
        if (streamErrors.length) failureCallback(streamErrors);
        break;
      }

      case LOG_ERROR: {
        const logUrl = `${apiUrl}/${apiVersion}/client-log`;
        await httpClient.post(logUrl, params, requestConfig);
        break;
      }

      case CUSTOM_GET: {
        const urlGet = `${apiUrl}/${prefix}${resource}`;

        const getData = await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(urlGet, sessionIdInUrl, apiUrl),
          requestConfig,
        );
        return getData;
      }

      case CUSTOM_POST: {
        const sendUrl = `${apiUrl}/${apiVersion}/${prefix}${resource}`;

        const sendData = await httpClient.post(
          createNewUrlBaseOfSessionIdInUrl(sendUrl, sessionIdInUrl, apiUrl),
          params?.data,
          requestConfig,
        );

        return sendData;
      }

      default: {
        return { data: null };
      }
    }
  } catch (error) {
    /**
     * Because of using `iframe` in app, when there is an error in authentication process in the `iframe`
     * We'll have an error here
     */
    if (typeof error === 'object' && error.code === 401) {
      // now, the user should login again
      location.href = location.origin + '#/login';
    }

    throw error;
  }

};

export default dataProvider;
