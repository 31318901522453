import {
  FC,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslate } from 'react-admin';
import lodashDebounce from 'lodash/debounce';
import lodashMerge from 'lodash/merge';
import lodashMap from 'lodash/map';

import { getLabelForDropdownOption } from '../../../../helper/DropdownHelper';
import GridDropDownInputView from './grid-dropdown-input.view';
import { getDropdownRequestParams } from '../../../../helper/dropdown-api.helper';
import { GET_DROPDOWN } from '../../../../core/data-Provider.helper';
import dataProvider from '../../../../core/dataProvider';

import {
  arrayResultToObjectWithLowerCaseForDropDown,
  isEmpty,
} from '../../../../helper/data-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../../type/actor-setup';

import type { InputAppearanceCharacteristics } from '../../../../helper/meta-helper.type';
import type { GridDropDownInputInterface } from './grid-dropdown-input.type';
import type { DropDownInEditModeInterface } from '../../grid.type';
import type { DropdownMeta } from '../../../dynamic-input/dropdown-input';

const GridDropDownInputController: FC<GridDropDownInputInterface> = props => {
  const { field, onChangeInput, resource, customInputRef } = props;
  const { dropdown: dropdownMeta, name: fieldName } = field;

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<DropDownInEditModeInterface[]>([]);
  const [dropdownValue, setDropdownValue] = useState<string>('');
  // prettier-ignore
  const [selectedItem, setSelectedItem] = useState<DropDownInEditModeInterface | null | undefined>();

  const [appearanceCharacteristics, setAppearanceCharacteristics] =
    useState<InputAppearanceCharacteristics>();

  const updateInputCharacteristics = (
    value: InputAppearanceCharacteristics,
  ): void => {
    setAppearanceCharacteristics(value);
  };

  useImperativeHandle(customInputRef, () => ({
    updateInputCharacteristics,
  }));

  const isSelectedByChangeValueRef = useRef<boolean>(false);
  const actorOnDispatchCallbackIdRef = useRef<symbol>();

  const translate = useTranslate();

  useEffect(() => {
    actorOnDispatchCallbackIdRef.current = actorOnDispatch(
      'gridFormData',
      (gridFormData: Record<string, unknown>) => {
        const value = gridFormData[field.name] ?? '';
        setDropdownValue(value as string);
      },
    );

    return () => {
      actorRemoveAction({
        actionName: 'gridFormData',
        listenerId: actorOnDispatchCallbackIdRef.current,
      });
    };
  }, []);

  useEffect(() => {
    if (isEmpty(dropdownValue)) {
      setSelectedItem(null);
    }
  }, [dropdownValue]);

  /**
   * @function dropDownSearchByKey
   * @param {string} searchValue
   * @returns {void} void
   */
  const dropDownSearchByKey = async (searchValue: string) => {
    const gridFormData = actorGetActionValue('gridFormData')!;
    const dropdownId = dropdownMeta?.id;

    const params = {
      dropdownId: dropdownId,
      dropdownResource: resource,
      fieldName: fieldName,
      dropdownValueMember: dropdownMeta?.valueMember,
    };

    const finalParams = lodashMerge(
      getDropdownRequestParams({
        dropdownMeta,
        record: gridFormData,
        search: searchValue ?? ' ',
        isGridDropdown: true,
        resource,
      }),
      params,
    );
    return dataProvider(GET_DROPDOWN, dropdownId, finalParams);
  };

  /**
   * @function prepareDropdownData
   * @param { string } searchValue
   * @returns { Promise<void> }  Promise<void>
   */
  const prepareDropdownData = async (searchValue: string): Promise<void> => {
    if (isSelectedByChangeValueRef.current === true) return;

    const data = await dropDownSearchByKey(searchValue);
    const _data = arrayResultToObjectWithLowerCaseForDropDown(
      data.data,
      dropdownMeta?.valueMember ? dropdownMeta?.valueMember : fieldName,
    );

    const dropdownData: DropDownInEditModeInterface[] = [];
    let selectedItem: DropDownInEditModeInterface | null = null;

    if (data?.data?.length > 0) {
      dropdownData.push({
        value: null,
        label: translate('dropdown.noneLabel'),
      });
    }

    lodashMap(_data, dropdownItemInResponse => {
      const label = getLabelForDropdownOption(
        dropdownMeta as DropdownMeta,
        dropdownItemInResponse,
      );

      if (dropdownItemInResponse[dropdownMeta.valueMember] === dropdownValue) {
        selectedItem = dropdownItemInResponse;
      }

      dropdownData.push({
        value: dropdownItemInResponse,
        label,
      });
    });

    if (selectedItem != null) {
      setSelectedItem(selectedItem);
    }
    setOptions(dropdownData);
    setIsLoading(false);
    setIsOpen(true);
  };

  /**
   * this function handle value change in search input drop down
   * @function handleChangeInput
   * @param {React.ChangeEvent<HTMLInputElement>} {event}
   * @return {void}
   */
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedSearch(event.target.value);
  };

  /**
   * @function debouncedSearch
   * @param { string } value
   * @returns { void }
   */
  const debouncedSearch = useCallback(
    lodashDebounce((value: string) => {
      if (isEmpty(value)) {
        setSelectedItem(null);
      }

      setOptions([]);
      setIsLoading(true);

      prepareDropdownData(value);
    }, 500),
    [],
  );

  /**
   * @function handleDropdownChange
   * @param {FieldType} field
   * @param {unknown} value Can be any type, boolean, string, array, etc...
   * @returns {void} void
   */
  const mapDropdownData = (value: Record<string, unknown>): void => {
    const gridFormData = actorGetActionValue('gridFormData')!;

    const mapArray: { to: string; from: string }[] = dropdownMeta?.maps as {
      to: string;
      from: string;
    }[];

    mapArray.forEach(item => {
      const target = item.to;

      const targetValue: unknown = value?.[item.from];
      // if "item.from" is not defined, no need to trigger a change in form
      if (typeof targetValue !== 'undefined') {
        gridFormData[target] = targetValue;
      }
    });

    actorDispatch('gridFormData', gridFormData, {
      replaceAll: true,
      callerScopeName: 'GridController => mapDropdownData',
    });
  };

  /**
   * Turn values into a string chain and emit onChange
   * @function handleChangeValue
   * @param {React.ChangeEvent<{}>} {event}
   * @param {string} {value}
   * @param {AutocompleteChangeReason} {reason}
   * @returns {void}
   */
  const handleChangeValue = (
    _event,
    selectedItem: DropDownInEditModeInterface,
  ): void => {
    if (!selectedItem) {
      onFocus();
      return;
    }

    isSelectedByChangeValueRef.current = true;

    /**
     * When `noneLabel` is selected, we have to call `onChangeInput`
     * to set an empty value
     */
    if (selectedItem.value == null) {
      onChangeInput({
        fieldName,
        value: '',
      });

      setSelectedItem(null);
      isSelectedByChangeValueRef.current = false;
    } else {
      setSelectedItem(selectedItem);
    }
  };

  useEffect(() => {
    if (selectedItem?.value == null) return;

    const value = (selectedItem.value[dropdownMeta.valueMember] ?? '') as string;

    onChangeInput({
      fieldName,
      value,
    });

    mapDropdownData(selectedItem.value);
    setDropdownValue(value);
    setIsOpen(false);
    isSelectedByChangeValueRef.current = false;
  }, [selectedItem]);

  const onFocus = useCallback(() => {
    setOptions([]);
    setIsLoading(true);
    setIsOpen(true);
    prepareDropdownData('');
  }, []);

  const onBlur = useCallback(() => {
    setIsLoading(false);
    setIsOpen(false);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <GridDropDownInputView
      handleChangeValue={handleChangeValue}
      isOpen={isOpen}
      items={options}
      loading={isLoading}
      handleChangeInput={handleChangeInput}
      isDisabled={
        appearanceCharacteristics?.enable === false ||
        appearanceCharacteristics?.visible === false
      }
      dropdownValue={dropdownValue}
      onFocus={onFocus}
      onBlur={onBlur}
      fieldMeta={field.dropdown}
      toggleDropdown={toggleDropdown}
    />
  );
};

export default memo(GridDropDownInputController);
