import { useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import { TextField, BooleanField, useLocale, useTranslate } from 'react-admin';
import classNames from 'classnames';
import {
  Icon,
  IconButton,
  Typography,
  Link as MaterialLink,
  InputLabel,
  Tooltip,Box
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FileIcon } from 'react-file-icon';

import { getFractionDigitCountFromFormat } from '../helper/NumberHelper';
import {
  BOOLEAN_FIELD,
  DATE_FIELD,
  DATETIME_FIELD,
  getTypeByField,
  NUMBER_FIELD,
  RESOURCE_LINK_FIELD,
  URL_FIELD,
  FILE_FIELD,
  DECIMAL_FIELD,
  DROP_BASE_MULTI_SELECT_FIELD,
  RICH_TEXT_EDITOR_FIELD,
  FILE_STREAM_FIELD,
  MULTI_FILE_STREAM_FIELD,
  COMPUTED_FIELD,
  LOCATION_FIELD,
  getFieldAdditionalData,
  GADGET_FIELD,
  MANUAL_ID,
  CALCULATED_FIELD,
  inputTypes
} from '../helper/InputHelper';
import DateFieldContainer from './DateFieldContainer';
import ResourceLinkField from '../component/formLayout/ResourceLinkField';
import { addToFilterRequestListAction } from '../redux/listFilter/action';
import FileField from '../component/field/FileField';
import { clone, isEmpty, isEmptyObject } from '../helper/data-helper';
import NumberField from '../component/field/NumberField';
import AutocompleteField from '../component/field/AutocompleteField';
import { QuickEditButton } from '../component/quick-edit-button/';
import { RichTextEditorField } from '../component/rich-text-editor-field';
import { openLinkNewTab } from '../helper/UrlHelper';
import { apiUrl } from '../core/data-Provider.helper';
import { MultiFileStreamField } from '../component/field/multi-file-stream-field';
import { LocationField } from '../component/field/location-field';
import {
  getFileExtension,
  isImage,
} from '../component/react-file-icon-component/preview-file.helper';
import defaultStyles from '../component/react-file-icon-component/default.style';
import { castToBoolean } from '../helper/validation-helper';
import moment from 'moment-jalaali';
import { SINGLE_RECORD_CONSTANT_UNIQUE_ID } from '../helper/settings-helper';
import { actorGetActionValue } from '../type/actor-setup';
import { GadgetField } from '../component/field/gadget-field';
import { sanitizeFileStreamValue } from '../component/dynamic-input/multi-file-stream-input/multi-file-stream-input.helper';

import { openPreviewImage } from '../component/dynamic-input/multi-file-stream-input/multi-file-stream-input.helper';
import { PolygonInput } from '../component/dynamic-input/polygon-input';

const useStyles = makeStyles(theme => ({
  textField: {
    display: 'inline',
    margin: '0 5px',
    fontSize: 12, 
  },
  noComponentField: {
    whiteSpace: 'pre-line',
    overflow:'auto',
    margin: '0 5px',
    fontSize: 12, 
  },
  fieldItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
  },

  fieldCaption: {
    fontSize: theme.typography.subtitle2.fontSize,
    width: 110,
    minWidth: 110,
    maxWidth: 110,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.appSecondaryTextColor,
      margin: 0.5,
      padding:1
  },

  fieldValue: {
    flex: '2',
    flexGrow: '2',
    overflow: 'hidden',
    fontSize: 12,
  },

  fieldLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  booleanField: {
    display: 'flex',
    cursor: 'pointer',
    '& svg': {
      fontSize: 20,
    },
  },

  iconButton: {
    padding: 7,
  },

  icon: {
    fontSize: 13,
  },
  previewImg: {
    width: '40px',
    objectFit: 'cover',
    marginRight: 5,
  },
  previewImgIcon: {
    display: 'inline-flex',
    width: '40px',
    objectFit: 'cover',
    marginRight: 5,
  },
  actionButtons:{
    display:'flex',
    flexDirection:'column'
  }
}));

const DynamicField = props => {
  const {
    field,
    source,
    record,
    customLabel,
    label,
    addToFilterRequestList,
    relationMode,
    ...rest
  } = props;

  const classes = useStyles(props);
  const locale = useLocale();
  const translate = useTranslate();

  const hint = lodashGet(lodashGet(field, 'translatedComment'), locale, '');

  const [recordWithStarPassword, setRecordWithStarPassword] = useState({});

  useEffect(() => {
    const _record = clone(record);
    if (field.isHashed) {
      _record[source] = '******';
    }

    setRecordWithStarPassword(_record);
  }, [record]);

  const currentResource = actorGetActionValue('resources').current;

  const inputsInitialAppearanceCharacteristics = actorGetActionValue(
    'inputsInitialAppearanceCharacteristics',
    `${currentResource.value}.${currentResource.type}.${SINGLE_RECORD_CONSTANT_UNIQUE_ID}.${field.name}`,
  );

  const disabledField = !inputsInitialAppearanceCharacteristics?.enable;

  const fieldType = getTypeByField(field);
  if (fieldType === MANUAL_ID) return null;

  field.customOption = {
    additionalDataFieldAlt: '',
    customStyleAdditionalDataField: {},
  };

  if (!isEmpty(record[`__${field.name}_additionaldata`])) {
    const fieldAdditionalData = getFieldAdditionalData(
      record[`__${field.name}_additionaldata`],
    );

    field.customOption.additionalDataFieldAlt =
      fieldAdditionalData.additionalDataFieldAlt;

    field.customOption.customStyleAdditionalDataField =
      fieldAdditionalData.customStyleAdditionalDataField;
  }

  const commonProps = {
    record,
    source,
    label,
    field,
    'data-style-dynamic-field': fieldType,
  };

  /**
   * Extracts `relatedName` and `parameterKey` from the field and
   * also takes `relatedName` for value variable and these are used for the `addToFilterRequestList` function
   * @function setCurrentValueToFilter
   * @returns {void}
   */
  const setCurrentValueToFilter = () => {
    const { parameterKey, relatedName } = field;
    const value = lodashGet(record, relatedName);

    addToFilterRequestList({ name: parameterKey, value });
  };

  let fieldComponent;

  /**
   * @function getDefaultFieldComponent
   * @returns { void }
   */
  const getDefaultDynamicFieldComponent = () => {
    const linkName = lodashGet(field, 'linkName');
    const linkValue = lodashGet(record, linkName);
    
    let defaultComponent;
    if (linkName && linkValue) {
      defaultComponent = (
        <Link
          className={classNames(classes.fieldValue, classes.fieldLink)}
          to={`${linkValue.toLowerCase()}/show`}
          >
          {lodashGet(record, lodashGet(field, 'relatedName')) ??
            lodashGet(record, lodashGet(field, 'name'))}
        </Link>
      );
    } else {
      defaultComponent = !customLabel ? (
        <TextField
          {...rest}
          {...commonProps}
          record={recordWithStarPassword}
          emptyText=" "
          className={classes.textField}
        />
      ) : (
        <Typography className={classes.noComponentField} style={{
          height:
            field.rowSpan && field.rowSpan > 1 ? field.rowSpan * 40 : 'unset',
          overflow: 'auto',
        }}>{!isEmpty(recordWithStarPassword[source]) ? recordWithStarPassword[source].toString().replace(/\r\n|\r|\n/g,"\r\n") : ''}</Typography>
      );
    }

    if (field.placeholder && !defaultComponent) {
      return field.placeholder;
    }

    return defaultComponent;
  };
  switch (fieldType) {
    case RESOURCE_LINK_FIELD:
      fieldComponent = <ResourceLinkField {...rest} {...commonProps} />;
      break;

    case GADGET_FIELD:
      fieldComponent = <GadgetField gadgetValue={record.gadgetdesign} />;
      break;

    case URL_FIELD:
      fieldComponent = (
        <MaterialLink
          variant="body2"
          component="button"
          className={classes.fieldLink}
          onClick={() => openLinkNewTab(record[source])}
        >
          {lodashGet(record, lodashGet(field, 'relatedName')) ??
            lodashGet(record, lodashGet(field, 'name'))}
        </MaterialLink>
      );
      break;

    case BOOLEAN_FIELD:
      if (!isEmpty(field.customOption.additionalDataFieldAlt)) {
        field.customOption.additionalDataFieldAlt = castToBoolean(
          field.customOption.additionalDataFieldAlt,
        )
          ? translate('general.enable')
          : translate('general.disable');
      }
      fieldComponent = (
        <BooleanField
          {...rest}
          {...commonProps}
          className={classes.booleanField}
          data-test-boolean-field-name="booleanField"
        />
      );
      break;
    case DATETIME_FIELD:
    case DATE_FIELD: {
      let format = 'YYYY/MM/DD';
      if (locale === 'fa') {
        format = 'jYYYY/jMM/jDD';
      }

      if (!isEmpty(field.customOption.additionalDataFieldAlt)) {
        field.customOption.additionalDataFieldAlt = moment(
          field.customOption.additionalDataFieldAlt,
        ).format(format);
      }

      fieldComponent = <DateFieldContainer {...rest} {...commonProps} />;
      break;
    }

    case NUMBER_FIELD:
    case DECIMAL_FIELD:
      fieldComponent = (
        <NumberField
          {...rest}
          {...commonProps}
          className={classes.textField}
          options={{
            maximumFractionDigits: getFractionDigitCountFromFormat(field.format),
            minimumFractionDigits: getFractionDigitCountFromFormat(field.format),
          }}
          data-test-number-field-name={field.name}
          ignoreNumberFormat={isEmpty(field.format)}
        />
      );
      break;

    case CALCULATED_FIELD:
    case COMPUTED_FIELD:
      {
        const simpleType = lodashGet(field, 'dataType.simple');

        fieldComponent =
          simpleType === 'number' ? (
            <NumberField
              {...rest}
              {...commonProps}
              className={classes.textField}
              options={{
                maximumFractionDigits: getFractionDigitCountFromFormat(field.format),
                minimumFractionDigits: getFractionDigitCountFromFormat(field.format),
              }}
              data-test-number-field-name={field.name}
              ignoreNumberFormat={isEmpty(field.format)}
            />
          ) : (
            getDefaultDynamicFieldComponent()
          );
      }

      break;

    case FILE_FIELD:
      fieldComponent = (
        <FileField record={record} source={source} classes={classes} field={field} />
      );
      break;
    case RICH_TEXT_EDITOR_FIELD:
      fieldComponent = <RichTextEditorField fieldName={commonProps?.['source']} />;
      break;

    case DROP_BASE_MULTI_SELECT_FIELD:
      fieldComponent = (
        <AutocompleteField
          record={record}
          source={field.relatedName}
          data-test-text-field-name={field.name}
          label={label}
          field={field}
          isShowMode
        />
      );
      break;

    case FILE_STREAM_FIELD: {
      
      const linkValue = record[field?.linkName]
      const realFileName = record[field?.relatedName] ?? record[field?.name] ;
      const fileStreamValue = { [field.name] : [ { filePath : linkValue , realFileName } ] }   

      fieldComponent = (
        <MultiFileStreamField
          record={{...record,...fileStreamValue}}
          field={field}
          disabled={disabledField}
        />
      );
      break;
    }

    case MULTI_FILE_STREAM_FIELD: {
      record[field.name] = sanitizeFileStreamValue(record[field.name]);
      fieldComponent = (
        <MultiFileStreamField
          record={record}
          field={field}
          disabled={disabledField}
        />
      );
      break;
    }

    case inputTypes.POLYGON_FIELD:
        fieldComponent = (
          <PolygonInput
          {...rest}
          {...commonProps}
          className={classes.textField}
          options={{
            maximumFractionDigits: getFractionDigitCountFromFormat(field.format),
            minimumFractionDigits: getFractionDigitCountFromFormat(field.format),
          }}
          data-test-number-field-name={field.name}
          ignoreNumberFormat={isEmpty(field.format)}
          value={record[field.name]}
          isShowMode
          />
        );
      break;

    case LOCATION_FIELD: {
      fieldComponent = (
        <LocationField
          {...rest}
          {...commonProps}
          className={classes.textField}
          options={{
            maximumFractionDigits: getFractionDigitCountFromFormat(field.format),
            minimumFractionDigits: getFractionDigitCountFromFormat(field.format),
          }}
          data-test-number-field-name={field.name}
          ignoreNumberFormat={isEmpty(field.format)}
        />
      );
      break;
    }

    default: {
      fieldComponent = getDefaultDynamicFieldComponent();
    }
  }

  const fieldValue = record?.[field.name] ?? [];
  return !customLabel ? (
    <>
      {!!field.parameterKey && !relationMode && (
        <IconButton
          className={classes.iconButton}
          onClick={setCurrentValueToFilter}
          color="primary"
          data-test-filter-button-id={record[source]}
        >
          <Icon
            fontSize="small"
            className={classNames(classes.icon, 'fa fa-filter')}
          />
        </IconButton>
      )}
      {fieldComponent}
    </>
  ) : !isEmpty(hint) ? (
    <Tooltip title={field.customOption.additionalDataFieldAlt} PopperProps={{ disablePortal: true }}>
      <div className={classes.fieldItem} data-test-field-disabled={disabledField}>
        <InputLabel
          className={classes.fieldCaption}
          variant="standard"
          shrink={false}
        >
          {label}
        </InputLabel>
        <Typography
          className={classes.fieldValue}
          variant="subtitle2"
          data-style-subTitle="subTitle"
          style={field.customOption.customStyleAdditionalDataField}
        >
          {fieldComponent}
        </Typography>
        <Box className={classes.actionButtons}>
          {!disabledField && <QuickEditButton {...rest} {...commonProps} />}
          { fieldType == MULTI_FILE_STREAM_FIELD && !isEmptyObject(fieldValue) && (
            <IconButton onClick={openPreviewImage(fieldValue)}>
              <Icon fontSize="small">remove_red_eye</Icon>
            </IconButton>
          )}
        </Box>
      </div>
    </Tooltip>
  ) : (
    <div className={classes.fieldItem} data-test-field-disabled={disabledField}>
      <InputLabel className={classes.fieldCaption} variant="standard" shrink={false}>
        {label}
      </InputLabel>
      <Typography
        className={classes.fieldValue}
        variant="subtitle2"
        style={field.customOption.customStyleAdditionalDataField}
      >
        {fieldComponent}
      </Typography>
      {!disabledField && <QuickEditButton {...rest} {...commonProps} />}
    </div>
  );
};

// DynamicField.propTypes = {
//   field: PropTypes.object.isRequired,
//   source: PropTypes.string.isRequired, // must be defined for grid to show header column
// };

const mapDispatchTopProps = {
  addToFilterRequestList: addToFilterRequestListAction,
};

export default connect(null, mapDispatchTopProps)(DynamicField);
