import { ChangeEvent, FC, useEffect, useState } from 'react';

import { isCtrlEnterPressed } from '../../../../helper/FormHelper';
import { clone } from '../../../../helper/data-helper';
import { getRootTableId } from '../../../../helper/meta-helper';
import { getValue, USER_ID } from '../../../../core/configProvider';
import { actorDispatch } from '../../../../type/actor-setup';
import CreateEditNoteView from './create-edit-note.view';
import { showNotification } from '../../../../helper/general-function-helper';
import { resourceForNote } from './constants';

import type {
  CreateEditNoteInterface,
  NoteDataInterface,
} from './create-edit-note.type';

const CreateEditNoteController: FC<CreateEditNoteInterface> = props => {
  const {
    onSubmitNote,
    refreshView,
    parentFieldName,
    parentResource,
    relationData,
    parentId,
  } = props;

  const [invalidNote, setInvalidNote] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [noteText, setNoteText] = useState<string>('');
  const [attachmentArr, setAttachmentArr] = useState<Array<{ fileName: string }>>(
    [],
  );

  const currentUserId: string = getValue(USER_ID);

  /**
   * it will reset all states
   * @function resetStates
   * @returns {void} void
   */
  const resetStates = (): void => {
    Promise.resolve().then(() => {
      setNoteText('');
      setAttachmentArr([]);
      setIsSending(false);
      setInvalidNote(false);
    });
  };

  useEffect(() => {
    if (relationData) {
      try {
        const note = JSON.parse((relationData.note as string) ?? '');

        setAttachmentArr(note.attachmentArr ? note.attachmentArr : []);
        setNoteText(note.NoteText ?? note.notejson);
      } catch (error) {
        console.error(error);
      }
    }
  }, [relationData]);

  /**
   * change note text
   * @function onNewNoteChange
   * @param {ChangeEvent<HTMLInputElement>} event
   * @returns {void} void
   */
  const onNewNoteChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const text = event.target.value;

    if (!text.includes(' ') && text.length >= 50) {
      setInvalidNote(true);
    } else {
      setInvalidNote(false);
    }

    setNoteText(text);
  };

  /**
   * reset states , close open dialogs and request new relation data
   * @function onCreateEditSuccess
   * @returns {void} void
   */
  const onCreateEditSuccess = (): void => {
    onSubmitNote();
    resetStates();
    refreshView();
  };

  /**
   * save or update note
   * @function saveNote
   * @param {boolean | number} hasIsPin
   * @returns {void} void
   */
  const saveNote = (hasIsPin?: boolean | number) => (): void => {
    setIsSending(true);
    const data: NoteDataInterface = {
      NoteText: JSON.stringify({
        NoteText: noteText,
        attachmentArr: attachmentArr,
      }),
      IsPin: hasIsPin || relationData?.ispin ? 1 : 0,
      tableid: getRootTableId(),
      KeyID: parentId,
    };

    const params = relationData ? { ...data, NoteID: relationData.id } : data;

    data[parentFieldName] = parentId;
    actorDispatch(
      'crudAction',
      {
        type: 'RUN_SERVICE',
        data: {
          params,
        },
        actionUniqueId: resourceForNote,
        onSuccess: onCreateEditSuccess,
        onFailure: (error: string | Record<string, unknown>): void => {
          if (typeof error === 'string') {
            showNotification(error, 'error');
          }
          setIsSending(false);
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'CreateEditNoteController => saveNote',
      },
    );

    actorDispatch('refreshView', 'newDataNotePin');
  };

  /**
   * add new attachment
   * @function handleNewAttachment
   * @param {unknown} fileInfo
   * @returns {void} void
   */
  const handleNewAttachment = (fileInfo: { fileName: string }): void => {
    setAttachmentArr(prevValue => [...prevValue, fileInfo]);
  };

  /**
   * delete note base of index
   * @function handleDeleteAttachment
   * @param {number} index
   * @returns {void} void
   */
  const handleDeleteAttachment = (index: number): void => {
    const prevAttachmentArr = clone(attachmentArr);
    const newAttachmentArr = prevAttachmentArr.splice(index, 1);
    setAttachmentArr(newAttachmentArr);
  };

  /**
   * for handle ctrl + enter
   * @function onNewNoteKeyPress
   * @param {ChangeEvent<HTMLInputElement>} event
   * @returns {void} void
   */
  const onNewNoteKeyPress = (event: ChangeEvent<HTMLInputElement>): void => {
    if (noteText) {
      if (isCtrlEnterPressed(event)) {
        saveNote(false)(); //normal save
        event.preventDefault();
      }
    }
  };

  return (
    <CreateEditNoteView
      handleDeleteAttachment={handleDeleteAttachment}
      handleNewAttachment={handleNewAttachment}
      onNewNoteKeyPress={onNewNoteKeyPress}
      onNewNoteChange={onNewNoteChange}
      saveNote={saveNote}
      parentResource={parentResource}
      currentUserId={currentUserId}
      attachments={attachmentArr}
      invalidNote={invalidNote}
      isSending={isSending}
      noteText={noteText}
      parentId={parentId}
    />
  );
};

export default CreateEditNoteController;
