import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useTagStyle = makeStyles((theme: CustomTheme) => ({
  rootTooltip: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid' + theme.palette.secondary.main,
    color: theme.palette.common.black,
  },

  arrowTooltip: {
    '&::before': {
      backgroundColor: theme.palette.common.white,
      border: '1px solid' + theme.palette.secondary.main,
    },
  },

  button: {
    margin: '1px',
    borderRadius: 6,
    maxWidth: 90,
    background: theme.palette.primary.appPrimaryLightBackgroundColor,
    '&:hover': {
      background: theme.palette.primary.appPrimaryLightBackgroundColor,
    },
    '& .MuiButton-endIcon': {
      flexShrink: '1 !important',
    },
    '& .MuiChip-deleteIcon': {
      width: 18,
      height: 18,
    },
    '& .MuiTypography-root': {
      fontSize: '.7rem',
    },
  },

  spanItemButton: {
    flexGrow: 2,
  },
}));

export const useStyle = makeStyles((theme: CustomTheme) => ({
  content: { flexGrow: 1 },

  inputWrapper: {
    width: 'initial',
  },

  loadingWrapper: { marginInlineEnd: 5, marginTop: 5 },

  progress: { marginInlineEnd: 5 },

  iconButton: {
    padding: 5,
  },
  containerFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectFilter: {
    display: 'flex',
    overflow: 'hidden',
    flexWrap: 'wrap',
    maxHeight: 35,
  },
}));
